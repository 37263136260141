<div id="loading-image" *ngIf="isLoaderDisplayFullPage">    
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>

<div class="container-fluid content-area">
    <div class="row" style="height: 100%;">
        <div class="left-panel col-lg-2">
            <app-header></app-header>
            <app-sidebar></app-sidebar>
        </div>
        <div class="right-panel col-lg-10 row">
           
            <div class="login-container">
                <h3 id="signInModalLabel" class="login-label mb-3">Welcome, Admin</h3>
                <div *ngIf="listmsg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{listmsg}}</div>
                <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div>
                <form (ngSubmit)="submit()" #loginForm="ngForm" ngNativeValidate>
                    <div class="form-group mb-3">   
                        <label class="signup-label" for="Organization">User Name</label>
                        <select id="user" class="dropdown">
                            <option value="">Select User</option>
                            <option *ngFor="let user of userData" [value]="user.userId">{{ user.userId }}</option>
                          </select>
                     
                          <label class="signup-label" for="Organization">Campaign Name</label>
                        <select id="campaign" class="dropdown">
                            <option value="">Select Campaign</option>
                            <option *ngFor="let campaign of selectedUserCampaigns" [value]="campaign">{{ campaign }}</option>
                          </select>

                        <label class="signup-label" for="Organization">Tempalete Id</label>
                        <input type="text" placeholder="TemplateId" class="form-control" id="templateId" name="templateId" required>
                        
                        <label class="signup-label" for="Organization">Message</label>
                        <input type="text" placeholder="Message" class="form-control" id="message" name="message" required>
                        
                        <label class="signup-label" for="Organization">Content Variable</label>
                        <input type="text" placeholder="Content Variable" class="form-control" id="contentVariable" name="contentVariable" required>
                    
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary rounded submit px-3">Submit</button>
                    </div>  
                </form>
            </div> 
        </div>
        
    </div>
</div>