<div id="loading-image" *ngIf="isLoaderDisplayFullPage">
  <!-- Loader 6 -->
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
<div class="container-fluid content-area">    
    <div class="row">
        <div class="left-panel col-lg-2">
          <app-header></app-header>
            <app-sidebar></app-sidebar>
        </div>
        <div class="right-panel col-lg-10">            
           
                <h5 class="project-label">Create New Campaign</h5>
                <div class="subtitle-div">Create a new campaign.</div>
            <div class="section-container">   
                  <div *ngIf="campaigncreatemsg" [ngClass]="createerror ? 'errorDiv': 'successDiv'">{{campaigncreatemsg}}</div>
                  <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div>
                  <div class="sec-container-title">Campaign Name</div>
                    <!-- <div id="displaySaveMsg"></div> -->
                    <input class="form-control" placeholder="Enter a name of your campaign" autocomplete="off" type="text" name="savecampaigncomment" id="saveCampaignComment" [(ngModel)]="campaign.campaignName" required>
                 
                <button class="btn btn-primary action-btn mt-3" (click)="createCampaign('form','','','flow')" [disabled]="creatBtnDisable">Create</button>
              
            </div>
            <br>
           
                <div class="listProjectHeading">Select Existing Campaign</div>
          
            <div>
              <div *ngIf="campaignListmsg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{campaignListmsg}}</div>
              <div *ngIf="editmsg" [ngClass]="editerror ? 'errorDiv': 'successDiv'">{{editmsg}}</div>
              <div class="table-responsive">  
              <table *ngIf="campaignListData" class="table table-striped campaignlist" cellpadding="10" cellspacing="10">
                    <thead>
                      <th>Campaign Name</th>
                      <th></th>
                      <th>Created Date</th>
                      <th>Channel</th>
                      <th>Status</th>
                      <th>Last Updated On</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let item of campaignListData; let i=index">
                        <tr *ngIf="i >= getCurrentStartIndex() && i <= getCurrentLastIndex()">
                          <td class="d-flex align-items-center">{{item.campaignName}}</td>
                          <td><button mat-button [matMenuTriggerFor]="menu">
                            <svg class="action-img" width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.00004 0.5C1.08337 0.5 0.333374 1.25 0.333374 2.16667C0.333374 3.08333 1.08337 3.83333 2.00004 3.83333C2.91671 3.83333 3.66671 3.08333 3.66671 2.16667C3.66671 1.25 2.91671 0.5 2.00004 0.5ZM2.00004 12.1667C1.08337 12.1667 0.333374 12.9167 0.333374 13.8333C0.333374 14.75 1.08337 15.5 2.00004 15.5C2.91671 15.5 3.66671 14.75 3.66671 13.8333C3.66671 12.9167 2.91671 12.1667 2.00004 12.1667ZM2.00004 6.33333C1.08337 6.33333 0.333374 7.08333 0.333374 8C0.333374 8.91667 1.08337 9.66667 2.00004 9.66667C2.91671 9.66667 3.66671 8.91667 3.66671 8C3.66671 7.08333 2.91671 6.33333 2.00004 6.33333Z" fill="#323232" fill-opacity="0.8"/>
                            </svg></button>
                            
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="showEditCampaignModal(item.campaignName)"><span class="editDeleteText">Rename</span></button>
                                <button mat-menu-item (click)="deleteCampaign(item.campaignName)"><span class="editDeleteText">Delete</span></button>
                              </mat-menu>     
                                   
                          </td>
                          <td class="align-middle">{{dateFormat(item.createdDate)}}</td>
                          <td class="align-middle">{{item.channel == '' ? 'Not yet selected' : item.channel}}</td>
                          <td class="align-middle">{{item.status}}</td>
                          <td class="align-middle">{{dateFormat(item.updatedDate, 'time')}}</td> 
                          <td class="align-middle"><button class="sel-btn active-btn selected" (click)="createCampaign('link', item.campaignName, item, 'direct')">Details</button></td>                         
                        </tr>
                      </ng-container>
                    </tbody>
                </table>
              </div>
              <div class="pagination-wrapper">
                <p class="pagination-info" *ngIf="(campaignListData | json) != ({} | json) && getTotalData() >= recordsPerPage">
                  Showing
                  <span>{{ getCurrentRange() }}</span> of
                  <span>{{ getTotalData() }}</span> rows
                </p>
                <p *ngIf="((campaignListData | json) == ({} | json) || campaignListData.length<=0 ||  campaignListData.message) && islistLoaded" class="noDataClass">
                  No Campaign created for user
                </p>    
             
             
              <nav aria-label="Page navigation example" *ngIf="(campaignListData | json) != ({} | json) && getTotalData() >= recordsPerPage">
                <ul class="pagination justify-content-end">
                  <!-- Previous button -->
                  <li class="page-item pagination-arrow-l" *ngIf="pageNumber !== 1">
                    <a class="page-link pagination-arrow-left-dark" (click)="loadData(pageNumber - 1)"><img src="https://res.cloudinary.com/dt9b7pad3/image/upload/v1502810110/angle-down-dark_dkyopo.png"/></a>
                  </li>
              
                  <!-- Page buttons -->
                  <li class="page-item" *ngFor="let page of pagination">
                    <a class="page-link" (click)="loadData(page)" [class.active]="pageNumber === page">{{ page }}</a>
                  </li>
              
                  <!-- Next button -->
                  <li class="page-item pagination-arrow-r" *ngIf="pageNumber !== pagination.length">
                    <a class="page-link" (click)="loadData(pageNumber + 1)"><img src="https://res.cloudinary.com/dt9b7pad3/image/upload/v1502810110/angle-down-dark_dkyopo.png"/></a>
                  </li>
                </ul>
              </nav>
              </div>
              </div>
    </div>    
</div>    

<ng-template #campaignEditScreen>
  <mat-dialog-content>
    <div class="subscribeForFullAccessDiv d-flex justify-content-center align-items-center">         
      <div class="subscibePopupHeading">                
            <div  class="row">
              <div  class="save-text-heading col-lg-10">Rename Campaign - {{existingCampaign}}</div>
              <div class="col-lg-2 paddingLeft30" >
                <button mat-button mat-dialog-close class="closeBtn">
                  <img [src]="imagePath + 'close-icon.svg'">
                </button>
              </div>
          </div>
          <input required class="save-text" type="text" name="newProj" id="newCampaign"> 
          <button type="button" class="mr-20 btn mt-3 action-btn renamePopupBtn" value="save" (click)="updateCampaign()">Update</button>
          <button type="button" class="btn mt-3 action-sec-btn renamePopupBtn" value="cancel" mat-button mat-dialog-close>Cancel</button>              
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #campaignDeleteSuccessScreen>
  <mat-dialog-content>
      <div class="subscribeForFullAccessDiv deletePopup" align="left">
         
              <div class="closeDiv floatRight">
                  <mat-dialog-actions>
                    <button class="rd-closeBtn" mat-button mat-dialog-close>
                      <img [src]="imagePath + 'close-icon.svg'">
                    </button>
                  </mat-dialog-actions>
              </div>

              <div class="subscibePopupHeading" align="center">
                  <div class="mt-1 text-black">{{editDeleteMsg}}</div>
                  <button type="button" class="mt-2 mr-20 btn btn-primary action-sm-btn" value="cancel" mat-button mat-dialog-close>Ok</button>
              </div>
          </div>
  </mat-dialog-content>
</ng-template>