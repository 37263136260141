import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DBAPIService } from '../dbapi.service';
declare var $ : any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, ){ }

  user = {    
    userEmail: ''
  };

  msg = '';
  error: boolean = false;
  submit() {
    let obj = {
      userEmail: this.user.userEmail
    }
  
    this.DBAPIObj.forgotpwd(obj).subscribe({
      next: (data: any) => {
        this.msg = data.message
      },
      error: (error) => {
        console.log(error.error);
        this.error = true;
        this.msg = error.error.message
      }
    });
  }

}
