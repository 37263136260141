import { Component, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { DBAPIService } from 'src/app/dbapi.service';
import * as Highcharts from 'highcharts';
import 'select2';
declare var $ : any;
import { USER_ID, PROJECT_ID, ORG_ID } from '../constants';
import { SessionService } from '../session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EChartsOption } from 'echarts';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})

export class AudienceComponent {
  constructor(private dialog: MatDialog, private DBAPIObj: DBAPIService, private sessionService: SessionService, private router: Router){ }

  @ViewChild('crowdedCustomerInsightUnreg') crowdedCustomerInsightUnreg!: ElementRef;
  @ViewChild('AudienceConfirmNavigation') AudienceConfirmNavigation!: TemplateRef<any>;
  @ViewChild('saveAudienceDialog') saveAudienceDialog!: TemplateRef<any>;
  @ViewChild('saveCampaignDialog') saveCampaignDialog!: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.crowdedCustomerInsightUnreg) {
      this.crowdedCustomerInsightUnreg.nativeElement.src = 'https://hktiaddev.cloud.looker.com/embed/dashboards/6';
    }

    $("#locahome").select2();
    $("#locawork").select2();
    $('#segmentSearch').select2({
         closeOnSelect: false,
         placeholder:'Search a segment'
    });


    $('#eventSearch').select2({
      closeOnSelect: false,
      placeholder:'Search a event'
    });

    $('#segmentSearch').on('change',  (e: any) => {
      const selectedValue = $('#segmentSearch').val();
      this.filterSegData(selectedValue);
    });

    $('#eventSearch').on('change',  (e: any) => {
      const selectedValue = $('#eventSearch').val();
      this.filterEventData(selectedValue);
    });

    $("#deviceType").select2({
      closeOnSelect: false,
      placeholder:'Select Device Type'
    });

    $("#deviceOS").select2({
        closeOnSelect: false,
        placeholder:'Select Device OS'
    });

    $("#deviceBrand").select2({
      closeOnSelect: false,
      placeholder:'Select Device Brand'
    });

    $("#deviceModel").select2({
      closeOnSelect: false,
      placeholder:'Select Device Model'
    });

    $("#locahome").select2({
      closeOnSelect: false,
      placeholder:'Select House District'
    });

    $("#locawork").select2({
      closeOnSelect: false,
      placeholder:'Select Work District'
    });

    //Location Work and Home
    $('#locahome').on('change',  (e: any) => {
      const selectedValue = $('#locahome').val();
      this.jsonObj['Location'].householdDistrict = [];
      for (let j=0; j < selectedValue.length; j++) {  
        if(this.jsonObj['Location'].householdDistrict.indexOf(selectedValue[j]) <=-1){
          this.jsonObj['Location'].householdDistrict.push(selectedValue[j]);        
        }
      }      
      this.updateJsonObj(true);
      //this.crowdedCustomerInsightUnreg.nativeElement.src = 'https://hktiaddev.cloud.looker.com/embed/dashboards/6';
    });

    $('#locawork').on('change',  (e: any) => {
      const selectedValue = $('#locawork').val();
      this.jsonObj['Location'].workDistrict = [];
      for (let j=0; j < selectedValue.length; j++) {  
        if(this.jsonObj['Location'].workDistrict.indexOf(selectedValue[j]) <=-1){
          this.jsonObj['Location'].workDistrict.push(selectedValue[j]);        
        }
      } 
      this.updateJsonObj(true);
      //this.crowdedCustomerInsightUnreg.nativeElement.src = 'https://hktiaddev.cloud.looker.com/embed/dashboards/6';
    });
    
    $('#ageDropdown').on('change',  (e: any) => {
      const selectedValue = $('#ageDropdown').val();
      this.onSelectFromHouseHold('Age', selectedValue);
    });

    $('#houseHoldDropdown').on('change',  (e: any) => {
      const selectedValue = $('#houseHoldDropdown').val();
      this.onSelectFromHouseHold('Household Class', selectedValue);
    });

    $('#houseHoldIncomedDropdown').on('change',  (e: any) => {
      const selectedValue = $('#houseHoldIncomedDropdown').val();
      this.onSelectFromHouseHold('Household Income', selectedValue);
    });

    $('#lifeStgDropdown').on('change',  (e: any) => {
      const selectedValue = $('#lifeStgDropdown').val();
      this.onSelectFromHouseHold('Life Stage', selectedValue);
    }); 

    // $('#deviceLevel1').on('change',  (e: any) => {
    //   const selectedValue = $('#deviceLevel1').val();
    //   this.getDeviceLevel2FilteredData(selectedValue);
    // });

    // $('#deviceLevel2').on('change',  (e: any) => {
    //   const selectedValue = $('#deviceLevel2').val();
    //   console.log("selectedValue>>", selectedValue);
    //   this.updateDeviceObj(e);
    // });

    $('#deviceType').on('change',  (e: any) => {
      const selectedValue = $('#deviceType').val();
      this.updateDeviceObj('device_type', e, selectedValue);
    });

    $('#deviceOS').on('change',  (e: any) => {
      const selectedValue = $('#deviceOS').val();
      this.updateDeviceObj('device_os', e, selectedValue);
    });

    $('#deviceBrand').on('change',  (e: any) => {
      const selectedValue = $('#deviceBrand').val();
      this.updateDeviceObj('device_brand', e, selectedValue);
    });

    $('#deviceModel').on('change',  (e: any) => {
      const selectedValue = $('#deviceModel').val();
      this.updateDeviceObj('Device_Model', e, selectedValue);
    });
  }

  selectAllOpt(event:any){
    alert()
  }
  
  creatAudBtnDisable:any=false;
  creatCampBtnDisable:any=false;

  imagePath:any=environment.imagePath;
  imageUrl:any = environment.imageUrl;
  isLoaderDisplayFullPage= true;
  homeLocArr = [];
  workLocArr = [];
  selFilterArr = [''];
  selFilterArrEvent = [''];
  segAllData:any= [];
  eventAllData: any =[];
  nextClickCount:any=0;
  segnextClickCount:any=0;
  eventnextClickCount:any=0;
  carasoulNextPrevCount:any=0;

  demData :any = {};
  segmentDataForSearch: any = {};
  eventDataForSearch: any = {};
  genderObj: any = [];
  ageObj: any = [];
  houseHoldClassObj: any = [];
  houseHoldIncome: any = [];
  education: any = [];
  lifeStage: any = [];
  occupation: any = [];
  initialTotalCount: any = 0;
  parentalObj: any = [];
  isDisabledsaveBtn:any=true;

  auderror:any = false;
  audmsg:any = '';

  camperror:any = false;
  campmsg:any = '';

  groupedDatasa: any=[];
  groupedData:any = {};
  groupedEventData:any = {};
  groupedEventLevel2Data: any=[];
  groupedEventLevel3Data: any=[];
  groupedDeviceLevel2Data: any = [];
  groupedDeviceData:any = {};
  groupedDeviceNewData:any = {};
 

  isTabsLoaded = false;
  segmentLevel1Arr:any = [];
  segLevel1Level2Array:any={};
  eventLevel1Arr:any = [];
  deviceLevel1Arr: any = [];
  deviceTypeArray: any = [];
  deviceOSArray: any = [];
  deviceBrandArray: any = [];
  deviceModelArray: any = [];
  showEventSelSec=false;
  showSegmentSelSec: boolean = false;
  eventSelectedLevel:any = [];
  segmentSelectedLevel:any = [];
  displaySaveMsg:any= '';

  selectedId:any;
  selectedType:any;
  selectedProj:any;

  level1Options: string[] = [];
  level2Options: string[] = [];
  level3Options: string[] = [];
  
  segFilteredData: any = [];

  jsonObj: any = {
    Demographics: {
      'Age': {
        filters: []
      },
      'Gender': {
        filters: []
      },
      'Household Class': {
        filters: []
      },
      'Household Income': {
        filters: []
      },
      'Occupation': {
        filters: []
      },
      'Education': {
        filters: []
      },
      'Life Stage': {
        filters: []
      },
      'Parental': {
        filters: []
      }
      
    },
    Segment: [],
    Event: [],
    Device: [],
    Location: {
      "householdDistrict": [],
      "workDistrict": []
    },
    'Total Count': "",
    'Organization': ORG_ID,
    'Project': PROJECT_ID,
    'UserID': ''
  };

  filterJsonObj ={
    'Organization': ORG_ID,
    'Project': PROJECT_ID,
    'UserID': ''
  }

  //eventLevel1Arr: any =['Event1-level1', 'Event2-level1'];
  

  houseHoldClassFilter: any = [];
  educationFilter: any = [];
  lifeStageFilter: any = [];
  occupationFilter: any = [];
  houseHoldIncomeFilter: any = [];

  selSegArr: any[] = [];
  isSegClassAdded: boolean = false;
  userCount: any = 0;
  chart: Highcharts.Chart | undefined;
  pieChartOptions: EChartsOption = {};
  chartId = 'chart-sec';
  isFilterAppliedInDemographics = false;
  filteredData = [];
  
  Highcharts = Highcharts; // Define the Highcharts property
  scrollAmount = 0;

  error: boolean = false;
  projectLists: any = [];
  listmsg: any = '';
  segmentSearchOptions: any = [];
  eventSearchOptions: any = [];

  initializeDropdownOptions() {
    this.level1Options = this.getUniqueValues('level1');
    this.level2Options = this.getUniqueValues('level2');
    this.level3Options = this.getUniqueValues('level3');
  }

  getUniqueValues(key: string): string[] {
    const values = new Set<string>();
    this.demData['Event'].forEach((item: { [x: string]: string; }) => {
      values.add(item[key]);
    });
    return Array.from(values);
  }

  ngOnInit() {
    // let currentValues = ['40-49', '60+', '30-39', '<18', 'Unknown', '18-29', '50-59'];
    // console.log(this.sortAgeValues(currentValues));

    //Household
    let householdVal = ['Lower 50%', 'Top 10%', '21-30%','41-50%','11-20%', '31-40%', 'Unknown'];
    console.log(this.sortedHouseHold(householdVal));

    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }

    this.selectedProj = this.sessionService.getItem('sessionProjectName');

    this.getProjectsList();
    
    this.filterJsonObj.UserID = this.sessionService.getItem('sessionUserName');

    this.segmentSearchOptions = [];
    this.DBAPIObj.getSegmentSearchData().subscribe({
      next: (data: any) => {
        if(data && data['segmentFilter']){
          data['segmentFilter'].forEach((item: any, key: any) => {
            let segmentIterateArray = item.split('__');
            for (let j=1; j < segmentIterateArray.length; j++) {        
              if( this.segmentSearchOptions.indexOf(segmentIterateArray[j]) <=-1 ) {
              {
                this.segmentSearchOptions.push(segmentIterateArray[j]);
              }
            }
          }       
        })
      }      
    },
    error: (error) => {
      console.log(error);
    },
  }); 

  this.eventSearchOptions = [];
    this.DBAPIObj.getEventSearchData().subscribe({
      next: (data: any) => {
        if(data && data['eventFilter']){
          data['eventFilter'].forEach((item: any, key: any) => {
            let eventIterateArray = item.split('__');
            for (let j=1; j < eventIterateArray.length; j++) {        
              if( this.eventSearchOptions.indexOf(eventIterateArray[j]) <=-1 ) {
              {
                this.eventSearchOptions.push(eventIterateArray[j]);
              }
            }
          }       
        })
      }      
    },
    error: (error) => {
      console.log(error);
    },
  }); 


    this.DBAPIObj.getAllAudienceData(this.filterJsonObj).subscribe({
      next: (data) => {
        this.demData = data

        console.log(this.demData);
      
        this.initializeDropdownOptions();

        $("#ageDropdown").select2({
          closeOnSelect: false,
          placeholder:'Select Age'
        });

        $("#lifeStgDropdown").select2({
          closeOnSelect: false,
          placeholder:'Select an option'
        });


        $("#houseHoldDropdown").select2({
          closeOnSelect: false,
          placeholder:'Select an option'
        });

        $("#houseHoldIncomedDropdown").select2({
          closeOnSelect: false,
          placeholder:'Select an option'
        });

        if ( Object.values(this.demData).length === 0) {
          return;
        }

        this.initialTotalCount = this.demData['Total Count'];
        this.userCount = this.demData['Total Count'];

        this.createChart();

        //Create AGE Array
        for(const age in this.demData.Demographics['Age'].filters){
          this.ageObj.push(this.demData.Demographics['Age'].filters[age].value);
        }
        this.ageObj = this.listSort(this.ageObj);
      
        //Create Gender Array
        for(const gen in this.demData.Demographics['Gender'].filters){
          this.genderObj.push(this.demData.Demographics['Gender'].filters[gen].value);
        }
        this.genderObj = this.listSort(this.genderObj);

        //Create HouseHold Class Array
        for(const householdcls in this.demData.Demographics['Household Class'].filters){
          this.houseHoldClassObj.push(this.demData.Demographics['Household Class'].filters[householdcls].value);
        }
        this.houseHoldClassObj = this.listSort(this.houseHoldClassObj);
       

        //Create Education array
        for(const education in this.demData.Demographics['Education'].filters){
          this.education.push(this.demData.Demographics['Education'].filters[education].value);
        }
        this.education = this.listSort(this.education);

        //Create Occupation array
        for(const occu in this.demData.Demographics['Occupation'].filters){
          this.occupation.push(this.demData.Demographics['Occupation'].filters[occu].value);
        }
        this.occupation.sort((a: string, b: string) => (a.toLowerCase() == "unknown" ? 1 : b.toLowerCase() === "unknown" ? -1 : 0));
        this.occupation = this.listSort(this.occupation);

        //Create Household Income array
        for(const housholdIncome in this.demData.Demographics['Household Income'].filters){
          this.houseHoldIncome.push(this.demData.Demographics['Household Income'].filters[housholdIncome].value);
        }
        this.houseHoldIncome = this.listSort(this.houseHoldIncome);
       
    
        //Create LifeStage array
        for(const lifeStage in this.demData.Demographics['Life Stage'].filters){
          this.lifeStage.push(this.demData.Demographics['Life Stage'].filters[lifeStage].value);
        }
        this.lifeStage.sort((a: string, b: string) => (a.toLowerCase() == "unknown" ? 1 : b.toLowerCase() === "unknown" ? -1 : 0));
        this.lifeStage = this.listSort(this.lifeStage);
      

        for(const parental in this.demData.Demographics['Parental'].filters){
          this.parentalObj.push(this.demData.Demographics['Parental'].filters[parental].value);
        }
        this.parentalObj = this.listSort(this.parentalObj);

        //store segment into another array for search
        this.segmentDataForSearch = this.demData.Segment;

        // Iterate through the Segment array
        if(this.demData.Segment != null && this.demData.Segment != undefined && this.demData.Segment.length >0){
          this.setSegement(this.demData.Segment);
        }
        

        //store segment into another array for search
        this.eventDataForSearch = this.demData.Event;
        //Iterate EVENT for level1

        if(this.demData.Event != null && this.demData.Event != undefined && this.demData.Event.length >0){
          this.setEvent(this.demData.Event);
        }
      
        
        this.setLocation(this.demData['Location']);
      
        this.demData.Device.forEach((item: { level1: any; }) => {
          const { level1 } = item;
          if(!this.groupedDeviceNewData[level1])
          this.groupedDeviceNewData[level1] = [];          
          this.groupedDeviceNewData[level1].push(item);
        })

        if( this.demData.Device != null && this.demData.Device != undefined &&  this.demData.Device.length > 0){
          this.deviceTypeArray = this.groupedDeviceNewData['device_type'][0].filters;
          this.deviceOSArray = this.groupedDeviceNewData['device_os'][0].filters;
          this.deviceBrandArray = this.groupedDeviceNewData['device_brand'][0].filters;
          if(this.groupedDeviceNewData['device_model']){
            this.deviceModelArray = this.groupedDeviceNewData['device_model'][0].filters;
          }
        }
       
        if(this.demData.Segment != null && this.demData.Segment != undefined && this.demData.Segment.length >0){
          this.getSegmentLevelWiseFilteredData();
        }
        
        if(this.demData.Event != null && this.demData.Event != undefined && this.demData.Event.length >0){
          this.getEventLevelWiseFilteredData();
        }
        
        this.isLoaderDisplayFullPage = false;
  },
  error: (error) => {
    console.log(error);
  },
    });  
    //User Count Value
    this.userCount = this.demData.totalCount;  
}

  createChart(){
    var data = [
      { value: this.userCount},
      { value: this.initialTotalCount - this.userCount}
    ];
    var colorPalette = ['#5B93FF', '#cee6fe'];
    var formattedNumber = parseInt(this.userCount).toFixed().toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.pieChartOptions = {
      
      title: {
        text: formattedNumber, //this.userCount,
        left: 'center',
        top: 'center',
      },
      width: '100%',
      series: [
        {
          type: 'pie',
          radius: ['83%', '63%'],
          center: ['50%', '50%'],
          label: {
            show: false
          },
          data: data,
          color: colorPalette
        }
      ]
    };
  }

  setSegement(segmentData:any){

    this.demData.Segment = segmentData;    
    segmentData.forEach((item: { level1: any; }) => {
      const { level1 } = item;

      // Check if the level1 group exists, if not, create it
      if (!this.groupedData[level1]) {
        this.groupedData[level1] = [];
      }

      // Add the item to the corresponding level1 group
     // Create a map to store the result
      const resultMap = new Map();

      // Iterate through the data array
      this.demData.Segment.forEach((item: { level1: any; level2: string | number; level3: any; }) => {
          // If level1 doesn't exist in the map, add it
          if (!resultMap.has(item.level1)) {
              resultMap.set(item.level1, {});
          }

          // If level2 doesn't exist in the level1 object, add it
          if (!resultMap.get(item.level1).hasOwnProperty(item.level2)) {
              resultMap.get(item.level1)[item.level2] = [];
          }

          // Add level3 to the corresponding level2 array
          resultMap.get(item.level1)[item.level2].push(item.level3);

          //let alldata = this.getSegmentLevel2FilteredData(level1);
        // this.segFilteredData = this.getSegmentLevel2FilteredData(level1);
      });


      // Convert the map to the desired array format
      this.segLevel1Level2Array = [...resultMap.entries()].map(([level1, level2Obj]) => ({
        level1,
          ...level2Obj,
      }));

      this.groupedData[level1].push(item);
      
      const keys = Object.keys(this.groupedData);
    
      keys.forEach((key) => {
        const value = this.groupedData[key];
        
        if (!this.segmentLevel1Arr.includes(key)) {
          this.segmentLevel1Arr.push(key);
        }
      });
    });   
   
  }

  setEvent(eventData: any){
    this.demData.Event = eventData; 
    eventData.forEach((item: { level1: any; }) => {
      const { level1 } = item;

      // Check if the level1 group exists, if not, create it
      if (!this.groupedEventData[level1]) {
        this.groupedEventData[level1] = [];
      }

      // Add the item to the corresponding level1 group
      this.groupedEventData[level1].push(item);
      const keys = Object.keys(this.groupedEventData);
    
      keys.forEach((key) => {
        const value = this.groupedEventData[key];
        
        if (!this.eventLevel1Arr.includes(key)) {
          this.eventLevel1Arr.push(key);
        }
      });

      //Location Home and work Array
      
      // this.homeLocArr = this.demData['Location'].householdDistrict;
      // this.workLocArr = this.demData['Location'].workDistrict;
    });
  }

  getDeviceLevel2FilteredData(desiredLevel1:any){
    // Use filter to extract data where level1 is the same
    let filteredData = this.demData.Device.filter((item: { level1: string; }) => desiredLevel1.includes(item.level1));
    
      const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level2: any; }) => {
      const { level2 } = item;
      result[level2] = result[level2] || [];
      result[level2].push(item);
      return result;
      
    }, {});
    
    this.groupedDeviceLevel2Data =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 
  }

  updateDeviceObj(type: any, event:any, selectedValue: any){
      let matchFound = false;
      let filterArr:any = [];
      let removeIndexArray: any;
      
      for (let j=0; j < selectedValue.length; j++) {        
        let newFilter = {
          "value": selectedValue[j],
        };
        filterArr.push(newFilter);
      }
      
      this.jsonObj.Device.forEach((device: { level1: string; filters: { value: any }[]; }, key: any) => {
        if (device.level1 === type
          ) {
         
          if(filterArr.length<=0){
            removeIndexArray = key;
          }
          device.filters =  filterArr;
          matchFound = true;
        }
      });
      
      if(removeIndexArray >= 0){
        this.jsonObj.Device.splice(removeIndexArray, 1);
      }
      
       // If no match was found, create a new object
      if (!matchFound) {
        const newDevice = {
          "level1": type,
          "filters": filterArr
        };
        this.jsonObj.Device.push(newDevice);
      }
    
    this.updateJsonObj(false);
  }

  setLocation(locData:any){
    this.homeLocArr = locData.householdDistrict;
    this.workLocArr = locData.workDistrict;
  }

  addNextArrowForSubMenus(id:any){
    setTimeout(() => {
      
    if(this.isTabsLoaded){
        var frameWidth = $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs').width(), // width of div showed on display
        scrollElmtWidth = $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs')[0].scrollWidth // width of div also with hidden part
  
      if (scrollElmtWidth > frameWidth) {
        $('.filter-section .tab-pane-body').addClass('has-scroll');
      } else {
        $('.filter-section .tab-pane-body').removeClass('has-scroll');
      }
      }
    }, 1000);
  }
  listSort(lst:any){
    return lst.sort((a: string, b: string) => (a.toLowerCase() == "unknown" || a.toLowerCase().includes("other")) ? 1 : (b.toLowerCase() === "unknown" || b.toLowerCase().includes("other")) ? -1 : 0);
  }
  
  faceAndBodyCareFilters(level2:any, level3:any){
    return this.demData.Segment != undefined ? this.demData.Segment.find((segment: { level3: any, level2: any; }) => segment.level3 === level2 && segment.level2 === level3)?.filters : '';
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj).filter(key => key !== 'level1');
  }

  inactiveOtherTabs(hrefval:any){
    $('#segmentTabContent').find('> .tab-pane-body > .tab-pane').removeClass('show active');
    $("#" + hrefval).addClass('show active');
    $('.filter-section .card-item .card .nav-link').removeClass('active');
    $(this).addClass('active');

    this.addNextArrowForSubMenus('segmentTabContent');
  }

  inactiveOtherEventTabs(hrefval:any){
    $('#EventTabContent').find('> .tab-pane-body > .tab-pane').removeClass('show active');
    $("#" + hrefval).addClass('show active');
    $('.filter-section .card-item .card .nav-link').removeClass('active');
    $(this).addClass('active');

    this.addNextArrowForSubMenus('EventTabContent');
  }
 
/*   sideScroll(direction:any, speed:any, distance:any, step: any){
      var slideTimer = setInterval(() =>{
          if(direction == 'left'){ 
            $('.scoll-pane').scrollLeft -= step;
          } else {
            $('.scoll-pane').scrollLeft += step;
          }
          this.scrollAmount += step;
          if(this.scrollAmount >= distance){
              window.clearInterval(slideTimer);
          }
      }, speed);
  } */

  isScrollRequired(parentid:any, scrollTabid:any){
    let showscroll = false;
   // const parentElement = (event.target as HTMLElement).parentElement;
    var frameWidth = $('#'+parentid).width();
    var scrollElmtWidth = $('#'+scrollTabid)[0].scrollWidth;

    if(scrollElmtWidth - 1 > frameWidth){
      showscroll = true;
    }
    return showscroll;
  }

  carasoulScroll(id:any, isLeft:any, event: any, isSeg:any) { 
    if(isLeft)
      this.carasoulNextPrevCount++;
    else
      this.carasoulNextPrevCount--;

      let maxClickCount;
      if(isSeg){
        maxClickCount = this.segAllData.length-2;
      }else{
        maxClickCount = this.eventAllData.length -2;
      }

      if(this.carasoulNextPrevCount == 0){
        $('#'+id).find('.card-control-prev').addClass('disabled');
      }else{
        $('#'+id).find('.card-control-prev').removeClass('disabled');
      }

      if(this.carasoulNextPrevCount == maxClickCount){
        $('#'+id).find('.card-control-next').addClass('disabled');
      }else{
        $('#'+id).find('.card-control-next').removeClass('disabled');
      }

      let outerwidth = $('#'+id).find('.card-inner .card-item').width() + 20;

      let scrollAmount = outerwidth * this.carasoulNextPrevCount;
      $('#'+id).find('.card-inner').animate({scrollLeft: scrollAmount}, 100);  

      return;
  } 


  level2TabScroll(id:any, isLeft:any, event: any, isSeg:any) { 
    const parentElement = (event.target as HTMLElement).parentElement;
    let currentClickCount = $(parentElement).find('input[type=hidden]')[0].value;

    $(parentElement).find('.tab-next').removeClass('disabled');
    $(parentElement).find('.tab-prev').removeClass('disabled');

     
    if(currentClickCount >=0){
      if(isLeft)
        currentClickCount++;
      else
        currentClickCount--;
    }
  
    let maxWidth = $(parentElement).find('.nav-tabs')[0].scrollWidth;
    $(parentElement).find('input[type=hidden]')[0].value = currentClickCount;
    var frameWidth = $(parentElement).width();
    var scrollAmt = frameWidth;
    scrollAmt = frameWidth*currentClickCount;
    $(parentElement).find('.nav-tabs').animate({scrollLeft: scrollAmt}, 500);  

    if(currentClickCount <= 0) {
      $(parentElement).find('.tab-prev').addClass('disabled');
    }

    if(scrollAmt >= (maxWidth - frameWidth)){
      $(parentElement).find('.tab-next').addClass('disabled');
    }
  } 

  navTabScroll(id:any, isLeft:any, event: any) { 
    const parentElement = (event.target as HTMLElement).parentElement;
    let currentClickCount = $(parentElement).find('input[type=hidden]')[0].value;

    $(parentElement).find('.tab-next').removeClass('disabled');
    $(parentElement).find('.tab-prev').removeClass('disabled');

     
    if(currentClickCount >=0){
      if(isLeft)
        currentClickCount++;
      else
        currentClickCount--;
    }
  
    let maxWidth = $(parentElement).find('.nav-tabs')[0].scrollWidth;
    $(parentElement).find('input[type=hidden]')[0].value = currentClickCount;
    var frameWidth = $(parentElement).width();
    var scrollAmt = frameWidth;
    scrollAmt = frameWidth*currentClickCount;
    $(parentElement).find('.nav-tabs').animate({scrollLeft: scrollAmt}, 500);  

    if(currentClickCount <= 0) {
      $(parentElement).find('.tab-prev').addClass('disabled');
    }

    if(scrollAmt >= (maxWidth - frameWidth)){
      $(parentElement).find('.tab-next').addClass('disabled');
    }
  } 
  

navTabScroll1(id:any, isLeft:any, event: any) {
  var frameWidth = $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs').width(), // width of div showed on display
    scrollElmtWidth = $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs')[0].scrollWidth, // width of div also with hidden part
    scrollAmount = 0,
    distance = 0,
    maxDistance = scrollElmtWidth - frameWidth, // width of hidden part - width which must be uncovered bz scrolling
    scrollStep = maxDistance/4; // divide all resolution to 4 steps
    // if (scrollElmtWidth > frameWidth) {
    //   $('.filter-section .tab-pane-body').addClass('has-scroll');
    // } else {
    //   $('.filter-section .tab-pane-body').removeClass('has-scroll');
    // }
    event.preventDefault(); 
    distance = $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs').scrollLeft();  
    
    if(isLeft)
      this.nextClickCount++;
    else
      this.nextClickCount--;

    $('#'+id+' .tab-pane-body .tab-pane.show.active .tab-next').removeClass('disabled');

    if (distance < maxDistance) {   
        //if scrolled distance is less then max distance + 1/4 px each time -> after max distance have not to count + 1/4 px
         scrollAmount += (scrollStep*this.nextClickCount);      
        $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs').animate({scrollLeft: scrollAmount}, 500);
    }else if (distance = maxDistance) {
      scrollAmount += (scrollStep*this.nextClickCount);
      $('#'+id+' .tab-pane-body .tab-pane.show .nav-tabs').animate({scrollLeft: scrollAmount}, 500);
      $('#'+id+' .tab-pane-body .tab-pane.show.active .tab-next').addClass('disabled');
    }    
}  

  getSegmentLevel2FilteredData(desiredLevel1 :any){
    // Use filter to extract data where level1 is the same
    let segdata = this.demData.Segment;
    let filteredData = segdata.filter((item: { level1: string; }) => item.level1 === desiredLevel1);
    
      const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level2: any; }) => {
      const { level2 } = item;
      result[level2] = result[level2] || [];
      result[level2].push(item);
      return result;
      
    }, {});
    
    this.groupedDatasa =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 
  }

  private containsArt(entry: { [x: string]: string; }, desiredLevel1:any): boolean {
    return ['level1', 'level2', 'level3']
      .some(key => entry[key] === desiredLevel1);
  }
    
  filterSegData(desiredLevel1:any){
    let filteredData:any = [];
    if(desiredLevel1.length > 0) {
        for(var i=0; i< desiredLevel1.length; i++){
          let result = this.segmentDataForSearch.filter((entry: any) =>        
            this.containsArt(entry, desiredLevel1[i])
          );
          
          result.forEach((item:any, key: any) => {
            filteredData.push(item);
          })
          
        }
    }else{
      filteredData = this.segmentDataForSearch;
    }  
    //Iterate through the Segment array
    this.setSegement(filteredData);
    this.getSegmentLevelWiseFilteredData();
  }
  
  getSegmentLevelWiseFilteredData(){
    this.segAllData = [];    
    const uniqueLevel1Values = [...new Set(this.demData.Segment.map((segment: { level1: any; }) => segment.level1))];

    // Iterate over unique level1 values using a for loop
    for (let i = 0; i < uniqueLevel1Values.length; i++) {
      const currentLevel1Value = uniqueLevel1Values[i];
      //console.log(`Level1 Value ${i + 1}: ${currentLevel1Value}`);


      // Use filter to extract data where level1 is the same
      let segdata = this.demData.Segment;
      let filteredData = segdata.filter((item: { level1: string; }) => item.level1 === currentLevel1Value);
      
        const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level2: any; }) => {
        const { level2 } = item;
        result[level2] = result[level2] || [];
        result[level2].push(item);
        return result;
        
      }, {});
      
      this.groupedDatasa =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 

      const obj = {
        "key": currentLevel1Value,
        "value": this.groupedDatasa
      };
    
      this.segAllData.push(obj);
      this.isTabsLoaded = true;

      //console.log('groupedDatasa:', this.groupedDatasa);
    }
  }


  filterEventData(desiredLevel1: any){
    let filteredData:any = [];
    if(desiredLevel1.length > 0) {
        for(var i=0; i< desiredLevel1.length; i++){
          let result = this.eventDataForSearch.filter((entry: any) =>        
            this.containsArt(entry, desiredLevel1[i])
          );          
          result.forEach((item:any, key: any) => {
            filteredData.push(item);
          })          
        }
    }else{
      filteredData = this.eventDataForSearch;
    }  
    
    //Iterate through the Segment array
    this.setEvent(filteredData);
    this.getEventLevelWiseFilteredData();
  }

  sortedFilters(filters:any){
    return filters.sort((a: { value: string; }, b: { value: string; }) => {
      const order = ['High','Mid', 'Low'];
      return order.indexOf(a.value) - order.indexOf(b.value);
    });
  }

  getColor(val:any){
    let color = '';

    if(val.toLowerCase() == 'high'){
      return '#3A974C';
    }else if(val.toLowerCase() == 'mid'){
      return '#FFD66B';
    }else if(val.toLowerCase() == 'low'){
      color='#FF6A77';
    }
    return color;
  }


  getEventLevelWiseFilteredData(){
    this.eventAllData = []; 
    const uniqueLevel1Values = [...new Set(this.demData.Event.map((event: { level1: any; }) => event.level1))];

    // Iterate over unique level1 values using a for loop
    for (let i = 0; i < uniqueLevel1Values.length; i++) {
      const currentLevel1Value = uniqueLevel1Values[i];
      //console.log(`Level1 Value ${i + 1}: ${currentLevel1Value}`);


      // Use filter to extract data where level1 is the same
      let evedata = this.demData.Event;
      let filteredData = evedata.filter((item: { level1: string; }) => item.level1 === currentLevel1Value);
      
        const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level2: any; }) => {
        const { level2 } = item;
        result[level2] = result[level2] || [];
        result[level2].push(item);
        return result;
        
      }, {});
      
      this.groupedDatasa =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 

      const obj = {
        "key": currentLevel1Value,
        "value": this.groupedDatasa
      };
    
      this.eventAllData.push(obj);
      this.isTabsLoaded = true;
      //console.log('groupedDatasa:', this.groupedDatasa);
    }
  }

  showPrev(){
    $('.card-item.active').prev().addClass('active');
    $('.card-item.active').next().removeClass('active');
  }

  showNext(){
    $('.card-item.active').next().addClass('active');
    $('.card-item.active').prev().removeClass('active');
  }

  /*getEventLevel2FilteredData(desiredLevel1:any){
    // Use filter to extract data where level1 is the same
    let filteredData = this.demData.Event.filter((item: { level1: string; }) => desiredLevel1.includes(item.level1));
    
      const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level2: any; }) => {
      const { level2 } = item;
      result[level2] = result[level2] || [];
      result[level2].push(item);
      return result;
      
    }, {});
    
    this.groupedEventLevel2Data =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 
  }

  getEventLevel3FilteredData(desiredLevel2:any){
     // Use filter to extract data where level1 is the same
     let filteredData = this.demData.Event.filter((item: { level2: string; }) => desiredLevel2.includes(item.level2));
    
     const groupedData = filteredData.reduce((result: { [x: string]: any[]; }, item: { level3: any; }) => {
     const { level3 } = item;
     result[level3] = result[level3] || [];
     result[level3].push(item);
     return result;
     
   }, {});
   
   this.groupedEventLevel3Data =  Object.keys(groupedData).map(key => ({key, val: groupedData[key]})); 

   console.log(this.groupedEventLevel3Data);
  }*/

  //For Segment
  isInSelFilterArr(id: string): boolean {
    return this.selFilterArr.includes(id);
  }


   //For Event
   isInSelFilterArrEvent(id: string): boolean {
    return this.selFilterArrEvent.includes(id);
  }

  updateSegmentObj(parentData:any, childData:any, event:any, idval:any ){  
    if( $(event.target).hasClass('active-btn')){
      this.selFilterArr = this.selFilterArr.filter(item => item !== idval)
    }else {
      this.selFilterArr.push(idval); 
    } 
    this.showSegmentSelSec = true;
    event.target.value = $(event.target).hasClass('active-btn') ? 'Select' : 'Selected';
    //  event.target.classList.add('active-btn');

    let removeCurrentSelection = $(event.target).hasClass('active-btn') ? true : false;
    event.target.classList.toggle('active-btn');   
      
    if(removeCurrentSelection){
      let filterLength = 0;
      let delKey = '';

      this.jsonObj.Segment.forEach((segment: { level1: string; level2: string; level3: string; filters: { value: any; count: any; }[]; }, key:any) => {
        if (
          segment.level1 === parentData.level1 &&
          segment.level2 === parentData.level2 &&
          segment.level3 === parentData.level3 &&
          segment.filters.some((filter) => filter.count == childData.count && filter.value == childData.value)
        ) {
          filterLength = segment.filters.length;
          delKey = key;
        }
      });

      if(filterLength == 1){
        this.jsonObj.Segment.splice(delKey, 1);       
      }
      
     

      // this.segmentSelectedLevel.forEach((segment: { level1: string; level2: string; level3: string; }, key: any) => {
      //   if (segment.level1 === parentData.level1 
      //     && segment.level2 === parentData.level2 
      //     && segment.level3 === parentData.level3) {
      //     // If there's a match, remove from segmentSelectedLevel
      //     this.segmentSelectedLevel.splice(key, 1);
      //   }
      // });
      
      const condition = { value: childData.value, count: childData.count };

      // Iterate over the "Segment" array and filter out items that match the condition
      // this.jsonObj.Segment.forEach((segment: { filters: any[]; }) => {
      //   segment.filters = segment.filters.filter((filter: { value: string; count: string; }) => {
      //     return filter.value !== condition.value && filter.count !== condition.count;
      //   });
      // });

      // Use the filter method to create a new array without items that match the conditions
       this.jsonObj.Segment.map((item: { level1: any; level2: any; level3: any; filters: any[]; }) => {
        if (
          item.level1 === parentData.level1 &&
          item.level2 === parentData.level2 &&
          item.level3 === parentData.level3
        ) {
          item.filters = item.filters.filter(
            (            filterItem: { value: any; count: any; }) =>
              filterItem.value !== condition.value &&
              filterItem.count !== condition.count
          );
        }
        return item;
      });
    }else{
       // Define the new filter and values
      const newFilter = {
        "value": childData.value,
        "count": childData.count
      };
    
      // Flag to keep track of whether a match is found
      let matchFound = false;
      
      // Iterate through the "Segment" array
      this.jsonObj.Segment.forEach((segment: { level1: string; level2: string; level3: string; filters: { value: any; count: any; }[]; }) => {
        if (segment.level1 === parentData.level1 
          && segment.level2 === parentData.level2 
          && segment.level3 === parentData.level3) {
          // If there's a match, update the filters
          segment.filters.push(newFilter);
          matchFound = true;
        }
      });
      
      var selectedSegment = {
        'level1': parentData.level1,
        'level2': parentData.level2,
        'level3': parentData.level3,
      }
      this.segmentSelectedLevel.push(selectedSegment);

      // If no match was found, create a new object
      if (!matchFound) {
        const newSegment = {
          "level1": parentData.level1,
          "level2": parentData.level2,
          "level3": parentData.level3,
          "filters": [newFilter]
        };
        this.jsonObj.Segment.push(newSegment);
      }
    }
  
    //console.log('LATET:', this.segmentSelectedLevel);
    this.updateJsonObj(false);

    if(this.jsonObj.Segment.length <=0){
      this.showSegmentSelSec = false;
    }
  }

  
  
  updateEventObj(parentData:any, childData:any, event:any, idval:any ){   
    if( $(event.target).hasClass('active-btn')){
      this.selFilterArrEvent = this.selFilterArrEvent.filter(item => item !== idval)
    }else {
      this.selFilterArrEvent.push(idval);
    } 
    this.showEventSelSec = true;
      event.target.value = $(event.target).hasClass('active-btn') ? 'Select' : 'Selected';
    //  event.target.classList.add('active-btn');

      let removeCurrentSelection = $(event.target).hasClass('active-btn') ? true : false;
      event.target.classList.toggle('active-btn');
      

    if(removeCurrentSelection){
      let filterLength = 0;
      let delKey = '';

      this.jsonObj.Event.forEach((event: { level1: string; level2: string; level3: string; filters: { value: any; count: any; }[]; }, key:any) => {
        if (
          event.level1 === parentData.level1 &&
          event.level2 === parentData.level2 &&
          event.level3 === parentData.level3 &&
          event.filters.some((filter) => filter.count == childData.count && filter.value == childData.value)
        ) {
          filterLength = event.filters.length;
          delKey = key;
        }
      });

      if(filterLength == 1){
        this.jsonObj.Event.splice(delKey, 1);       
      }



      const condition = { value: childData.value, count: childData.count };

      // Iterate over the "Event" array and filter out items that match the condition
      // this.jsonObj.Event.forEach((event: { filters: any[]; }) => {
      //   event.filters = event.filters.filter((filter: { value: string; count: string; }) => {
      //     return filter.value !== condition.value || filter.count !== condition.count;
      //   });
      // });

      this.jsonObj.Event.map((item: { level1: any; level2: any; level3: any; filters: any[]; }) => {
        if (
          item.level1 === parentData.level1 &&
          item.level2 === parentData.level2 &&
          item.level3 === parentData.level3
        ) {
          item.filters = item.filters.filter(
            (            filterItem: { value: any; count: any; }) =>
              filterItem.value !== condition.value &&
              filterItem.count !== condition.count
          );
        }
        return item;
      });
  
      // Display the updated object
    }else{
       // Define the new filter and values
      const newFilter = {
        "value": childData.value,
        "count": childData.count
      };
    
      // Flag to keep track of whether a match is found
      let matchFound = false;
      
      // Iterate through the "Event" array
      this.jsonObj.Event.forEach((event: { level1: string; level2: string; level3: string; filters: { value: any; count: any; }[]; }) => {
        if (event.level1 === parentData.level1 
          && event.level2 === parentData.level2 
          && event.level3 === parentData.level3) {
          // If there's a match, update the filters
          event.filters.push(newFilter);
          matchFound = true;
        }
      });
      var selectedEvent = {
        'level1': parentData.level1,
        'level2': parentData.level2,
        'level3': parentData.level3,
      }
      this.eventSelectedLevel.push(selectedEvent);

      // If no match was found, create a new object
      if (!matchFound) {
        const newEvent = {
          "level1": parentData.level1,
          "level2": parentData.level2,
          "level3": parentData.level3,
          "filters": [newFilter]
        };
        this.jsonObj.Event.push(newEvent);
      }
    }
    
    this.updateJsonObj(false);

    if(this.jsonObj.Event.length <=0){
      this.showEventSelSec = false;
    }
  }

  onSelectFromAge(key: any, event: any) {
    const selectedValue = event.target.value;
    let keyname = '';

    if(key == 'Household Class'){
      keyname = 'Household Class';
    } else if(key == 'Education'){
      keyname = 'Education';
    } else if(key == 'Household Income'){
      keyname = 'Household Income';
    } else if(key == 'Life Stage'){
      keyname = 'Life Stage';
    } else if(key == 'Age'){
      keyname = 'Age';
    } else if(key == 'Gender'){
      keyname = 'Gender';
    } else if(key == 'Occupation'){
      keyname = 'Occupation';
    } 
    else if(key == 'Parental'){
      keyname = 'Parental';
    } 
    
    // Replace these values with the actual data you want to add
    const newFilter = {
      value: selectedValue
    };

    this.jsonObj.Demographics[keyname].filters.push(newFilter);

    //Remove from Object if deselction from UI
    let removeCurrentSelection = $(event.target).hasClass('active-btn') ? true : false;
    if(removeCurrentSelection){
      this.jsonObj.Demographics[keyname].filters = this.jsonObj.Demographics[keyname].filters.filter((filter: { value: string; }) => filter.value !== selectedValue);
    }

    this.isFilterAppliedInDemographics = true;
    this.updateJsonObj(true);
    event.target.classList.toggle('active-btn');
  }

  onSelectFromHouseHold(key: any, selectedValue:any) {
    let keyname = '';

    if(key == 'Household Class'){
      keyname = 'Household Class';
    } else if(key == 'Education'){
      keyname = 'Education';
    } else if(key == 'Household Income'){
      keyname = 'Household Income';
    } else if(key == 'Life Stage'){
      keyname = 'Life Stage';
    } else if(key == 'Age'){
      keyname = 'Age';
    } else if(key == 'Gender'){
      keyname = 'Gender';
    } else if(key == 'Occupation'){
      keyname = 'Occupation';
    }
   
    // Replace these values with the actual data you want to add
    this.jsonObj.Demographics[keyname].filters = [];
    for(var i=0; i < selectedValue.length; i++){
      const newFilter = {
        value: selectedValue[i]
      };
      this.jsonObj.Demographics[keyname].filters.push(newFilter);
    }
    //Remove from Object if deselction from UI
     var removeCurrentSelection = (this.jsonObj.Demographics[keyname].filters.length > selectedValue.length) ? true: false; //$(event.target).hasClass('active-btn') ? true : false;
     if(removeCurrentSelection){
       this.jsonObj.Demographics[keyname].filters = this.jsonObj.Demographics[keyname].filters.filter((filter: { value: string; }) => filter.value !== selectedValue);
     }
    this.updateJsonObj(true);

  }

  updateJsonObj(updateSegEventObj:boolean){    
    this.isDisabledsaveBtn = false;
    this.jsonObj.UserID = this.sessionService.getItem('sessionUserName');
    this.DBAPIObj.getFilterCount(this.jsonObj).subscribe({
      next: (data) => {
       // this.demData = data
        this.userCount = parseInt(data['Total Count']);
        //this.setLocation(data['Location']);


        // this.segmentDataForSearch = data['Segment'];
        // if(updateSegEventObj){
        //   this.setSegement(data['Segment']);
        //   this.getSegmentLevelWiseFilteredData();
        // }
        

        
        // this.eventDataForSearch = data['Event'];
        // if(updateSegEventObj){
        //   this.setEvent(data['Event']);
        //   this.getEventLevelWiseFilteredData();
        // }

        this.getSegEventData(updateSegEventObj);
        this.updateChart();
      },
      error: (error) => {
        console.log(error)
        alert('Error fetching data:'+ error.replaceAll('_', ' '));
      },
    });
  }

  getSegEventData(updateSegEventObj:any){
    this.DBAPIObj.getSegEventFilterCount(this.jsonObj).subscribe({
      next: (data) => {
        this.segmentDataForSearch = data['Segment'];
        if(updateSegEventObj){
          this.setSegement(data['Segment']);
          this.getSegmentLevelWiseFilteredData();
        }
        

        
        this.eventDataForSearch = data['Event'];
        if(updateSegEventObj){
          this.setEvent(data['Event']);
          this.getEventLevelWiseFilteredData();
        }
      },
      error: (error) => {
        console.log(error)
        alert('Error fetching data:'+ error.replaceAll('_', ' '));
      },
    });
  }

  saveAudConfirmationDialog(){
    this.dialog.closeAll();
    this.dialog.open(this.saveAudienceDialog, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    });
    this.creatAudBtnDisable = false;
  }

  saveCampaign(){
    var postparams = {
      "campaignName" : $('#saveCampaignComment').val(),
      "userId" : this.sessionService.getItem('sessionUserName')
   }
   
   let txtVal = $('#saveCampaignComment').val();

   let pattern = new RegExp('^[a-zA-Z0-9-_]*$'); 
   if (txtVal == '' || txtVal == undefined){
         $('#campmsg').html('Please enter campaign name');
         $('#campmsg').addClass('error');
   }
   else if(!txtVal.match(pattern)){   
     $('#campmsg').html('Please select valid campaign name without special character');
     $('#campmsg').addClass('error');

   }
   else{
    this.creatCampBtnDisable = true;

    this.DBAPIObj.validateCampaign(postparams).subscribe({
      
      next: (data: any) => {
        this.sessionService.setItem('sessionCampaignName', $('#saveCampaignComment').val());
        this.sessionService.setItem('audienceSetType', 'flow');
        this.dialog.closeAll();
        this.router.navigate(['campaign']);
      },
      error: (error) => {
        // this.camperror = true;        
        // this.campmsg = error.error['message']; 
        this.creatCampBtnDisable = false;
      
        $('#campmsg').html(error.error['message'].replaceAll('_', ' '));
          $('#campmsg').addClass('error'); 
      }
    }); 
   }
  }

  disaplayAudScreen(){
    location.reload();
  }

  displayCampaignScreen(){
    this.dialog.closeAll();
    this.dialog.open(this.saveCampaignDialog, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    });
    // this.dialog.closeAll();
    // this.router.navigate(['campaign']); 
  }

  saveAudienceData(){  
    let selProject = this.sessionService.getItem('sessionProjectName');
    let txtVal = $('#saveComment').val();

    let pattern = new RegExp('^[a-zA-Z0-9-_]*$'); 
    if (selProject == '' || selProject == undefined){
          $('#audmsg').html('Please select project first');
          $('#audmsg').addClass('error');
    }
    else if(!txtVal.match(pattern)){   
      $('#audmsg').html('Please select valid audience name without special character');
      $('#audmsg').addClass('error');

    }
    else{
      this.creatAudBtnDisable = true;

      let savejsonObj: any = {
        AudienceFilter: {
        },
        "AudienceListName": "",
        "Organization": ORG_ID,
        "Project": selProject,
        "UserID": this.sessionService.getItem('sessionUserName')
      };

   
      //alert(txtVal);
      this.sessionService.setItem('sessionAudienceName', txtVal);
      this.jsonObj.Project = selProject;
      savejsonObj['AudienceFilter'] = this.jsonObj;
      savejsonObj['AudienceListName'] = txtVal;
      
      this.isLoaderDisplayFullPage = true;
    
      this.DBAPIObj.saveAudieanceData(savejsonObj).subscribe({
        next: (data) => {
          // $('#displaySaveMsg').html('Audieance Data Save Successfully!!');
          // $('#displaySaveMsg').addClass('success');
          this.isLoaderDisplayFullPage = false;
          this.displaySaveMsg = 'Your audience data has been saved';          
          this.dialog.closeAll();
          this.creatAudBtnDisable = true;
          this.dialog.open(this.AudienceConfirmNavigation, {
            height: '363',
            width: '560px',
          }); 
        },
        error: (error) => {
          console.log(error.error);
          this.isLoaderDisplayFullPage = false;
          this.creatAudBtnDisable = false;
          // this.auderror = true;
          // this.audmsg = error.error['message'];

          $('#audmsg').html(error.error.replaceAll('_', ' '));
          $('#audmsg').addClass('error');
        },
      });
    }
  }
 

  transformData(data: any): { key: string, value: any }[] {
    return Object.keys(data).map(key => ({ key, value: data[key] }));
  }

  updateChart() {
    // Simulate updating the user count
    //this.userCount = Math.floor(Math.random() * 100);

    // Update the chart's title text and data
    // if (this.chart) {
    //   this.chart.setTitle({ text: this.userCount.toString() });
    //   this.chart.series[0].setData([{ y: this.userCount, color: '#5B93FF' }]);
    // }

    // const chartOptions: Highcharts.Options = {
    //   title: {
    //     text: this.userCount
    //   },
    //   series: [
    //     {
    //       type: 'pie',
    //       data: [
    //         { y: this.userCount, color: '#5B93FF' },
    //         { y: this.initialTotalCount - this.userCount, color: '#ffffff' }
    //       ],
    //     },
    //   ],
    // };
    

    // if (this.chart) {
    //   this.chart.update(chartOptions, true);
    // }


    var data = [
      { value: this.userCount},
      { value: this.initialTotalCount - this.userCount}
    ];
    var colorPalette = ['#5B93FF', '#cee6fe'];
    
    var formattedNumber = parseInt(this.userCount).toFixed().toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.pieChartOptions = {
     
      title: {
        text: formattedNumber, //this.userCount,
        left: 'center',
        top: 'center',
      },
      width: '100%',
      series: [
        {
          type: 'pie',
          radius: ['83%', '63%'],
          center: ['50%', '50%'],
          label: {
            show: false
          },
          data: data,
          color: colorPalette
        }
      ],
    };
  }

  
  getProjectsList(){
    var postParams = {
      "user": this.sessionService.getItem('sessionUserName')
    }
    this.DBAPIObj.listProject(postParams).subscribe({
      next: (data: any) => {
        if (data && data['projects']){
          this.error = false;  
          this.DBAPIObj.setprojectList(data['projects']);
          this.projectLists = data['projects'];  
          $("#projectDropdown").select2();        
           
        }else {
          this.error = true;
          this.listmsg = data['message'].replaceAll('_', ' ');        
        }
      },
      error: (error) => {
        this.error = true;
        this.listmsg = 'Error occured while fetching project';          
      }
    });
  }

  formatImageName(imgname:any){
    return imgname.replaceAll('_', ' ').replaceAll('and', '_');
  }

  toCamelCase(inputString:any) {
    //console.log(inputString);
    const words = inputString.split(/\s+/);
  
    const camelCaseWords = words.map((word: string, index: number) => {
      // Capitalize each word (except excluded words)
      if (index === 0 || !["and", "or", "between"].includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    });
  
    return camelCaseWords.join(' ');
  }

  sortAgeValues(inputList: string[]) {
    const ltSign = inputList.filter(val => val.includes('<'));
    const plusSign = inputList.filter(val => val.includes('+'));
    const unknown = inputList.filter(val => val.toLowerCase().includes('unknown'));
    const rangeValues = inputList.filter(val => !val.includes('+') && !val.includes('<') && !val.toLowerCase().includes('unknown'));

    // Sort the rangeValues based on the first part of each range
    const sortedRangeValues = rangeValues.sort((a, b) => {
      const aStart = parseInt(a.split('-')[0], 10);
      const bStart = parseInt(b.split('-')[0], 10);
      return aStart - bStart;
    });

    // Concatenate the sorted values based on the given order
    const result = [...ltSign, ...sortedRangeValues, ...plusSign, ...unknown];

    return result;
  }

  sortedHouseHold(inputList:string[]){
    const top = inputList.filter(val => val.toLowerCase().includes('top'));
    const lower = inputList.filter(val => val.toLowerCase().includes('lower'));
    const unknown = inputList.filter(val => val.toLowerCase().includes('unknown'));
    const rangeValues = inputList.filter(val => !val.toLowerCase().includes('top') && !val.toLowerCase().includes('lower') && !val.toLowerCase().includes('unknown'));

    // Sort the rangeValues based on the first part of each range
    const sortedRangeValues = rangeValues.sort((a, b) => {
      const aStart = parseInt(a.split('-')[0], 10);
      const bStart = parseInt(b.split('-')[0], 10);
      return aStart - bStart;
    });

    // Concatenate the sorted values based on the given order
    const result = [...top, ...sortedRangeValues, ...lower, ...unknown];

    return result;
  }
}