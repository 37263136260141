import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { DBAPIService } from 'src/app/dbapi.service';
import { HttpClient } from  '@angular/common/http';
declare var $ : any;
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isLogin: boolean = false;
  isAdminUser: boolean = false;
  currSelMenu:any='';

  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, 
    private sessionService: SessionService, private route: ActivatedRoute, private router: Router){ }
    
  ngOnInit(){
    this.route.url.subscribe(urlSegments => {
      // The current route is available in the urlSegments array
      this.currSelMenu = urlSegments[0]?.path;
    });

    this.sessionService.sessionData.subscribe(data => {
      this.isAdminUser =  this.sessionService.getItem('sessionUserName') == environment.admin_user_email;
      this.isLogin = data || this.sessionService.getItem('sessionUserName');
    })
  }

  logout(event:any){
    this.selectMenu(event);
    this.sessionService.removeItem('sessionUserName');
    this.sessionService.setSessionData(''); 
    this.router.navigate(['']);   
  }

  selectMenu(opt:any){
    this.currSelMenu = opt;
    this.router.navigate([opt]);
    // alert();
    // $('.left-links').removeClass('active-opt')
    // $(event.target).parents('.left-links').addClass('active-opt');
  }
}
