import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from  '@angular/common/http';
import { SessionService } from '../session.service';
import { DBAPIService } from 'src/app/dbapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
declare var $ : any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, AfterViewInit{
  project = {    
    project: ''
  };

  imagePath:any=environment.imagePath;
  msg: any = '';
  delmsg:any = '';
  editmsg:any = '';
  editerror:any = false;
  listmsg: any = '';
  projectLists: any = '';
  error: boolean = false;
  existingProj:any= '';
  creatBtnDisable:any=false;

  recordsPerPage = 10;
  currentPage = 1;
  pagination: number[] = [];
  projListData :any = {};

  pagination_data = {
    num_per_page: 0,
    total_data: 0,
    current_page: 1,
  };

  pageNumber = 1;

  filterFormData = {
    page: this.currentPage,
    num_per_page: this.recordsPerPage    
  };

  isLoaderDisplayFullPage:boolean = false;
  editDeleteMsg:any;

  createmsg:any='';
  createerror:any='';

  @ViewChild('projectCreateConfirmNavigation') projectCreateConfirmNavigation!: TemplateRef<any>;
  @ViewChild('projectEditScreen') projectEditScreen!: TemplateRef<any>;
  @ViewChild('projectDeleteSuccessScreen') projectDeleteSuccessScreen!: TemplateRef<any>;



  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, 
    private sessionService: SessionService, private router: Router, private dialog: MatDialog){ }

  ngOnInit(){      
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }
    this.listProject();
  }

  loadData(pageNumber: number) {
    this.filterFormData.page = pageNumber;
    this.currentPage = pageNumber;
    this.pageNumber = pageNumber;

    if (pageNumber >= 1 && pageNumber <= this.pagination.length) {
      this.getSubscriptions();
    }
  }

  getTotalData() {
    return this.pagination_data.total_data;
  }

  getSubscriptions() {
    //initially till index 10
   // this.audListData = newdata;
  }

  getCurrentRange() {
    return (this.pagination_data.num_per_page * (this.currentPage - 1)) + 1;
  }

  getCurrentStartIndex() {
    return this.getCurrentRange() - 1;
  }

  getCurrentLastIndex() {
    return (this.currentPage *  this.recordsPerPage) - 1;
  }

  calculatePagination() {
    this.pagination = Array.from({ length: Math.ceil(this.pagination_data.total_data / this.pagination_data.num_per_page) }, (_, index) => index + 1);
  }

  dateFormat(dt:any, time: any = ''){
    if(time){
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy - hh:mm',"en-US");
    }
    else{
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy',"en-US");
    }
  }

  listProject(){
    var postParams = {
      "user": this.sessionService.getItem('sessionUserName')
    }
    this.DBAPIObj.listProject(postParams).subscribe({
      next: (data: any) => {
        if (data && data['projects']){
          this.error = false;  
          this.DBAPIObj.setprojectList(data['projects']);
          this.projectLists = data['projects']; 
          
          this.pagination_data.total_data = data['projects'].length;

           // Mock data for pagination_data
  
          this.pagination_data.num_per_page = this.recordsPerPage;

          this.calculatePagination();
          this.getSubscriptions();
          this.loadData(this.currentPage);
           
        }else {
          this.projectLists = '';
          this.error = true;
          this.listmsg = data['message'];        
        }
      },
      error: (error) => {
        this.projectLists = '';
        this.error = true;        
        this.listmsg = error.error['message'];  
      }
    });
  }

  navigateAud(selProj:any){
    this.sessionService.setItem('sessionProjectName', selProj);
    this.router.navigate(['audience']);  
  }


  showEditProjModal(projName:any){
    this.existingProj = projName;
    this.dialog.open(this.projectEditScreen, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    })
  }

  updateProject(){    
    let newProj = $('#newProject').val();
    var postparams = {
        "operation" : 'rename',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "project" : this.existingProj,
        "newName" : newProj
    }

    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll();
    this.DBAPIObj.updateProject(postparams).subscribe({
      next: (data: any) => {
        this.isLoaderDisplayFullPage = false;
        if (data && data['message']){
          this.editerror =  false;  
          this.editmsg = data['message'];   
         // this.openDialogAfterProjectCreateConfirmation();
        // this.dialog.open(this.projectCreateConfirmNavigation);
        this.sessionService.setItem('sessionProjectName', newProj);
        // this.router.navigate(['audience']);  
           this.listProject();
           
          this.editDeleteMsg = 'Project updated successfully';
          this.dialog.open(this.projectDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
           
        }else {
          this.editerror = true;
          this.editmsg = data['message'];  
          
          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = data['message'];  
        }
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        this.editerror = true;
        this.editmsg = error.error['message'];          
      }
    });
  }

  deleteProject(projName:any){
    var postparams = {
        "operation" : 'delete',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "project" : projName
    }
    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll();
    this.DBAPIObj.updateProject(postparams).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;  
          this.delmsg = data['message'];   
    
          this.sessionService.setItem('sessionProjectName', projName);
          this.listProject();
          this.currentPage = 1;
          
          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = 'Project deleted successfully';
          this.dialog.open(this.projectDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }else {
          this.error = true;
          this.isLoaderDisplayFullPage = false;
          this.delmsg = data['message'];  
          
          this.editDeleteMsg = data['message'];   
          this.dialog.open(this.projectDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        this.error = true;
        this.delmsg = error.error['message'];          
      }
    });
  }

  createProject(){ 
    let pattern = new RegExp('^[a-zA-Z0-9-_]*$');   
    if(this.project.project == '' || this.project.project == undefined){
      this.error = true;
      this.msg = 'Please enter project name';
    }
    else if(!this.project.project.match(pattern)){      
      this.error = true;
      this.msg = 'Please enter valid project name without special character';
    }
    else{
      this.creatBtnDisable = true;
      var postParams = {
        'project': this.project.project,
        'user': this.sessionService.getItem('sessionUserName')
      }
      this.DBAPIObj.createProject(postParams).subscribe({
        next: (data: any) => {
          if (data && data['message']){
            this.error = false;  
            this.msg = data['message'];   
          // this.openDialogAfterProjectCreateConfirmation();
          // this.dialog.open(this.projectCreateConfirmNavigation);
          this.sessionService.setItem('sessionProjectName', postParams.project);
          this.router.navigate(['audience']);  
            
          }else {
            this.createerror = true;
            this.createmsg = data['message'];        
          }
          this.creatBtnDisable = false;
        },
        error: (error) => {
          this.createerror = true;
          this.createmsg = error.error['message'];  
          this.creatBtnDisable = false;        
        }
      });
    }  
  }

  ngAfterViewInit(){
      this.DBAPIObj.projectList.subscribe(projectListData => {
        this.projectLists = projectListData;
      })
  }  

}
