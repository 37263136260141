import { Component } from '@angular/core';
import { DBAPIService } from 'src/app/dbapi.service';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})



export class HealthCheckComponent {

  healthCheckResult:any ='';
  
  constructor(private DBAPIObj: DBAPIService) {}
}
