import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { DBAPIService } from 'src/app/dbapi.service';
import { SessionService } from '../session.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
declare var $ : any;

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent {

  imagePath:any=environment.imagePath;
  msg: any = '';
  delmsg:any = '';
  editerror:any='';
  editmsg:any = '';
  listmsg: any = '';
  error: boolean = false;
  existingProj:any= '';
  creatBtnDisable:any = false;

  recordsPerPage = 10;
  currentPage = 1;
  pagination: number[] = [];
  campaignListData :any = {};
  islistLoaded:any=false;

  existingCampaign:any = '';

  createerror:any= false;        
  campaigncreatemsg:any = '';  

  pagination_data = {
    num_per_page: 0,
    total_data: 0,
    current_page: 1,
  };

  pageNumber = 1;

  filterFormData = {
    page: this.currentPage,
    num_per_page: this.recordsPerPage    
  };
  campaignListmsg:any;
  isLoaderDisplayFullPage:boolean = false;
  editDeleteMsg: any;
  campaign = {    
    campaignName: ''
  };

  @ViewChild('campaignEditScreen') campaignEditScreen!: TemplateRef<any>;
  @ViewChild('campaignDeleteSuccessScreen') campaignDeleteSuccessScreen!: TemplateRef<any>;
  
  constructor(private dialog: MatDialog, private router: Router, private DBAPIObj: DBAPIService, private sessionService: SessionService) {}

  ngOnInit() {
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }
    
    this.listCampaign();
    this.sessionService.removeItem('sessionProjectName');
    this.sessionService.removeItem('sessionAudienceName');
    this.sessionService.removeItem('sessionCampaignName');
    this.sessionService.removeItem('audienceSetType');
  }

  
  dateFormat(dt:any, time: any = ''){
    if(time){
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy - hh:mm',"en-US");
    }
    else{
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy',"en-US");
    }
  }

  updateCampaign(){
    let newCampaign = $('#newCampaign').val();
    var postparams = {
        "operation" : 'rename',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "oldName" : this.existingCampaign,
        "newName" : newCampaign
    }
    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll(); 

    this.DBAPIObj.updateCampaign(postparams).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;  
          this.editmsg = data['message'];   
         // this.openDialogAfterProjectCreateConfirmation();
        // this.dialog.open(this.projectCreateConfirmNavigation);
        //this.sessionService.setItem('sessionProjectName', newProj);
        // this.router.navigate(['audience']);  
           this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = 'Campaign updated successfully';
          this.dialog.open(this.campaignDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
          
          this.listCampaign();

        }else {
          this.dialog.closeAll(); 
          this.editerror = true;
          this.editmsg = data['message'];  
          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = data['message'];       
        }
      },
      error: (error) => {
        this.editerror = true;
        this.isLoaderDisplayFullPage = false;
        this.editmsg = error.error['message'];          
      }
    });
  }


  showEditCampaignModal(campaignName:any){
    this.existingCampaign = campaignName;
    this.dialog.open(this.campaignEditScreen, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    })
  }

  deleteCampaign(campaignName:any){
    var postparams = {
        "operation" : 'delete',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "oldName" : campaignName
    }

    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll(); 

    this.DBAPIObj.updateCampaign(postparams).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;  
          this.delmsg = data['message'];   
    
          //this.sessionService.setItem('sessionProjectName', projName);
         

          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = 'Campaign deleted successfully';
          this.dialog.open(this.campaignDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });

          this.currentPage = 1;
          this.listCampaign();
        }else {
          this.error = true;
          this.isLoaderDisplayFullPage = false;
          this.delmsg = data['message'];   
          
          this.editDeleteMsg = data['message'].replaceAll('_', ' ');   
          this.dialog.open(this.campaignDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        this.error = true;
        this.delmsg = error.error['message'].replaceAll('_', ' ');          
      }
    });
  }

  listCampaign(){
    var postParams = {
      "userId": this.sessionService.getItem('sessionUserName')
    }

    this.DBAPIObj.getCampaignListData(postParams).subscribe({
      next: (data: any) => {
        this.islistLoaded = true;
        this.campaignListData = data

        this.pagination_data.total_data = data.length;
        // Mock data for pagination_data
  
        this.pagination_data.num_per_page = this.recordsPerPage;

        this.calculatePagination();
        this.getSubscriptions();
        this.loadData(this.currentPage);
      },
      error: (error) => {
        this.campaignListData = '';
        this.error = true;        
        this.campaignListmsg = error.error['message'].replaceAll('_', ' ');  
      }
    });
  }

  createCampaign(type: any, name: any = '', item:any, ausetType:any) {
    let pattern = new RegExp('^[a-zA-Z0-9-_]*$');   
    if((this.campaign.campaignName == '' || this.campaign.campaignName == undefined) && type == 'form'){
      this.error = true;
      this.msg = 'Please enter campaign name';
    }
    else if(!this.campaign.campaignName.match(pattern)){      
      this.error = true;
      this.msg = 'Please enter valid campaign name without special character';
    }
    else{
      this.creatBtnDisable = true;
      if(ausetType == 'flow') {
        var postparams = {
          "campaignName" : this.campaign.campaignName,
          "userId" : this.sessionService.getItem('sessionUserName')
       }
        this.DBAPIObj.validateCampaign(postparams).subscribe({
          next: (data: any) => {
            var campaignName = (type == 'form')? this.campaign.campaignName :  name;
            this.sessionService.setItem('audienceSetType', ausetType);
            this.sessionService.setItem('campainChannel', item.channel);
            this.sessionService.setItem('campaignChannelStatus', item.status);
            this.sessionService.setItem('campaignChannelFilter', item.filterCondition);
            this.sessionService.setItem('sessionCampaignName', campaignName);
            this.router.navigate(['campaign']);
            this.creatBtnDisable = false;
          },
          error: (error) => {
            this.createerror = true;        
            this.campaigncreatemsg = error.error['message'].replaceAll('_', ' ');  
            this.creatBtnDisable = false;
          }
        });
      }else {
        var campaignName = (type == 'form')? this.campaign.campaignName :  name;
        this.sessionService.setItem('audienceSetType', ausetType);
        this.sessionService.setItem('campainChannel', item.channel != undefined ? item.channel : '');
        this.sessionService.setItem('campaignChannelStatus', item.status);
        this.sessionService.setItem('campaignChannelFilter', item.filterCondition);
        this.sessionService.setItem('sessionCampaignName', campaignName);
        this.router.navigate(['campaign']);
      }
    }
  }

  loadData(pageNumber: number) {
    this.filterFormData.page = pageNumber;
    this.currentPage = pageNumber;
    this.pageNumber = pageNumber;

    if (pageNumber >= 1 && pageNumber <= this.pagination.length) {
      this.getSubscriptions();
    }
  }

  getTotalData() {
    return this.pagination_data.total_data;
  }

  getSubscriptions() {
    //initially till index 10
   // this.audListData = newdata;
  }

  getCurrentRange() {
    return (this.pagination_data.num_per_page * (this.currentPage - 1)) + 1;
  }

  getCurrentStartIndex() {
    return this.getCurrentRange() - 1;
  }

  getCurrentLastIndex() {
    return (this.currentPage *  this.recordsPerPage) - 1;
  }

  calculatePagination() {
    this.pagination = Array.from({ length: Math.ceil(this.pagination_data.total_data / this.pagination_data.num_per_page) }, (_, index) => index + 1);
  }
}
