<app-header></app-header>

<div class="container-fluid content-area">
    <div class="d-flex">
        
        <div class="left-panel col-2">
            <app-sidebar></app-sidebar>
        </div>
    </div>
    <h5 class="modal-title" id="signUpModalLabel">Register to HKT</h5>
    <div class="registration-form">
        <form (ngSubmit)="createUser()" #registrationForm="ngForm" ngNativeValidate>
           
                <!-- <label class="label" for="username">Username</label> -->
                <input class="form-control" placeholder="Full Name" type="text" id="username" name="username" [(ngModel)]="user.username" required>
                <!-- <div *ngIf="registrationForm?.controls?.['username']?.touched && registrationForm?.controls?.['username']?.errors?.['required']">
                    Name is required.
                </div> -->
          
                <!-- <label class="label" for="email">Email</label> -->
                <input class="form-control" placeholder="Email" type="email" id="email" name="email" [(ngModel)]="user.email" required>
                <!-- <div *ngIf="registrationForm?.controls?.['email']?.touched && registrationForm?.controls?.['email']?.errors?.['required']">
                    Email is required.
                </div> -->
       
                <!-- <label class="label" for="password">Password</label> -->
                <input class="form-control" placeholder="Password" type="password" id="password" name="password" [(ngModel)]="user.password" required>
        
                <button type="submit" class="form-control btn btn-primary rounded submit px-3">Register</button>
           
            <div><span class="haveAcntText">Have an account? </span><span><button type="button" class="LoginNowBtnLink"> Login Now</button></span></div>
        </form>
    </div> 
</div>


     
      