import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { DBAPIService } from 'src/app/dbapi.service';
import { SessionService } from '../session.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
declare var $ : any;

@Component({
  selector: 'app-audience-list',
  templateUrl: './audience-list.component.html',
  styleUrls: ['./audience-list.component.scss']
})
export class AudienceListComponent implements OnInit {

  creatCampBtnDisable:any=false;

  imagePath:any=environment.imagePath;
  audSel_popup:any='';
  projSel_popup:any='';

  editerrormsg:any='';
  editmsg:any='';
  delerror:any='';
  delmsg:any='';
  existingAud:any ='';
  existingProj:any='';
  recordsPerPage = 10;
  currentPage = 1;
  pagination: number[] = [];
  audListData :any = {};

  pagination_data = {
    num_per_page: 0,
    total_data: 0,
    current_page: 1,
  };

  pageNumber = 1;

  filterFormData = {
    page: this.currentPage,
    num_per_page: this.recordsPerPage    
  };

  audienceListmsg: any;
  error: boolean = false;
  editDeleteMsg: any = '';
  loadList:any=false;

  @ViewChild('audienceEditScreen') audienceEditScreen!: TemplateRef<any>;
  @ViewChild('audienceDeleteSuccessScreen') audienceDeleteSuccessScreen!: TemplateRef<any>;
  @ViewChild('saveCampaignDialog') saveCampaignDialog!: TemplateRef<any>;
  
  constructor(private dialog: MatDialog, private router: Router, private DBAPIObj: DBAPIService, private sessionService: SessionService) {}

  ngOnInit() {
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }
    
    this.listAudiences();
    this.sessionService.removeItem('sessionProjectName');
    this.sessionService.removeItem('sessionAudienceName');
    this.sessionService.removeItem('sessionCampaignName');
    this.sessionService.removeItem('audienceSetType');
  }

  listAudiences(){
    var postParams = {
      "UserID": this.sessionService.getItem('sessionUserName')
    }
    this.DBAPIObj.getAudListData(postParams).subscribe({
      next: (data: any) => {
        this.loadList=true;
        this.audListData = data

      
        this.pagination_data.total_data = data.length;
          // Mock data for pagination_data
  
        this.pagination_data.num_per_page = this.recordsPerPage;

        this.calculatePagination();
        this.getSubscriptions();
        this.loadData(this.currentPage);
      },
      error: (error) => {
        this.error = true;        
        this.audienceListmsg = error.error['message'].replaceAll('_', ' ');  
      }
    });
  }

  //EDIT/DELE
  showEditAudModal(audName:any, projName:any){
    this.existingAud = audName;
    this.existingProj = projName;
    this.dialog.open(this.audienceEditScreen, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    })
  }

  isLoaderDisplayFullPage: boolean = false;
  updateProject(){
    let newProj = $('#newProject').val();
    var postparams = {
        "operation" : 'rename',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "project" : this.existingProj,
        "oldName" : this.existingAud,
        "newName" : newProj
    }
    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll(); 
    this.DBAPIObj.updateAudience(postparams).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.editerrormsg = false;  
          this.editmsg = data['message'];   
          this.isLoaderDisplayFullPage = false;          
          this.listAudiences();
          this.editDeleteMsg = 'Audience updated successfully';
          this.dialog.open(this.audienceDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }else {
          this.dialog.closeAll(); 
          this.editerrormsg = true;
          this.editmsg = data['message'];        

          this.isLoaderDisplayFullPage = false;
          this.dialog.closeAll(); 
          this.editDeleteMsg = data['message'];   
          this.dialog.open(this.audienceDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }
      },
      error: (error) => {
        this.dialog.closeAll(); 
        this.editerrormsg = true;
        this.isLoaderDisplayFullPage = false;
        this.editmsg = error.error['message'].replaceAll('_', ' ');          
      }
    });
  }

  dateFormat(dt:any, time: any = ''){
    if(time){
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy - hh:mm',"en-US");
    }
    else{
      return dt == undefined || dt == null || dt == ''? '' : formatDate(dt,'dd MMM yyyy',"en-US");
    }
  }

  deleteAudience(projName:any, audName:any){
    var postparams = {
        "operation" : 'delete',
        "userId" : this.sessionService.getItem('sessionUserName'),
        "project" : projName,
        "oldName" : audName
    }
    this.isLoaderDisplayFullPage = true;
    this.dialog.closeAll(); 
    this.DBAPIObj.updateAudience(postparams).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.delerror = false;  
          this.delmsg = data['message'];   
    
          this.currentPage = 1;
          this.listAudiences();
          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = 'Audience deleted successfully';
          this.dialog.open(this.audienceDeleteSuccessScreen, {
            height: '170px',
            width: '500px',
          });
        }else {
          this.dialog.closeAll(); 
          this.delerror = true;
          this.delmsg = data['message'].replaceAll('_', ' ');    
          this.isLoaderDisplayFullPage = false;
          this.editDeleteMsg = data['message'].replaceAll('_', ' ');    
        }
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        this.dialog.closeAll(); 
        this.delerror = true;
        this.delmsg = error.error['message'].replaceAll('_', ' ');          
      }
    });
  }


  createAudience() {
    this.router.navigate(['project']);
  }

  showCampaign(projVal:any,txtVal:any) {
    this.dialog.open(this.saveCampaignDialog, {
      panelClass: 'md-popup-custom-min-height',
      width: '560px',
    })

    this.projSel_popup = projVal;
    this.audSel_popup = txtVal;

    // this.sessionService.setItem('sessionAudienceName', txtVal);
    // this.sessionService.setItem('sessionProjectName', projVal);
    // this.router.navigate(['campaign']);
  }

  saveCampaign(){

    var postparams = {
      "campaignName" : $('#saveCampaignComment').val(),
      "userId" : this.sessionService.getItem('sessionUserName')
   }

   let txtVal = $('#saveCampaignComment').val();

   let pattern = new RegExp('^[a-zA-Z0-9-_]*$'); 
   if (txtVal == '' || txtVal == undefined){
         $('#campmsg').html('Please enter campaign name');
         $('#campmsg').addClass('error');
   }
   else if(!txtVal.match(pattern)){   
     $('#campmsg').html('Please select valid campaign name without special character');
     $('#campmsg').addClass('error');

   }
   else{
  
   this.creatCampBtnDisable = true;
   
    this.DBAPIObj.validateCampaign(postparams).subscribe({
      next: (data: any) => {
        this.sessionService.setItem('sessionAudienceName', this.audSel_popup);
        this.sessionService.setItem('sessionProjectName', this.projSel_popup);
        this.sessionService.setItem('sessionCampaignName', $('#saveCampaignComment').val());
        this.dialog.closeAll();
        this.router.navigate(['campaign']);
      },
      error: (error) => {
        // this.camperror = true;        
        // this.campmsg = error.error['message']; 
        this.creatCampBtnDisable = false;
        $('#campmsg').html(error.error['message'].replaceAll('_', ' '));
        $('#campmsg').addClass('error'); 
      }
    }); 
    }
  }

  loadData(pageNumber: number) {
    this.filterFormData.page = pageNumber;
    this.currentPage = pageNumber;
    this.pageNumber = pageNumber;

    if (pageNumber >= 1 && pageNumber <= this.pagination.length) {
      this.getSubscriptions();
    }
  }

  getTotalData() {
    return this.pagination_data.total_data;
  }

  getSubscriptions() {
    //initially till index 10
   // this.audListData = newdata;
  }

  getCurrentRange() {
    return (this.pagination_data.num_per_page * (this.currentPage - 1)) + 1;
  }

  getCurrentStartIndex() {
    return this.getCurrentRange() - 1;
  }

  getCurrentLastIndex() {
    return (this.currentPage *  this.recordsPerPage) - 1;
  }

  calculatePagination() {
    this.pagination = Array.from({ length: Math.ceil(this.pagination_data.total_data / this.pagination_data.num_per_page) }, (_, index) => index + 1);
  }
}
