import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DBAPIService } from '../dbapi.service';
declare var $ : any;
import 'select2';
import { SessionService } from '../session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent {
  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient,private sessionService: SessionService, private router: Router ){ }

  ngOnInit(){
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }

    //If Admin normal user trying to load Admin page then dont allow.
    if(this.sessionService.getItem('sessionUserName') != environment.admin_user_email){
      this.router.navigate(['']); 
    }

    $("#user").select2({
      placeholder:'Select User'
    });
    $("#campaign").select2({
      placeholder:'Select Campaign'
    });

    $('#user').on('change',  (e: any) => {
      this.selectedUserId = $('#user').val();
      this.updateSecondDropdown();
    });

   
    this.getListCampaignForAdmin();
  }
  msg ='';
  userlist:any=[];
  error:any='';
  listmsg:any='';
  userCampaignArra:any=[{}];

  userData:any=[];
  selectedUserId: string = '';
  selectedUserCampaigns: string[] = [];

  isLoaderDisplayFullPage = false;

  getListCampaignForAdmin(){
    this.DBAPIObj.getChannelListDataForAdmin().subscribe({
      next: (data: any) => {
        this.userData = data;
        
        const result = data.reduce((acc: { [x: string]: any[]; }, { userId, campaignName }: any) => {
          if (!acc[userId]) {
              acc[userId] = [];
          }
          acc[userId].push(campaignName);
          return acc;
        }, {});
      
        const arrayOfObjects = Object.keys(result).map(userId => ({ userId, campaignName: result[userId] }));
      
        this.userlist = arrayOfObjects;
      },
      error: (error) => {
        this.error = true;
        this.listmsg = error.error.replaceAll('_', ' ');          
      }
    });
  }

  updateSecondDropdown() {
  const userObject = this.userlist.find((user: { userId: any; }) => user.userId === this.selectedUserId);
  this.selectedUserCampaigns = userObject ? userObject.campaignName : [];
  }

  submit() {
    let tempId = $('#templateId').val();
    let message = $('#message').val();
    let contentVar = $('#contentVariable').val();
    let userId = $('#user').val();
    let campaignId = $('#campaign').val();

    let obj ={
      "twilioTemplateId" : tempId,
	    "messageServiceId" : message,
	    "contentVariables" : contentVar,
	    "userId" : userId,
	    "campaignId" : campaignId
    }
    
    this.isLoaderDisplayFullPage = true;

    this.DBAPIObj.saveChannelData(obj).subscribe({
      next: (data: any) => {
        this.isLoaderDisplayFullPage = false;
        this.msg = data.message.toLowerCase() == 'success' ? 'Channel Data Saved Succefully!!' : data.message;
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        this.msg = error.replaceAll('_', ' ');
      }
    });
  }
}
