<div class="container-fluid content-area">       
        <div class="row" style="height: 100%;">
            <div class="left-panel col-lg-2">
                <app-header></app-header>
                <app-sidebar></app-sidebar>
            </div>
            <div class="right-panel col-lg-8 login-panel">
                <div class="d-flex align-items-center">
                    <div class="login-container">                    
                        <h3 id="signInModalLabel" class="signup-heading-label mb-4 text-center">Sign Up</h3>
                        <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div>
                        <form  (ngSubmit)="createUser()" #signupForm="ngForm" ngNativeValidate>
                            <label class="signup-label" for="Organization">Organization</label>
                            <input type="text" placeholder="Organization" class="form-control" id="organization" name="organization" [(ngModel)]="user.organization" required>
                                <!-- <div *ngIf="loginForm?.controls?.['email']?.touched && loginForm?.controls?.['email']?.errors?.['required']">
                                    Email is required.
                                </div> -->
                            <label class="signup-label" for="Email">Email Address</label>
                            <input type="email" placeholder="Email" class="form-control" id="userEmail" name="userEmail" [(ngModel)]="user.userEmail" required>
                            <!-- <div *ngIf="loginForm?.controls?.['email']?.touched && loginForm?.controls?.['email']?.errors?.['required']">
                                Email is required.
                            </div> -->
                            <label for="password" class="signup-label">Password</label>
                            <input type="password" placeholder="Password" id="password" class="form-control" name="password" [(ngModel)]="user.password" required minlength="6">
                            <!-- <a data-dismiss="modal" data-toggle="modal" data-target="#resetPwdModal" class="changePwd" href="">Change Password</a> -->
        
                            <!-- <button type="submit" class="form-control btn btn-primary rounded submit px-3" [disabled]="!loginForm.form.valid">Sign In</button> -->
                            <button type="submit" class="form-control rounded px-3 action-btn mt-5">Create account</button>
                    
                        <div>                        
                        </div>
                        <div class="text-center">
                            <span class="loginText">Already have an account?</span><a href='login'  class="link"> Log in</a>
                        </div>
                        </form>
                    </div>
                </div> 
            </div>
           
        </div>
</div>

 

 
        