import { Component, OnInit } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { SessionService } from '../session.service';
import { DBAPIService } from 'src/app/dbapi.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  user = {    
    userEmail: '',
    password: '',
    newPassword: ''
  };
  error: boolean = false;
  msg: any = '';

  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, 
    private sessionService: SessionService,  private router: Router){ }
    
  ngOnInit(){
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }
  }

  changePassword() {
    this.user.userEmail = this.sessionService.getItem('sessionUserName');
    this.DBAPIObj.changePassword(this.user).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;
          this.msg =  data['message'];   
          this.user.password = "";
          this.user.newPassword = "";        
        }else {
          this.error = true;
          this.msg = data['message'].replaceAll('_', ' ');        
        }
      },
      error: (error) => {
        this.error = true;
        this.msg = (error.error['message'] == 'INVALID_LOGIN_CREDENTIALS') ? 'INVALID LOGIN CREDENTIALS' : error.error['message'].replaceAll('_', ' ');                       
      }
    });
  }
}
