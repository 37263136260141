<div class="line-separator"></div>
<div class="flex-column" class="sidebarDiv">
    <!-- <div class="d-flex left-links" [ngClass]="currSelMenu == 'home' ? 'active-opt' : '' "  *ngIf="isLogin" (click)="selectMenu($event)">
        <img src="../assets/images/home.svg"/>
        <a href="#" class="link-lbl">Home</a>       
    </div> -->

    <div class="d-flex left-links" [ngClass]="currSelMenu == 'project' ? 'active-opt' : '' "  (click)="selectMenu('project')" *ngIf="isLogin && !isAdminUser">
        <a routerLink="/project" class="link-lbl">
            <svg class="img-stroke" width="20" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.83333 4.66671H13.1667M4.83333 8.00004H8.16667M9 14.6667L5.66667 11.3334H3.16667C2.24619 11.3334 1.5 10.5872 1.5 9.66671V3.00004C1.5 2.07957 2.24619 1.33337 3.16667 1.33337H14.8333C15.7538 1.33337 16.5 2.07957 16.5 3.00004V9.66671C16.5 10.5872 15.7538 11.3334 14.8333 11.3334H12.3333L9 14.6667Z" stroke="#404040" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>            
            <span class="icon-text">Project</span>
        </a>      
    </div>
    
    <div class="d-flex left-links" *ngIf="isLogin && !isAdminUser" [ngClass]="(currSelMenu == 'audiencelist' || currSelMenu == 'audience') ? 'active-opt' : '' "  (click)="selectMenu('audiencelist')">
        <a  class="link-lbl">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="currentColor" d="M9.987 2.99a2.227 2.227 0 1 1 0 4.454a2.227 2.227 0 0 1 0-4.455Zm0 1a1.227 1.227 0 1 0 0 2.454a1.227 1.227 0 0 0 0-2.455ZM3.016 11.5a.5.5 0 1 1-1 0V9.014a1 1 0 0 1 .883-.993l.117-.007h2.757a1.987 1.987 0 0 0-.26.82l-.008.18h-2.49V11.5Zm14.474.5a.5.5 0 0 0 .5-.5V9.014l-.007-.116a1 1 0 0 0-.993-.884h-2.784c.146.253.24.543.262.851l.005.15h2.517V11.5a.5.5 0 0 0 .5.5Zm-5.016-3.986a1 1 0 0 1 .993.884l.006.116V11.5a.5.5 0 1 1-1 0V9.014H7.506V11.5a.5.5 0 1 1-1 0V9.014a1 1 0 0 1 .883-.993l.117-.007h4.969Zm4.414-2.483a1.913 1.913 0 1 0-3.827 0a1.913 1.913 0 0 0 3.827 0Zm-2.827 0a.913.913 0 1 1 1.827 0a.913.913 0 0 1-1.827 0ZM5.016 3.617a1.913 1.913 0 1 1 0 3.827a1.913 1.913 0 0 1 0-3.827Zm0 1a.913.913 0 1 0 0 1.827a.913.913 0 0 0 0-1.827ZM2.5 13a.5.5 0 0 0-.5.5v1A2.5 2.5 0 0 0 4.5 17h11a2.5 2.5 0 0 0 2.5-2.5v-1a.5.5 0 0 0-.5-.5h-15Zm.5 1.5V14h14v.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 14.5Z"/></svg>
            <span class="icon-text">Audience</span>
        </a>
    </div>
    <div class="d-flex left-links" *ngIf="isLogin && !isAdminUser" [ngClass]="(currSelMenu == 'campaignlist' || currSelMenu == 'campaign') ? 'active-opt' : '' "  (click)="selectMenu('campaignlist')">
        <a  class="link-lbl">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M18 13v-2h4v2h-4Zm1.2 7L16 17.6l1.2-1.6l3.2 2.4l-1.2 1.6Zm-2-12L16 6.4L19.2 4l1.2 1.6L17.2 8ZM5 19v-4H4q-.825 0-1.412-.587T2 13v-2q0-.825.588-1.412T4 9h4l5-3v12l-5-3H7v4H5Zm6-4.55v-4.9L8.55 11H4v2h4.55L11 14.45Zm3 .9v-6.7q.675.6 1.088 1.463T15.5 12q0 1.025-.413 1.888T14 15.35ZM7.5 12Z"/></svg>
            <span class="icon-text">Campaign</span>
        </a>
    </div>
    <div class="d-flex left-links" *ngIf="isLogin && !isAdminUser" [ngClass]="currSelMenu == 'changePassword' ? 'active-opt' : '' " (click)="selectMenu('changePassword')">
        <a  class="link-lbl">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12.63 2c5.53 0 10.01 4.5 10.01 10s-4.48 10-10.01 10c-3.51 0-6.58-1.82-8.37-4.57l1.58-1.25C7.25 18.47 9.76 20 12.64 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8C8.56 4 5.2 7.06 4.71 11h2.76l-3.74 3.73L0 11h2.69c.5-5.05 4.76-9 9.94-9m2.96 8.24c.5.01.91.41.91.92v4.61c0 .5-.41.92-.92.92h-5.53c-.51 0-.92-.42-.92-.92v-4.61c0-.51.41-.91.91-.92V9.23c0-1.53 1.25-2.77 2.77-2.77c1.53 0 2.78 1.24 2.78 2.77zm-2.78-2.38c-.75 0-1.37.61-1.37 1.37v1.01h2.75V9.23c0-.76-.62-1.37-1.38-1.37"/></svg>
            <span class="icon-text">Change Password</span></a>
    </div>
    <div class="d-flex left-links" *ngIf="isLogin" (click)="logout($event)">
        <a href="#" class="link-lbl">
            <svg xmlns="http://www.w3.org/2000/svg" class="img-stroke" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2"/><path d="M9 12h12l-3-3m0 6l3-3"/></g></svg>
            <span class="icon-text">Logout</span>
            </a>
    </div>
    <!-- <ul class="sidebar">
        <li>
           
        </li>
        <li>
            <a href="#"><img src="../assets/images/campaign.png">Campaign</a>
        </li>
        <li>
            <a href="#"><img src="../assets/images/campaign.png">Audience</a>
        </li>
    </ul> -->
</div>
