import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
//import { HighchartsChartModule } from 'highcharts-angular';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { DatePipe } from '@angular/common';

import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CampaignComponent } from './campaign/campaign.component';
import { AudienceComponent } from './audience/audience.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProjectComponent } from './project/project.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AudienceListComponent } from './audience-list/audience-list.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { EmailActivationComponent } from './email-activation/email-activation.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    CampaignComponent,
    AudienceComponent,
    RegistrationComponent,
    LoginComponent,
    SignupComponent,
    ProjectComponent,
    ChangePasswordComponent,
    AudienceListComponent,
    CampaignListComponent,
    HealthCheckComponent,
    ForgotPasswordComponent,
    AdminPageComponent,
    EmailActivationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule, 
    MatMenuModule, 
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      //echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
      echarts
    }),
    //CanvasJSAngularChartsModule,
    //HighchartsChartModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
