<div class="container-fluid content-area">
    <div class="row" style="height: 100%;">
        <div class="left-panel col-lg-2">
            <app-header></app-header>
        </div>
        <div class="right-panel col-lg-8 login-panel mt-50">  
            <h3 class="email-label text-left">
                <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div> <span class="link" (click)="login()" *ngIf="error == ''">login</span> to continue.
              </h3>
        </div>           
</div>
</div>