import { Component, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { DBAPIService } from 'src/app/dbapi.service';
//import * as Highcharts from 'highcharts';
import 'select2';
declare var $ : any;
import { USER_ID, PROJECT_ID, ORG_ID } from '../constants';
import { SessionService } from '../session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EChartsOption } from 'echarts';
import { filter } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})


export class CampaignComponent {

  constructor(public activatedRoute: ActivatedRoute, private dialog: MatDialog, private DBAPIObj: DBAPIService, private sessionService: SessionService, private router: Router){ }

  @ViewChild('crowdedCustomerInsightUnreg') crowdedCustomerInsightUnreg!: ElementRef;
  @ViewChild('CampaignSaveDialog') saveCampaignDialog!: TemplateRef<any>;

  imagePath:any=environment.imagePath;
  dynamicId: string = 'audienceSet';
  // dynamicORId: string = 'audienceORSet';
  // dynamicANDId: string = 'audienceANDSet';

  // dynamicORIdProj: string = 'projORSet';
  // dynamicANDIdProj: string = 'projANDSet';

  //chart: Highcharts.Chart | undefined;
  //Highcharts = Highcharts; // Define the Highcharts property
  isLoaderDisplayFullPage: boolean = false;
  dynamicORANDId: string = 'campienceOpt';
  selectedCompaignStr: string='';
  singleSelected:boolean=true;
  audSize = 0;
  showNextIteration: any = 1;
  selected_proj: any=this.sessionService.getItem('sessionProjectName');
  selected_aud: any=this.sessionService.getItem('sessionAudienceName');
  selCampaign: any = this.sessionService.getItem('sessionCampaignName');
  selChannelVal:any='';
  PROJ_CURR_SEL:any=this.sessionService.getItem('sessionProjectName');
  selectedAudObj:any={};
  isChannelOptUpdated:any=false;
  campStatus:any='';

  audSelSize = new Array(100);
  projectAudiences:any = {};
  campaignList:any=[];
  campaignInfoList:any=[];
  campaignObj: any = {
    id: ''
  }
  campaignReqParamsObj:any= {
    "Organization": ORG_ID,
    "UserID": ''
  }
  reqParamsObj:any= {
    "Organization": ORG_ID,
    "Project": '',
    "UserID": ''
  }

  selecteddropValue!: string;
  options: any[] = [];
  error: boolean = false;
  projectLists: any = [];
  audList:any = [];
  audFilterList:any = [];
  listmsg: any = '';
  channelArr:any='';
  campFilter:any='';

  channellist:any =[];

  chartOptions: EChartsOption = {};
  pieChartOptions: EChartsOption = {};
  stackBarOptions: EChartsOption = {};
  horizontalBarOptions: EChartsOption = {};
  showlayeredDiv: boolean = false;
  audienceSetType:any;

  //receivedCampaignName:any='camp1';
  //current not using this 
  //@ViewChild('campaignIframe') campaignIframe!: ElementRef;

  ngAfterViewInit() {
   
    $("#location").select2();

    //Currently not using this function
    /*this.DBAPIObj.getAudienceLookerIframeUrl().subscribe((data: any) => {   
      let str = data;   
      console.log(str['URL']);
      this.campaignIframe.nativeElement.src = str['URL']; //+ "&external_user_id="+this.sessionService.getItem('sessionUserName');
    })*/

    $('#projectDropdown').on('change',  (e: any) => {
      const selectedValue = $('#projectDropdown').val();      
    });  
   
  }

  ngOnInit() {
    if(!this.sessionService.getItem('sessionUserName')){
      this.router.navigate(['']);   
    }
    this.audienceSetType = this.sessionService.getItem('audienceSetType');
    
    if(this.audienceSetType == 'direct'){
      $('.select').prop('disabled', true);

     this.channelArr = this.sessionService.getItem('campainChannel');
     this.campStatus = this.sessionService.getItem('campaignChannelStatus');
     this.campFilter = this.sessionService.getItem('campaignChannelFilter');

     this.prefilledSelProjectAud();
    }

    //For Disabled Option from dropdown
    this.updateObject(this.selectedAudObj, this.selected_proj, this.selected_aud);

    if(this.selected_aud != '' && this.selected_proj){
      this.setaudienceUnionCountDetails();
    }

    //this.getProjectsList();
    this.getProjAudList();
    this.reqParamsObj.UserID = this.sessionService.getItem('sessionUserName');    
    this.getCampaignData();  
    
    if(this.selected_aud)
     this.selectedCompaignStr = this.selected_proj + '.' + this.selected_aud;

    this.setaudienceUnionCountDetails();

    this.getChannelList();

    if(this.selCampaign == "" || this.selCampaign == undefined){
      this.showlayeredDiv = true;
    }
    this.setChart();
  }

  addChannel(event:any){
    this.isChannelOptUpdated = true;
   // $('.sel-btn').removeClass('active-btn');
    $(event.target).toggleClass('active-btn');
    let butntext = $(event.target).hasClass('active-btn') ? 'Selected' : 'Select';
    $(event.target).text(butntext);

    var activeButtonValues:any = [];
    var activeButtons = document.querySelectorAll('.active-btn');

    activeButtons.forEach(function(button:any) {
      activeButtonValues.push(button.value);
    });

    this.selChannelVal = activeButtonValues.toString();
  }

  getChannelList(){
    this.DBAPIObj.getChannelListData().subscribe({
      next: (data: any) => {
        this.channellist = data;
      },
      error: (error) => {
        this.error = true;
        this.listmsg = error.error.message;          
      }
    });
  }

  showCampaignList(){
    this.router.navigate(['campaignlist']);
  }

  getCampaignData(){
//    this.reqParamsObj.Project = selectedVal;
    this.campaignList = [];
    this.campaignInfoList = [];
    this.options = [];
    this.campaignReqParamsObj.UserID = this.sessionService.getItem('sessionUserName');    
    this.DBAPIObj.getCampaignData(this.campaignReqParamsObj).subscribe({
      next: (data) => {
       //this.campaignList = data;

       for (let i = 0; i < data.length; i++) {
        this.campaignList.push(data[i]["audienceSetName"]);
        this.campaignInfoList.push(data[i]["filter"]);

        let newObj = {
          id: 0,
          text: '',
          isDisable: null,
          info:''
        }

         //Info selected
         if(this.selected_aud == data[i]["audienceSetName"]){
          let sel_Aud_Info = data[i]["filter"];
          $('#aud0').parent().find('.filter-info').html('<img src="' + this.imagePath + 'info.svg" class="info-icon" title="' + sel_Aud_Info + '">')
        }

        newObj['id'] = i;
        newObj['text'] = data[i]["audienceSetName"];
        newObj['info'] = data[i]["filter"];

        this.options.push(newObj);
       }
      
       setTimeout(() => {
        this.initializeSelect2();
      }, 1000); // Simulate 
      },
      error: (error) => {
        $('#displaySaveMsg').append(error.error.replaceAll('_', ' '));
        $('#displaySaveMsg').addClass('error');
      },
    });    

  }
  // onSelectChange(selectedValue: any) {
  //   alert(selectedValue)
  //   // Implement your logic to regenerate options based on the selectedValue
  //   // You can modify this.options based on your requirements
  //   if (selectedValue === '1') {
  //     this.options = [
  //       { id: '3', text: 'Option 3' },
  //       { id: '4', text: 'Option 4' },
  //       // Add more options as needed
  //     ];
  //   }
  // }

  addSelAudSpecificProject(obj:any, key:any, value:any) {
    // Check if the key is already present in the object
    if (obj.hasOwnProperty(key)) {
      // If the key is present, add the value to the existing key
      obj[key] = [...obj[key], value];
    } else {
      // If the key is not present, create a new key with an array containing the value
      obj[key] = [value];
    }

    this.selectedAudObj = obj;
  }
  
  updateObject(obj:any, key:any, values:any) {
    obj[key] = [values];
    this.selectedAudObj = obj;
    //return obj;
  }

  hasKeyValue(obj:any, key:any, value:any) {
    return obj.hasOwnProperty(key) && obj[key].includes(value);
  }

  
  initializeSelect2() {
    for (let i = 0; i < this.audSelSize.length; i++) {
      const selectId =  + i;
      const select2Element = $('#aud' + selectId);
      select2Element.select2();

      //proj
      const select2ElementProj = $('#proj' + selectId);
      select2ElementProj.select2();
      
      $(select2ElementProj).on('change',  (e: any) => {
        //alert();
        let selectVal = e.target.value;
        
        this.PROJ_CURR_SEL = selectVal;
        let cuuIndex = e.target.id.substr(4,4);
      
        // Using jQuery  
        //alert(e.target.id);

        if(cuuIndex == i) {
          $('#aud' + cuuIndex).empty().select2('destroy').select2();

          // Get the select element and add a new option
          let audLst = this.projectAudiences[this.PROJ_CURR_SEL];
         
          var placeholderOption = document.createElement('option');
          placeholderOption.value = ''; // Set an empty value or any default value
          placeholderOption.text = 'Select Option';

          // Append the placeholder option to the select element
          $('#aud' + cuuIndex).append(placeholderOption);

          let SEL_AUD = this.selected_aud;
          let that = this;

          if(audLst == undefined){
            this.createFilterStrForSelectedVal();
          }else{
            audLst.forEach(function (item: { audienceSetName: string; }) {
              var option = document.createElement('option');
              option.value = item.audienceSetName; // You can use a different property if needed
              option.text = item.audienceSetName;
  
              // if(that.hasKeyValue(that.selectedAudObj, that.PROJ_CURR_SEL, item.audienceSetName)){
              //   option.disabled = true;
              // }
            
              $('#aud' + cuuIndex).append(option);
  
            });
          }
       

          
          // Trigger Select2 to update its UI
          $('#aud' + cuuIndex).trigger('change.select2');

        }
        
        // if(cuuIndex == i)
        // this.getAudListSpecificProj();
        //this.setAud(selectVal);
      });


      $(select2Element).on('change',  (e: any) => {
        let selectVal = e.target.value;
        //console.log("inside11")

        this.singleSelected = false;
        this.createFilterStrForSelectedVal();
       
        this.addSelAudSpecificProject(this.selectedAudObj, this.PROJ_CURR_SEL, selectVal);

        //Append Tooltip
        let currTooltip = this.campaignInfoList[this.campaignList.indexOf(selectVal)]; //this.campaignInfoList[e.target.id];
        $('#' + e.target.id).parent().find('.filter-info').html('');
        $('#' + e.target.id).parent().find('.filter-info').append('<img src="' + this.imagePath + 'info.svg" class="info-icon" title="' + currTooltip + '">')
        //this.showNextAudienceDropdown(e.target.id);

        // if(selectVal == '') {
        //   this.removeCurrentOptionSelection(e);
        // }
        // this.createOptioRecord(e, this.PROJ_CURR_SEL);
      });
      
    }

    if(this.audienceSetType == 'direct'){
      $('.select').prop('disabled', true);

      $('.andor-sec').addClass('disabled');
    }
  }

  // setAud(currProj:any){
  //   alert(currProj);
  // }

  getAudListSpecificProj(){
   // console.log('gt:', this.projectAudiences);
    return this.projectAudiences[this.PROJ_CURR_SEL];
  }

  showNextAudienceDropdown(i: any){     
    this.showNextIteration = parseInt(i)+1; 
    for(var k=0; k<this.showNextIteration;k++){
      $('#btn-'+k).attr('style','display:none');
    }
    if(i >= 0 && i < this.audSelSize.length){      
      
      setTimeout(() => {
        const select2Element = $('#aud' + this.showNextIteration);
        select2Element.select2();

        const select2ElementProj = $('#proj' + this.showNextIteration);
        select2ElementProj.select2();
        
        $(select2ElementProj).on('change',  (e: any) => {
          let selectVal = e.target.value;
          this.PROJ_CURR_SEL = selectVal;
          

          $('#aud' + k).empty().select2('destroy').select2();

          // Get the select element and add a new option
          let audLst = this.projectAudiences[this.PROJ_CURR_SEL];
        
          var placeholderOption = document.createElement('option');
          placeholderOption.value = ''; // Set an empty value or any default value
          placeholderOption.text = 'Select Option';

          // Append the placeholder option to the select element
          $('#aud' + k).append(placeholderOption);
          
          if(audLst == undefined){
            this.createFilterStrForSelectedVal();
          }else{
            audLst.forEach(function (item: { audienceSetName: string; }) {
              var option = document.createElement('option');
              option.value = item.audienceSetName; // You can use a different property if needed
              option.text = item.audienceSetName;
              $('#aud' + k).append(option);
  
            });
          }
         

          
          // Trigger Select2 to update its UI
          $('#aud' + k).trigger('change.select2');
          
        });

        let that = this;

        $(select2Element).on('change',  (e: any) => {
          //let currEvent = e;
          let selectVal = e.target.value;
          this.createFilterStrForSelectedVal();

         // this.options[this.campaignList.indexOf(selectVal)].isDisable = true;
          let currTooltip = this.campaignInfoList[this.campaignList.indexOf(selectVal)];//this.campaignInfoList[e.target.id];
          
          that.addSelAudSpecificProject(that.selectedAudObj, that.PROJ_CURR_SEL, selectVal);
          //Append Tooltip
          $('#' + e.target.id).parent().find('.filter-info').html('');
          $('#' + e.target.id).parent().find('.filter-info').append('<img src="' + this.imagePath + 'info.svg" class="info-icon" title="' + currTooltip + '">')
        
          // if(selectVal == '') {
          //   this.removeCurrentOptionSelection(e);
          // }
          // this.createOptioRecord(e, that.PROJ_CURR_SEL);
        })
       }, 1000); // Simulate 
    }
  }

  createOptioRecord(event:any, selectedProject:any){
    const parentElement = (event.target as HTMLElement).parentElement;

    $(parentElement).find('input[type=hidden][name=currentSelectedProject]')[0].value = selectedProject;
    $(parentElement).find('input[type=hidden][name=currentSelectedAudience]')[0].value = event.target.value;

    console.log(parentElement);
  }

  removeCurrentOptionSelection(event:any){
    const parentElement = (event.target as HTMLElement).parentElement;

    let currentSelProj = $(parentElement).find('input[type=hidden][name=currentSelectedProject]')[0].value;
    let currentSelAud = $(parentElement).find('input[type=hidden][name=currentSelectedAudience]')[0].value;

    // Iterate through the object properties
    for (var key in this.selectedAudObj) {
      if (this.selectedAudObj.hasOwnProperty(key)) {
        // Check if the key is "New-Project-2" and the value contains "MAle-update"
        if (key === currentSelProj && this.selectedAudObj[key].includes(currentSelAud)) {
          // Remove the key-value pair
          delete  this.selectedAudObj[key];
        }
      }
    }

    console.log(this.selectedAudObj);
  }

  enableValueOnDeSelect(i: any){

    
    
  //   if(i >= 1 && i < this.campaignList.length){
      
  //     setTimeout(() => {
  //       const select2Element = $('#' + this.showNextIteration);
  //       select2Element.select2();
        
  //       $(select2Element).on('change',  (e: any) => {
  //         let selectVal = e.target.value;
  //      }, 1000); // Simulate 
  //   })
  // }
}


  createFilterStrForSelectedVal(){
    this.selectedCompaignStr = '';
    for (let i = 0; i < this.audSelSize.length; i++) {
     // let dropDownVal = $('#' + i).find(":selected").val();

      let projSelVal = $('#proj' + i).find(":selected").val();
      let audSelVal = $('#aud' + i).find(":selected").val();
      
      let dropDownVal = projSelVal + '.' + audSelVal;

      if(this.singleSelected){
        let projname = $('#proj0').val();
        let audname = $('#aud0').val();

        let dropDownVal1 = projname + '.' + audname;

        this.selectedCompaignStr = dropDownVal1;
      }else{
        let currIndex = i-1;
        let radioName = 'campienceOpt' + currIndex;
        let andOrselction = $("input[type='radio'][name='"+radioName+"']:checked").val();

        if(audSelVal != '' && audSelVal != undefined && audSelVal != 'undefined')
        this.selectedCompaignStr += andOrselction != undefined ? ' + ' + andOrselction + ' + ' + dropDownVal : dropDownVal;
      }      
    }

    if(this.selectedCompaignStr != ''){
      this.setaudienceUnionCountDetails();
    }else{
      this.audSize = 0;
      this.setChart();
    }
    
  }

  setaudienceUnionCountDetails(){
    let savejsonObj: any = {};

    if(this.audienceSetType == 'direct'){
      savejsonObj = {
        "audienceListQuery": "",
        "UserID": this.sessionService.getItem('sessionUserName'),
        "campaignName": this.sessionService.getItem('sessionCampaignName')
      };
    }else {
       savejsonObj = {
        "audienceListQuery": "",
        "UserID": this.sessionService.getItem('sessionUserName')
      };
  
    }
    
    savejsonObj['audienceListQuery'] = this.selectedCompaignStr;

    this.DBAPIObj.setaudienceUnionCountDetails(savejsonObj).subscribe({
      next: (data) => {
        this.audSize = data['totalCount'];
        this.setChart();
    
        if(data['statistics']){
          this.showCharts(data['statistics']);
        }
      },
      error: (error) => {
        //alert(error);
      },
    });
  }

  setChart(){
    var data = [
      { value: this.audSize}
    ];
    var colorPalette = ['#5B93FF'];
    var formattedNumber = this.audSize.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.pieChartOptions = {
     
      title: {
        text: formattedNumber, //this.audSize.toString(),
        left: 'center',
        top: 'center',
      },
      width: '100%',
      series: [
        {
          type: 'pie',
          radius: ['83%', '63%'],
          center: ['50%', '50%'],
          label: {
            show: false
          },
          data: data,
          color: colorPalette
        }
      ]
    };
  }

  showCharts(statistics: any){
    for(var i=0; i< statistics.length; i++){
      this.showSelectedChart(statistics[i]['filters'], statistics[i]['level']);
    }
  }
  showHouseHold: boolean = false;
  showLifeStage: boolean = false;
  showAgeGroup: boolean = false;
  showAgeGenderGroup: boolean = false;
  showSelectedChart(filters: any, type: any){
    var countArray: any = [];
    var labelArray: any = [];
    var pieData: any = [];
    var genderAgeLabelArray: any = [];
    var genderAgeLegendArray: any = [];
    var maleCountArray: any = [];
    var femaleCountArray: any = [];
    for(var i=0; i<filters.length; i++){
      countArray.push(filters[i]['count']);
      labelArray.push(filters[i]['value']);
      if(type == 'Age Group') {
        var ageGroupData = {
          name: filters[i]['value'],
          value: filters[i]['count']
        }
        pieData.push(ageGroupData);
      }
      if(type == 'Gender:Age Group') {        
        let t = filters[i]['value'].split(":");
        if(genderAgeLabelArray.indexOf(t[1])<=-1){
          genderAgeLabelArray.push(t[1]);
        }
        if(t[0] == 'FEMALE'){
          if(genderAgeLegendArray.indexOf('FEMALE')<=-1){
            genderAgeLegendArray.push('Female')
          }
          femaleCountArray.push(filters[i]['count']);
        }
        if(t[0] == 'MALE'){
          if(genderAgeLegendArray.indexOf('MALE')<=-1){
            genderAgeLegendArray.push('Male')
          }
          maleCountArray.push(filters[i]['count']);
        }
      }
    }

    if(type == 'House Hold'){      
      if(filters.length <= 0){
        this.showHouseHold = false;
      }
      else{
        this.showHouseHold = true;
        this.chartOptions = {
          title: {
            text: 'HouseHold Class',
            textAlign: "center",
            top: '2%',
            left: '50%',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: labelArray,// ['Ownership schedule', 'Public', 'Private', 'Village'],        
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 75,
              formatter: function(params: any){
                return (params.length > 15) ? params.substring(0, 15) + '...' : params
              }
            },
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: countArray,//[820, 932, 901, 934],
              type: 'bar',
              name: 'Count', 
              stack: 'Total',     
              barWidth: '30%',
              label: {
                show: true,
                position: 'top'
              },
              //itemStyle: { color: "#63869e" }
            },
          ],
        };
      }  

    }else if(type == 'Life Stage'){

      if(filters.length <= 0){
        this.showLifeStage = false;
      }
      else{
        this.showLifeStage  = true;
        this.horizontalBarOptions = {
          title: {
            text: 'Life Stage',
            textAlign: "center",
            top: '2%',
            left: '50%',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '5%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {        
          },
          yAxis: {
            type: 'category',
            data: labelArray, //['Single','Just Married', 'Family with Child'],
            
            axisLabel: {
              show: true,
              interval: 0,
              //rotate: 45          
            },
          },
          series: [
            {
              data: countArray,//[2, 3, 9],
              type: 'bar',
              barWidth: '50%',
              name: 'Count',
              label: {
                show: true,
                position: 'right'
              },
            },
          ],         
        };
      }  

    }else if(type == 'Age Group') {

      if(filters.length <= 0){
        this.showAgeGroup = false;
      }
      else{
        this.showAgeGroup = true;
        this.pieChartOptions = {
          title: {
            text: 'Age Group Split',
            textAlign: "center",
            top: '2%',
            left: '50%',
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            data: labelArray,//['USA', 'Brazil', 'India', 'France', 'UK'],
          },
          series: [
            {
              name: 'Age Group',
              type: 'pie',
              radius: '40%',
              center: ['50%', '50%'],
              label: {
                show: true,
                formatter(param: any) {
                  // correct the percentage
                  return param.value * 2 + '%';
                }
              },
              data: pieData, /*[
                {
                  name: 'USA',
                  value: 500
                },
                {
                  name: 'Brazil',
                  value: 300
                },
                {
                  name: 'India',
                  value: 200
                },
                {
                  name: 'France',
                  value: 100
                },
                {
                  name: 'UK',
                  value: 100
                }
              ]*/
            }
          ]
        };
      }
    }else if(type == 'Gender:Age Group'){
      
      if(filters.length <= 0){
        this.showAgeGenderGroup = false;
      }
      else{
        this.showAgeGenderGroup = true;
        this.stackBarOptions = {   
          title: {
            text: 'Gender X Age Group',
            textAlign: "center",
            top: '2%',
            left: '50%',
          },  
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '5%',
            bottom: '3%',
            containLabel: true
          },
          legend: {
            data: genderAgeLegendArray,
            orient: 'vertical',
            left: 'right'
          },
          xAxis: {
            data: genderAgeLabelArray, 
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 75,
              formatter: function(params: any){
                return (params.length > 8) ? params.substring(0, 8) + '...' : params
              }
            }
          },        
          yAxis: {},
          series: [
            {
              data: maleCountArray,
              type: 'bar',
              name: 'Male',
              stack: 'total',
              label: {
                show: true,
                position: 'inside'
              },
            },
            {
              data: femaleCountArray,
              type: 'bar',
              name: 'Female',
              stack: 'total'  ,
              label: {
                show: true,
                position: 'inside'
              },            
            }
          ]
        };
      }  
    }


  }

  
  saveCampaignDetails(){
    let campaignName = $('#campaignName').val();
   
    let jsonObj = {
      "audienceListQuery": "",
      "UserID": this.sessionService.getItem('sessionUserName'),
      "campaignName": "",
      "campaignType": "",
      "operation": this.audienceSetType == 'direct' ? 'update' : 'create'
    }
    
    jsonObj['audienceListQuery'] = this.selectedCompaignStr;
    jsonObj['campaignName'] = this.selCampaign;
    jsonObj['campaignType'] = this.isChannelOptUpdated ? this.selChannelVal : this.channelArr;
    this.isLoaderDisplayFullPage = true;
    this.DBAPIObj.saveCampaignDetails(jsonObj).subscribe({
      next: (data) => {

      this.isLoaderDisplayFullPage = false;
      this.sessionService.removeItem('sessionProjectName');
      this.sessionService.removeItem('sessionAudienceName');
      this.sessionService.removeItem('sessionCampaignName');
      this.sessionService.removeItem('audienceSetType');
      this.sessionService.removeItem('campainChannel');
      this.sessionService.removeItem('campaignChannelStatus');
      this.sessionService.removeItem('campaignChannelFilter');

        $('#displaySaveMsg').html('Campain Data Save Successfully!!');
        $('#displaySaveMsg').addClass('success');

        this.dialog.open(this.saveCampaignDialog, {
          height: '307px',
          width: '560px',
        });

        this.router.navigate(['campaignlist'])
      },
      error: (error) => {
        this.isLoaderDisplayFullPage = false;
        $('#displaySaveMsg').html(error.error.message.replaceAll('_', ' '));
        $('#displaySaveMsg').addClass('error');
      },
    });
  }

  setPrefilledInfo(currProj:any, currAud:any, i:any){
      var postParams = {
        "UserID": this.sessionService.getItem('sessionUserName')
      }
      this.DBAPIObj.getAudListData(postParams).subscribe({
        next: (data: any) => {
       
        const filteredData = data.find((item: { project: string; audienceSetName: string; }) =>
          item.project.toLowerCase() === currProj.toLowerCase() &&
          item.audienceSetName.toLowerCase() === currAud.toLowerCase()
        );
  
        $('#aud'+i).parent().find('.filter-info').html('<img src="' + this.imagePath + 'info.svg" class="info-icon" title="' + filteredData.filter + '">')
        // console.log('filteredData:', filteredData.filter);
        // return filteredData ? filteredData.filter : null;
      }
     });
  }

  prefilledSelProjectAud(){ 
    let filterStr = this.campFilter;
    let projlst: (string | undefined)[] = [];
    let audlst: (string | undefined)[] = [];
    let andorlst: (string | undefined)[] = [];
    
    let iterationCnt = 0;

    let parts = filterStr.split('+').map((part: string) => part.trim());
    
    for(let i=0;i<parts.length;i++){
      let str = parts[i];

      console.log(str);

      if(str != '' && str.toLowerCase() != 'or' && str.toLowerCase() != 'and' && str.includes('.')){
        iterationCnt++;
        let projaudval = str.split('.').map((part: string) => part.trim());
        projlst.push(projaudval[0]);
        audlst.push(projaudval[1]);
      } 
      
      if(str != '' && (str.toLowerCase() == 'or' || str.toLowerCase() == 'and')){
        andorlst.push(str);
      }
    }

    setTimeout(() => {
      for(let j=0; j<projlst.length;j++){
        var newOption = new Option(projlst[j], '', true, true);
        $('#proj'+j).append(newOption).trigger('change');
      }

      for(let k=0; k<audlst.length;k++){
        var newOption = new Option(audlst[k], '', true, true);
        $('#aud'+k).append(newOption).trigger('change');

         //SET INFO
         this.setPrefilledInfo(projlst[k],audlst[k], k);
      }

     
      for(let l=0; l<andorlst.length;l++){
        let radioName = 'campienceOpt' + l;
        let radioVal = andorlst[l]?.toUpperCase();
  
        $('input[name="'+radioName+'"][value="'+radioVal+'"]').prop('checked', true);
      }
    }, 1000);

    this.showNextIteration = iterationCnt-1;
   
  }
  
  // getProjectsList(){
  //   var postParams = {
  //     "user": this.sessionService.getItem('sessionUserName')
  //   }
  //   this.DBAPIObj.listProject(postParams).subscribe({
  //     next: (data: any) => {
  //       if (data && data['projects']){
  //         this.error = false;  
  //         this.DBAPIObj.setprojectList(data['projects']);
  //         this.projectLists = data['projects'];  
  //         $("#projectDropdown").select2();        
           
  //       }else {
  //         this.error = true;
  //         this.listmsg = data['message'];        
  //       }
  //     },
  //     error: (error) => {
  //       this.error = true;
  //       this.listmsg = 'Error occured while fetching project';          
  //     }
  //   });
  // }

  getProjAudList(){
    var postParams = {
      "UserID": this.sessionService.getItem('sessionUserName')
    }
    this.DBAPIObj.getAudListData(postParams).subscribe({
      next: (data: any) => {
  
        data.forEach((item: { project: any; audienceSetName: any; filter: any; createdDate: any; updatedDate: any; }) => {
          const projectName = item.project;
        
          if (!this.projectAudiences[projectName]) {
            this.projectAudiences[projectName] = [];
          }
        
          this.projectAudiences[projectName].push({
            audienceSetName: item.audienceSetName,
            filter: item.filter,
            createdDate: item.createdDate,
            updatedDate: item.updatedDate
          });
        });
        
        this.audList = this.PROJ_CURR_SEL != null ? [...new Set(this.projectAudiences[this.PROJ_CURR_SEL].map((item: { audienceSetName: any; }) => item.audienceSetName))] : [];
        this.audFilterList = this.PROJ_CURR_SEL != null ? [...new Set(this.projectAudiences[this.PROJ_CURR_SEL].map((item: { filter: any; }) => item.filter))] : [];
        console.log('audFilterList:', this.audFilterList);

        this.projectLists = Object.keys(this.projectAudiences)
       
      }
    });
  }

  monthlyIncome:any =  ['HKD$5,000 - 10,000', 'HKD$10,000 - 20,000', 'HKD$20,000 - 30,000', 'HKD$30,000 - 40,000'];
  lifeStage:any =  ['Childhood', 'Young Adulthood', 'Married', 'Parenthood', 'Retirement'];

  segmentTechnologyStack:any = [
    {
      'type':'Software Engineer',
      'data':[
          {
          'status': 'Active',
          'noOfPeople': 54,
          'rate': '93%'
        },
        {
          'status': 'Somewhat Active',
          'noOfPeople': 34,
          'rate': '60%'
        },
        {
          'status': 'Inactive',
          'noOfPeople': 10,
          'rate': '20%'
        }
      ]
    },
    {
      'type':'Data Scientist',
      'data':[
          {
          'status': 'Active',
          'noOfPeople': 54,
          'rate': '93%'
        },
        {
          'status': 'Somewhat Active',
          'noOfPeople': 34,
          'rate': '60%'
        },
        {
          'status': 'Inactive',
          'noOfPeople': 10,
          'rate': '20%'
        }
      ]
    },
    {
      'type':'Cybersecurity Alert',
      'data':[
          {
          'status': 'Active',
          'noOfPeople': 54,
          'rate': '93%'
        },
        {
          'status': 'Somewhat Active',
          'noOfPeople': 34,
          'rate': '60%'
        },
        {
          'status': 'Inactive',
          'noOfPeople': 10,
          'rate': '20%'
        }
      ]
    },
    {
      'type':'UI/UX Designer',
      'data':[
          {
          'status': 'Active',
          'noOfPeople': 54,
          'rate': '93%'
        },
        {
          'status': 'Somewhat Active',
          'noOfPeople': 34,
          'rate': '60%'
        },
        {
          'status': 'Inactive',
          'noOfPeople': 10,
          'rate': '20%'
        }
      ]
    }
  ]
  //['Software Engineer', 'Data Scientist', 'Cybersecurity Analyst', 'UX/UI Designer', 'Network Administrator']
}
