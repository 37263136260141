<div id="loading-image" *ngIf="isLoaderDisplayFullPage">    
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>

<div class="container-fluid content-area">
    <div class="row">

        <div class="left-panel col-lg-2">
            <app-header></app-header>
            <app-sidebar></app-sidebar>
        </div>

        <div class="right-panel col-lg-10 row">
            <div class="col-md-9 middle-section" id="accordion">
                <div class="row">
                    <div class="col-12 float-right">
                        <span class="audience-label">Selected Project: </span>
                        <span class="audience-sub-label">{{selectedProj}}</span>
                         
                        <!-- <select id="projectDropdown" class="dropdown">
                            <option value="">Select Project</option>
                            <option *ngFor="let proj of projectLists" value="{{proj}}">{{ proj }}</option>
                        </select> -->
                    </div>
                </div>    
                <br /> 
                <!-- Demographic section -->
                <div class="filter-section">
                    <div class="collapse-header audience-filter-label" data-toggle="collapse" data-target="#collapseExample"
                        aria-expanded="false" aria-controls="collapseExample"> Demographics</div>
                    <div class="subtitle-div">Select your ideal audience based on demographics.</div>
                    <div class="demographics-filter-1 collapse show" id="collapseExample">
                       
                        <div class="demographics-filter-row-margin-bottom">
                            <div class="row">
                                <div class="col-md-6 mb-10">
                                    <div class="filter-heading">Age</div>
                                    <!-- <select id="ageDropdown" class="dropdown" multiple>
                                        <option value="< 18">< 18</option>
                                        <option value="18-29">18-29</option>
                                        <option value="30-39">30-39</option>
                                        <option value="40-49">40-49</option>
                                        <option value="50-59">50-59</option>
                                        <option value="60+">60+</option>
                                        <option value="Unknown">Unknown</option>
                                    </select> -->

                                    <select id="ageDropdown" class="dropdown" multiple>
                                        <option value="">Select an option</option>
                                        <option *ngFor="let age of sortAgeValues(ageObj)"
                                            value="{{age}}">{{ age }}</option>
                                    </select>
                                </div>
                                <div class="col-xl-6 mb-10">
                                    <div class="filter-heading">Gender</div>
                                    <span *ngFor="let gen of genderObj">
                                        <button value="{{gen}}" type="button" class="income-btn"
                                            (click)="onSelectFromAge('Gender', $event)">{{gen}}</button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="demographics-filter-row-margin-bottom">
                            <div class="row">
                                <div class="col-md-6 mb-10">
                                    <div class="filter-heading">Residential Property Type</div>
                                    <select id="houseHoldDropdown" class="dropdown" multiple>
                                        <option value="">Select an option</option>
                                        <option *ngFor="let houlseholdcls of houseHoldClassObj"
                                            value="{{houlseholdcls}}">{{ houlseholdcls }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-10">
                                    <div class="filter-heading">Estimated Household Income</div>
                                    <!-- <select id="houseHoldIncomedDropdown" class="dropdown" multiple>
                                        <option value="Top 10%">Top 10%</option>
                                        <option value="11 - 20%">11 - 20%</option>
                                        <option value="21 - 30%">21 - 30%</option>
                                        <option value="31 - 40%">31 - 40%</option>
                                        <option value="41 - 50%">41 - 50%</option>
                                        <option value="Lower 50%">Lower 50%</option>
                                        <option value="Unknown">Unknown</option>
                                    </select> -->
                                    <select id="houseHoldIncomedDropdown" class="dropdown" multiple>
                                        <option value="">Select an option</option>
                                        <option *ngFor="let income of sortedHouseHold(houseHoldIncome)"
                                            value="{{income}}">{{ income }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="demographics-filter-row-margin-bottom">
                            <div class="filter-heading mb-10">Education</div>
                            <span *ngFor="let edu of education">
                                <button type="button" value="{{edu}}" class="lifestage-btn"
                                    (click)="onSelectFromAge('Education', $event)">{{edu}}</button>
                            </span>
                        </div>
                        <div class="demographics-filter-row-margin-bottom">
                            <div class="row">
                                <div class="filter-heading m-10">Parental</div>
                            </div>

                            <span *ngFor="let parental of parentalObj">
                                <button type="button" value="{{parental}}" class="lifestage-btn"
                                    (click)="onSelectFromAge('Parental', $event)">{{parental}}</button>
                            </span>
                        </div>

                        <div class="demographics-filter-row-margin-bottom">
                            <div class="row">
                                <div class="col-md-6 mb-10">
                                    <div class="filter-heading">Home Location</div>
                                    <select id="locahome" class="dropdown" multiple>
                                        <option value="">Select Home Location</option>
                                        <option name="locahome" [value]="homlocName"
                                            *ngFor="let homlocName of homeLocArr;">{{homlocName}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-10">
                                    <div class="filter-heading">Work Location</div>
                                    <select id="locawork" class="dropdown" multiple>
                                        <option value="">Select Work Location</option>
                                        <option name="locawork" [value]="woklocName"
                                            *ngFor="let woklocName of workLocArr;">{{woklocName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="demographics-filter-row-margin-bottom">
                            <div class="row">
                                <div class=" col-6 filter-heading mb-10">Life Stage</div>
                            </div>
                            <div>
                                <select id="lifeStgDropdown" class="dropdown" multiple>
                                    <option *ngFor="let lifeStg of lifeStage" value="{{lifeStg}}">{{ lifeStg }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 filter-heading mb-10">Occupation</div>
                        </div>
                        <span *ngFor="let occu of occupation">
                            <button value="{{occu}}" type="button" class="income-btn"
                                (click)="onSelectFromAge('Occupation', $event)">{{occu}}</button>
                        </span>
                    </div>
                </div>

                <div class="filter-section">
                    <div class="collapse-header audience-filter-label" data-toggle="collapse" data-target="#collapseSegemt"
                        aria-expanded="false" aria-controls="collapseSegemt" (click)="addNextArrowForSubMenus('segmentTabContent')"> Segment</div>
                    <div class="subtitle-div">Select one or more segments below</div>

                    <div class="demographics-filter-1 collapse" id="collapseSegemt">
                        <div class="row">
                            <div class="col-12">
                                <select id="segmentSearch" class="dropdownfull" multiple>
                                    <option value="">Search a segment</option>
                                    <option *ngFor="let item of segmentSearchOptions" value="{{item}}">{{ toCamelCase(item.replaceAll('_',' ')) }}</option>
                                </select>
                            </div>
                        </div>
                        <div id="carouselSegmentControls" class="card-slide" data-interval="false">
                            <div class="card-inner">
                                <div class="card-item" [class.active]="i === 0"
                                    *ngFor="let item of segAllData;let i=index">
                                    <div class="card"
                                    [style.backgroundImage]="'url(\'' + imageUrl + '/segment/' + toCamelCase(formatImageName(item.key)) + '.jpg\')'">
                                    <img [src]="imageUrl + '/segment/' + toCamelCase(formatImageName(item.key)) + '.jpg'" style="
                                    object-fit: cover;
                                ">    
                                  
                                        <div class="card-body" [id]="'seglevel2Conainer'+ i">
                                            <div class="card-title">{{toCamelCase(item.key.replaceAll("_", " "))}}</div>
                                            <!-- <a class="card-link" [class.active]="j === 0 && i===0" href="#"  *ngFor="let key of getObjectKeys(item);let j = index">
                                        {{ key.replaceAll("_", " ") }}
                                    </a> -->
                                   
                                            <ul class="nav nav-tabs" [id]="'seglevel2scrollTabContent' + i" role="tablist">
                                                <li class="nav-item"
                                                    *ngFor="let navLink of item.value; let curri=index">
                                                    <a (click)="inactiveOtherTabs('seglevel1' + i + curri)" class="nav-link" [class.active]="i === 0 && curri === 0"
                                                        [attr.id]="'seglevel1' + curri + '-tab'" data-toggle="tab"
                                                        [attr.href]="'#seglevel1' + '-' + item.key + '-' + curri" role="tab"
                                                        [attr.aria-controls]="'seglevel1' + curri"
                                                        [attr.aria-selected]="curri === 0">
                                                        {{ toCamelCase(navLink.key.replaceAll("_", " ")) }}
                                                    </a>
                                                </li>
                                            </ul>
                                            <input type="hidden" name="clickeventcount" value="0">
                                            <button *ngIf="isScrollRequired('seglevel2Conainer' + i, 'seglevel2scrollTabContent' + i)" (click)="level2TabScroll('myTabs',false, $event, true)" type="button" class="tab-prev"></button>
                                            <button *ngIf="isScrollRequired('seglevel2Conainer' + i, 'seglevel2scrollTabContent' + i)" (click)="level2TabScroll('myTabs',true, $event,true)" type="button" class="tab-next"></button>
                                            
                                        </div>
                                        <div class="card-hover"></div>
                                    </div>

                                </div>
                            </div>
                            <a class="card-control-prev" href="javascript:void(0);" role="button"
                                data-slide="prev" (click)="carasoulScroll('carouselSegmentControls',false, $event, true)">
                                <span class="card-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="card-control-next" href="javascript:void(0);" role="button"
                                data-slide="next" (click)="carasoulScroll('carouselSegmentControls',true, $event, true)">
                                <span class="card-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                        <div class="p-22" class="tab-content p-22" id="segmentTabContent">
                            <div *ngFor="let item of segAllData;let parentIndex=index" class="tab-pane-body">
                                <div class="tab-pane fade"
                                    [ngClass]="{'show': i === 0 && parentIndex === 0, 'active': i === 0 && parentIndex === 0}"
                                    [attr.id]="'seglevel1' + '-' + item.key + '-' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'seglevel1' + parentIndex + i + '-tab'"
                                    *ngFor="let navLink of item.value; let i = index">

                                    <ul class="nav nav-tabs" id="level3Tabs" role="tablist">
                                        <li class="nav-item l3seg" *ngFor="let sub3 of navLink.val; let j=index">
                                            <a class="nav-link" [class.active]="j === 0" [attr.id]="'seglevel2' +  j + '-tab'"
                                                data-toggle="tab" [attr.href]="'#seglevel2' + parentIndex + i + j" role="tab"
                                                [attr.aria-controls]="'seglevel2' + parentIndex + i + j" [attr.aria-selected]="j === 0">
                                                {{toCamelCase(sub3.level3.replaceAll("_", " "))}}
                                            </a>
                                        </li>
                                    </ul>
                                    <input type="hidden" name="clickeventcount" value="0">
                                    <button (click)="navTabScroll('segmentTabContent',false, $event)" type="button" class="tab-prev"></button>
                                    <button (click)="navTabScroll('segmentTabContent',true, $event)" type="button" class="tab-next"></button>
                                    
                                    <div class="tab-pane fade" [ngClass]="{'show': j === 0, 'active': j === 0}"
                                        [attr.id]="'seglevel2'+ parentIndex + i + j" role="tabpanel"
                                        [attr.aria-labelledby]="'seglevel2' + j + '-tab'"
                                        *ngFor="let dt of navLink.val; let j=index">

                                        <!-- <div class="filter-heading m-10">{{dt.level3.replaceAll("_", " ")}}</div> -->
                                        <div class="row sub-header">
                                            <div class="col-4">Engagement Rate</div>
                                            <div class="col-4">Number of People</div>
                                            <div class="col-4"></div>
                                        </div>

                                        <div class="row sub-section"
                                            *ngFor="let item of sortedFilters(dt.filters);let indexfilter=index">
                                            <div class="col-4 progress-text">
                                                {{item.value}}
                                            </div>
                                            <div class="col-4">
                                                <span [style.color]="getColor(item.value)">{{item.count}}</span>
                                                <div class="progress" style="height: 4px;">
                                                    <div class="progress-bar" [style.background]="getColor(item.value)"
                                                       
                                                         role="progressbar" [style.width.%]="item.count"
                                                        aria-valuenow="item.count}}" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <input type="button"
                                                    [id]="dt.level1 + i + '-' + j + '-' + indexfilter" class="sel-btn"
                                                    [ngClass]="{'active-btn active': isInSelFilterArr(dt.level1 + i + '-' + j + '-' + indexfilter)}"
                                                    [value]="isInSelFilterArr(dt.level1 + i + '-' + j + '-' + indexfilter) ? 'Selected' : 'Select'"
                                                    (click)="updateSegmentObj(dt, item, $event, dt.level1 + i + '-' + j + '-' + indexfilter)">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="filter-section">
                    <div class="collapse-header audience-filter-label" data-toggle="collapse" data-target="#collapseEventSec"
                        aria-expanded="false" aria-controls="collapseEventSec" (click)="addNextArrowForSubMenus('EventTabContent')"> Event</div>
                    <div class="subtitle-div">Select one or more event or location below</div>

                    <div class="demographics-filter-1 collapse" id="collapseEventSec" >
                        <div class="row">
                            <div class="col-12">
                                <select id="eventSearch" class="dropdownfull" multiple>
                                    <option value="">Search a event</option>
                                    <option *ngFor="let item of eventSearchOptions" value="{{item}}">{{ toCamelCase(item.replaceAll('_',' ')) }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <img src="https://storage.cloud.google.com/bkt_pccw_images/event/Arts_and_Entertainment.jpg?authuser=1">
                        <img src="https://storage.cloud.google.com/bkt_pccw_images/segment/Arts and Entertainment.jpg?authuser=1"> -->
                        
                        <div id="carouselEventControls" class="card-slide" data-interval="false">
                            <div class="card-inner">
                                <div class="card-item" [class.active]="i === 0"
                                    *ngFor="let item of eventAllData;let i=index">
                                    <!-- <img src="https://storage.cloud.google.com/bkt_pccw_images/segment/Arts and Entertainment.jpg?authuser=1"> -->
                                    <div class="card"
                                    [style.backgroundImage]="'url(\'' + imageUrl + '/event/' + toCamelCase(formatImageName(item.key)) + '.jpg?authuser=1\')'">
                                        <img [src]="imageUrl + '/event/' + toCamelCase(formatImageName(item.key)) + '.jpg'" 
                                        style="object-fit: cover;"/>
                                        <div class="card-body" [id]="'eventlevel2Conainer'+ i">
                                            <h3 class="card-title">{{toCamelCase(item.key.replaceAll("_", " "))}}</h3>
                                            <!-- <a class="card-link" [class.active]="j === 0 && i===0" href="#"  *ngFor="let key of getObjectKeys(item);let j = index">
                                        {{ key.replaceAll("_", " ") }}
                                    </a> -->

                                            <ul class="nav nav-tabs"  [id]="'eventlevel2scrollTabContent' + i" role="tablist">
                                                <li class="nav-item"
                                                    *ngFor="let navLink of item.value; let curri=index">
                                                    <a (click)="inactiveOtherEventTabs('tab' + i + curri)" class="nav-link" [class.active]="i === 0 && curri === 0"
                                                        [attr.id]="'tab' + curri + '-tab'" data-toggle="tab"
                                                        [attr.href]="'#tab' + '-' + item.key + '-' + curri" role="tab"
                                                        [attr.aria-controls]="'tab' + curri"
                                                        [attr.aria-selected]="curri === 0">
                                                        {{ toCamelCase(navLink.key.replaceAll("_", " ")) }}
                                                    </a>
                                                </li>
                                            </ul>
                                            <input type="hidden" name="clickeventcount" value="0">
                                            <button *ngIf="isScrollRequired('eventlevel2Conainer' + i, 'eventlevel2scrollTabContent' + i)" (click)="level2TabScroll('myTabs',false, $event, false)" type="button" class="tab-prev"></button>
                                            <button *ngIf="isScrollRequired('eventlevel2Conainer' + i, 'eventlevel2scrollTabContent' + i)" (click)="level2TabScroll('myTabs',true, $event, false)" type="button" class="tab-next"></button>
                                            
                                        </div>
                                        <div class="card-hover"></div>
                                    </div>

                                </div>
                            </div>
                            <a class="card-control-prev" href="javascript:void(0);" role="button"
                                data-slide="prev" (click)="carasoulScroll('carouselEventControls',false, $event, false)">
                                <span class="card-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="card-control-next" href="javascript:void(0);" role="button"
                                data-slide="next" (click)="carasoulScroll('carouselEventControls',true, $event, false)">
                                <span class="card-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                        <div class="p-22" class="tab-content p-22" id="EventTabContent">
                            <div *ngFor="let item of eventAllData;let parentIndex=index" class="tab-pane-body">
                                <div class="tab-pane fade"
                                    [ngClass]="{'show': i === 0 && parentIndex === 0, 'active': i === 0 && parentIndex === 0}"
                                    [attr.id]="'tab' + '-' + item.key + '-' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'tab' + parentIndex + i + '-tab'"
                                    *ngFor="let navLink of item.value; let i = index">

                                    <ul class="nav nav-tabs" id="level3Tabs" role="tablist">
                                        <li class="nav-item" *ngFor="let sub3 of navLink.val; let j=index">
                                            <a class="nav-link" [class.active]="j === 0" [attr.id]="'tabb' + j + '-tab'"
                                                data-toggle="tab" [attr.href]="'#tabb' + parentIndex + i + j" role="tab"
                                                [attr.aria-controls]="'tabb'+ parentIndex + i + j" [attr.aria-selected]="j === 0">
                                                {{toCamelCase(sub3.level3.replaceAll("_", " "))}}
                                            </a>
                                        </li>
                                    </ul>

                                    <input type="hidden" name="clickeventcount" value="0">
                                    <button (click)="navTabScroll('EventTabContent',false, $event)" type="button" class="tab-prev"></button>
                                    <button (click)="navTabScroll('EventTabContent',true, $event)" type="button" class="tab-next"></button>
                                    <div class="tab-pane fade" [ngClass]="{'show': j === 0, 'active': j === 0}"
                                        [attr.id]="'tabb'+ parentIndex + i + j" role="tabpanel"
                                        [attr.aria-labelledby]="'tabb' + j + '-tab'"
                                        *ngFor="let dt of navLink.val; let j=index">




                                        <!-- <div class="filter-heading m-10">{{dt.level3.replaceAll("_", " ")}}</div> -->
                                        <div class="row sub-header">
                                            <div class="col-4">Engagement Rate</div>
                                            <div class="col-4">Number of People</div>
                                            <div class="col-4"></div>
                                        </div>

                                        <div class="row sub-section"
                                            *ngFor="let item of sortedFilters(dt.filters);let indexfilter=index">
                                            <div class="col-4 progress-text">
                                                {{item.value}}
                                            </div>
                                            <div class="col-4">
                                                <span [style.color]="getColor(item.value)">{{item.count}}</span>
                                                <div class="progress" style="height: 4px;">
                                                    <div class="progress-bar" [style.background]="getColor(item.value)"
                                                    role="progressbar" [style.width.%]="item.count"
                                                        aria-valuenow="item.count}}" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <input type="button"
                                                    [id]="dt.level1 + i + '-' + j + '-' + indexfilter" class="sel-btn"
                                                    [ngClass]="{'active-btn active': isInSelFilterArrEvent(dt.level1 + i + '-' + j + '-' + indexfilter)}"
                                                    [value]="isInSelFilterArrEvent(dt.level1 + i + '-' + j + '-' + indexfilter) ? 'Selected' : 'Select'"
                                                    (click)="updateEventObj(dt, item, $event, dt.level1 + i + '-' + j + '-' + indexfilter)">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="filter-section">
                    <div class="collapse-header audience-filter-label" data-toggle="collapse" data-target="#collapseDevice" aria-expanded="false" aria-controls="collapseDevice"> Device</div>      
                    <div class="subtitle-div">Select the device(s) used by the audience.</div>

                    <div class="demographics-filter-1 collapse" id="collapseDevice" >  
                        <div class="carosoul-section-1">
                            
                        </div>
                        <div class="row">
                            <div class="col-6">
                                Device Type
                            </div>
                            <div class="col-6">
                                Device OS
                            </div>
                            <div class="col-6">
                                <select id="deviceType" class="dropdown" multiple>
                                  <option name="deviceType" [value]="deviceType.value" *ngFor="let deviceType of deviceTypeArray">{{toCamelCase(deviceType.value.replaceAll('_',' '))}}</option>
                              </select> 
                          </div>
                          <div class="col-6">
                            <select id="deviceOS" class="dropdown" multiple>
                                <option name="deviceOS" [value]="deviceOS.value" *ngFor="let deviceOS of deviceOSArray">{{toCamelCase(deviceOS.value.replaceAll('_',' '))}}</option>
                            </select> 
                          </div>
                            <!--old device format-->
                            <!-- <div class="col-6">
                                  <select id="deviceLevel1" class="dropdown" multiple>
                                    <option name="deviceLevel1" [value]="deviceName" *ngFor="let deviceName of deviceLevel1Arr;">{{deviceName.replaceAll("_", " ")}}</option>
                                </select> 
                            </div>
                            <div class="col-6">
                                <select id="deviceLevel2" class="dropdown" multiple>
                                    <option *ngFor="let deviceName of groupedDeviceLevel2Data; let i=index" [value]="deviceName.key">{{ deviceName.key.replaceAll("_", " ") }}</option>
                                </select>
                            </div> -->
                        </div> 
                        <br />
                        <div class="row">
                            <div class="col-6">
                                Device Brand
                            </div>
                            <div class="col-6">
                                Device Model
                            </div>
                            <div class="col-6">
                                <select id="deviceBrand" class="dropdown" multiple>
                                  <option name="deviceBrand" [value]="deviceBrand.value" *ngFor="let deviceBrand of deviceBrandArray">{{toCamelCase(deviceBrand.value.replaceAll('_',' '))}}</option>
                              </select> 
                          </div>
                          <div class="col-6">
                            <select id="deviceModel" class="dropdown" multiple>
                                <option name="deviceModel" [value]="deviceModel.value" *ngFor="let deviceModel of deviceModelArray">{{toCamelCase(deviceModel.value.replaceAll('_',' '))}}</option>
                            </select> 
                          </div>
                        </div> 
                    </div>
                </div>
            <!--<div class="filter-section">
                    <div class="collapse-header" data-toggle="collapse" data-target="#collapseLocation"
                        aria-expanded="false" aria-controls="collapseLocation"> Location</div>
                    <div class="subtitle-div">You can select Location</div>
                    <div class="demographics-filter-1 collapse" id="collapseLocation" data-parent="#accordion">
                         <div class="row">
                            <div class="col-6">
                                <div class="subtitle-div">Home</div>
                                <select id="locahome" class="dropdown">
                                    <option value="">Select Home Location</option>
                                    <option name="locahome" [value]="homlocName" *ngFor="let homlocName of homeLocArr;">{{homlocName}}</option>
                                </select> 
                            </div>
                            <div class="col-6">
                                <div class="subtitle-div">Work</div>
                                <select id="locawork" class="dropdown">
                                    <option value="">Select Work Location</option>
                                    <option name="locawork" [value]="woklocName" *ngFor="let woklocName of workLocArr;">{{woklocName}}</option>
                                </select>
                            </div>
                        </div> 

                        <iframe #crowdedCustomerInsightUnreg frameborder="0" style="border:0" allowfullscreen
                            class="iframeSize" height="600" width="100%"></iframe>

                    </div>
                </div>-->

                <!-- <div class="filter-section">
                    <div class="collapse-header"> Save</div>


                    <div class="subtitle-div">SAVE Selected Filter</div>
                    <div class="row">
                        <div class="col-12">
                            <input type="button" class="save-btn btn btn-primary" value="SAVE"
                                (click)="saveAudConfirmationDialog()">
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-md-3 right-result-section justify-content-center">
            <div class="right-chart-section">
                <div class="est-project-label text-center"> Estimated Results</div>
                <!-- <div class="subtitle-div">Your potential audience size is 5,600,000 - 6,600,000</div> -->
                <div echarts [options]="pieChartOptions" class="aud-donut-chart"></div>  
            </div>           
                <div id="eventSelData" *ngIf="showSegmentSelSec" class="mt-2 marginLeft15"><b>Applied Filter on Segment</b></div>
                <div class="mt-2 marginLeft15">                    
                    <div class="selData" *ngFor="let item of jsonObj.Segment">                    
                        <div title="{{item.level1}}" class="mb-1"><b>Level 1:</b></div>
                        <div> {{toCamelCase(item.level1.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level2}}" class="mt-1 mb-1"><b>Level 2:</b></div>
                        <div> {{toCamelCase(item.level2.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level3}}" class="mt-2 mb-1"><b>Level 3:</b></div>
                        <div> {{toCamelCase(item.level3.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level4}}" class="mt-1 mb-1"><b>Engagement Level:</b></div>
                        <div> <span *ngFor="let data of item.filters; let i = index">{{i > 0 ? ',' + data.value.substring(0,25) : data.value.substring(0,25)}}</span></div>
                        <br />                        
                    </div>                
                </div>
                
                <div id="eventSelData" *ngIf="showEventSelSec" class="marginLeft15"><b>Applied Filter on Event</b></div>
                <br /> 
                <div>                    
                    <div class="selData marginLeft15" *ngFor="let item of jsonObj.Event">                    
                        <div title="{{item.level1}}" class="mb-1"><b>Level 1:</b></div>
                        <div> {{toCamelCase(item.level1.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level2}}" class="mt-1 mb-1"><b>Level 2:</b></div>
                        <div> {{toCamelCase(item.level2.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level3}}" class="mt-1 mb-1"><b>Level 3:</b></div>
                        <div> {{toCamelCase(item.level3.replaceAll("_", " ").substring(0,25))}}</div>
                        <div title="{{item.level4}}" class="mt-1 mb-1"><b>Engagement Level:</b></div>
                        <div> <span *ngFor="let data of item.filters; let i = index">{{i > 0 ? ',' + data.value.substring(0,25) : data.value.substring(0,25)}}</span></div>
                        <br />                        
                    </div>               
                </div>
                <div class="d-flex justify-content-center audiencebtn">
                    <button class="form-control btn btn-primary action-btn submit" (click)="saveAudConfirmationDialog()"
                    [disabled]="isDisabledsaveBtn">Create Audience</button>                                  
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>

    <ng-template #AudienceConfirmNavigation>
        <mat-dialog-content>
            <div class="subscribeForFullAccessDiv" align="left">
                <div>
                    <div>
                        <mat-dialog-actions>
                            <button mat-button mat-dialog-close>
                               <img [src]="imagePath + 'close-icon.svg'">
                            </button>
                        </mat-dialog-actions>
                    </div>
    
                    <div class="subscibePopupHeading text-center">
                        <img [src]="imagePath + 'Aud-save.png'">
                        <div id="displaySaveMsg" class="displaySaveMsg">{{displaySaveMsg}}</div>
                    </div>
                    <div class="mt-10 modal-body text-center">
                        <button class="action-btn cont-aud-btn renamePopupBtn" type="button" value="audience" (click)="disaplayAudScreen()">Continue Audience Creation</button><br>
                        <button class="action-btn create-camp-btn renamePopupBtn" type="button" value="campaign" (click)="displayCampaignScreen()">Create Campaign</button>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #saveAudienceDialog>
        <mat-dialog-content>
            <div class="subscribeForFullAccessDiv d-flex  justify-content-center align-items-center">         
                <div class="subscibePopupHeading">
                        <div  class="row">
                          <div  class="save-text-heading col-lg-10">
                            Audience Name
                          </div>
                          <div class="col-lg-2 paddingLeft30" >
                              <button mat-button mat-dialog-close class="closeBtn">
                                <img [src]="imagePath + 'close-icon.svg'">
                              </button>
                          </div>
                        </div>
                        <div id="audmsg"></div>
                        <input required class="save-text" type="text" name="savecomment" id="saveComment" placeholder="Ex: Working Parents in their 30s">
                        <button class="mr-6 btn action-btn mt-3 renamePopupBtn" type="button" value="audience" (click)="saveAudienceData()" [disabled]="creatAudBtnDisable">Create</button>
                        <button class="btn action-sec-btn mt-3 renamePopupBtn" type="button" value="campaign" mat-button mat-dialog-close>Cancel</button>
                </div>          
              </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #saveCampaignDialog>
        <mat-dialog-content>
            <div class="subscribeForFullAccessDiv d-flex  justify-content-center align-items-center">         
                <div class="subscibePopupHeading">
                        <div  class="row">
                          <div  class="save-text-heading col-lg-10">
                            Campaign Name
                          </div>
                          <div class="col-lg-2 paddingLeft30" >
                            <button mat-button mat-dialog-close class="closeBtn">
                              <img [src]="imagePath + 'close-icon.svg'">
                            </button>
                           </div>
                        </div>
                        <div id="campmsg"></div>
                        <input required class="save-text" type="text" name="savecampaigncomment" id="saveCampaignComment" placeholder="Ex: Working Parents in their 30s">
                        
                        <button class="mr-6 btn action-btn mt-3 renamePopupBtn" type="button" value="audience" (click)="saveCampaign()">Create</button>
                        <button class="btn action-sec-btn mt-3 renamePopupBtn" type="button" value="campaign" mat-button mat-dialog-close>Cancel</button>
                </div>          
              </div>
        </mat-dialog-content>
    </ng-template>
</div>