import { Component } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { SessionService } from '../session.service';
import { DBAPIService } from 'src/app/dbapi.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  user = {
    organization: '',
    userEmail: '',
    password: ''
  };
  sessionUserName:any = '';
  msg: any = '';
  error: boolean = false;

  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, 
              private sessionService: SessionService){ 
  }

  createUser() {
    this.DBAPIObj.signUpUser(this.user).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;
          this.msg = data['message']       
        }else {
          this.error = true;
          this.msg = data['message'];        
        }
      },
      error: (error) => {
        this.error = true;
        this.msg = (error.error && error.error.message)? error.error.message.replaceAll('_', ' ') :'Error occured while signup';        
      },
    });
  }
}
