import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { environment } from 'src/environments/environment';
// import { HttpClient } from  '@angular/common/http';
// import { SessionService } from '../session.service';
// import { DBAPIService } from 'src/app/dbapi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  
  imagePath:any=environment.imagePath;
  //imageBasePath: environment.imageBasePath;
  //  user = {
  //   username: '',
  //   email: '',
  //   password: ''
  // };

  // sessionUserName='';

  // constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, private sessionService: SessionService){ }

  // createUser() {
  //   this.DBAPIObj.signUpUser(this.user).subscribe({
  //     next: (data) => {
  //       this.sessionUserName = this.user.username;
  //       alert('User created successfully:' + data);
  //     },
  //     error: (error) => {
  //       alert('Error fetching data:'+ error);
  //     },
  //   });
  // }

  // logInUser() {
  //   console.log(this.user);
  //   this.DBAPIObj.login(this.user).subscribe({
  //     next: (data) => {
  //       this.sessionUserName = this.user.username;
  //       alert('User Login successfully:' + data);
  //     },
  //     error: (error) => {
  //       alert('Error fetching data:'+ error);
  //     },
  //   });
  // }

  // resetPassword(){

  // }

  // setUserInSession(username: any, email:any) {
  //   this.sessionService.setItem('user', { email: email, name: username });
  // }

}
