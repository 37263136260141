import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject  } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  public sessionEnable: BehaviorSubject<string> = new BehaviorSubject<string>('');  
  sessionData = this.sessionEnable.asObservable();

  setSessionData(data: any){
    this.sessionEnable.next(data);
  }

  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
