import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DBAPIService {
 
  public projectListObserve: BehaviorSubject<string> = new BehaviorSubject<string>('');  
  projectList = this.projectListObserve.asObservable();

  setprojectList(data: any){
    this.projectListObserve.next(data);
  }

  constructor(private httpClient: HttpClient) {}
  
  public signUpUser(userData: any){
    return this.httpClient.post(environment.apiUrl + 'registerUser/', userData);
  }

  public emailVerification(oobcode:any){
   // http://34.150.51.234:8080/api/pccw/verifyEmail?oobCode=xWQWTTqdb2gECrqkisxORmtwhgfmfyGeiPV9pJrP1q4AAAGMhlYrbw
    return this.httpClient.get(environment.apiUrl + 'verifyEmail?oobCode='+oobcode);
  }

  public forgotpwd(data:any){
    return this.httpClient.post(environment.apiUrl + 'resetPassword/', data);
  }
  public updateProject(projData: any){
    return this.httpClient.post(environment.apiUrl + 'changeProject/', projData);
  }

  public login(userData: any){
    return this.httpClient.post(environment.apiUrl + 'login/', userData);
  }

  public changePassword(userData: any){
    return this.httpClient.post(environment.apiUrl + 'changePassword/', userData);
  }

  public saveChannelData(data:any){
    return this.httpClient.post<any>(environment.apiUrl + 'saveWhatsappDetails', data); 
  }
  
  public createProject(postParams: any) {
   return this.httpClient.post<any>(environment.apiUrl + 'createProject', postParams);    
  } 

  public listProject(postParams: any) {
    return this.httpClient.post<any>(environment.apiUrl + 'listProject', postParams);    
   } 

  public getSegmentSearchData(){
    return this.httpClient.get(environment.apiUrl + 'searchSegment');
  }

  public getEventSearchData(){
    return this.httpClient.get(environment.apiUrl + 'searchEvent');
  }

  public getAllAudienceData(jsonObj: any){
    return this.httpClient.post(environment.apiUrl+ 'audienceFilterRetrieval',JSON.stringify(jsonObj));
  }

  public updateAudience(projData: any){
    return this.httpClient.post(environment.apiUrl + 'changeAudience/', projData);
  }

  public getAudListData(jsonObj: any){
    return this.httpClient.post(environment.apiUrl+ 'audienceSetRetrieval',JSON.stringify(jsonObj));
    /*let res = [{
        "audienceName": "Aud1",
        "Organization": "ORG1",
        "Project": "PR1",
        "UserID": "Deepak"
    },
    {
        "audienceName": "Aud2",
        "Organization": "ORG1",
        "Project": "PR2",
        "UserID": "Deepak"
    },
    {
        "audienceName": "Aud3",
        "Organization": "ORG1",
        "Project": "PR3",
        "UserID": "Deepak"
    },
    {
        "audienceName": "Aud4",
        "Organization": "ORG1",
        "Project": "PR4",
        "UserID": "Deepak"
    },
    {
        "audienceName": "Aud5",
        "Organization": "ORG1",
        "Project": "PR5",
        "UserID": "Deepak"
    }
    ];
    return of(res);*/
  }

  public getCampaignListData(jsonObj:any){
    return this.httpClient.post(environment.apiUrl+ 'listCampaign',JSON.stringify(jsonObj));
    // let res = [{
    //     "campaignName": "Camp1",
    //     "Organization": "ORG1",
    //     "Project": "PR1",
    //     "UserID": "Deepak"
    // },
    // {
    //     "campaignName": "Camp2",
    //     "Organization": "ORG1",
    //     "Project": "PR2",
    //     "UserID": "Deepak"
    // },
    // {
    //     "campaignName": "Camp3",
    //     "Organization": "ORG1",
    //     "Project": "PR3",
    //     "UserID": "Deepak"
    // },
    // {
    //     "campaignName": "Camp4",
    //     "Organization": "ORG1",
    //     "Project": "PR4",
    //     "UserID": "Deepak"
    // },
    // {
    //     "campaignName": "Camp5",
    //     "Organization": "ORG1",
    //     "Project": "PR5",
    //     "UserID": "Deepak"
    // }
    // ];
    // return of(res);
  }
  

  public updateCampaign(campaignData:any){
    return this.httpClient.post(environment.apiUrl + 'changeCampaign/', campaignData);
  }

  public getFilterCount(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl+ 'audienceFilterCountRetrieval',JSON.stringify(jsonObj));
  }

  public getSegEventFilterCount(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl+ 'audienceFilterSegmentCountRetrieval',JSON.stringify(jsonObj));
  }
  
  public saveAudieanceData(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl+'saveAudience',JSON.stringify(jsonObj));
  }

  public getCampaignData(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl + 'audienceSetRetrieval',JSON.stringify(jsonObj));
  }

  public setaudienceUnionCountDetails(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl +'audienceSetUnionCountDetails',JSON.stringify(jsonObj));
  }

  public saveCampaignDetails(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl+'saveCampaign',JSON.stringify(jsonObj));
  }

  public validateCampaign(jsonObj: any){
    return this.httpClient.post<any>(environment.apiUrl+'checkCampaign',JSON.stringify(jsonObj));
  }
  
  public getChannelListData(){
    return this.httpClient.get(environment.apiUrl + 'listCampaignMethod');
  }

  public getChannelListDataForAdmin(){
    return this.httpClient.get(environment.apiUrl + 'listAllCampaign');
  }

  public getAllAudienceData1(jsonObj: any){
    let res = {
        "Demographics": {
            "Age": {
                "filters": [
                    {
                        "value": "< 18"
                    },
                    {
                        "value": "18-29"
                    },
                    {
                        "value": "60+"
                    },
                    {
                        "value": "30-39"
                    },
                    {
                        "value": "Unknown"
                    },
                    {
                        "value": "40-49"
                    },
                    {
                        "value": "50-59"
                    }
                ]
            },
            "Gender": {
                "filters": [
                    {
                        "value": "UNKNOWN"
                    },
                    {
                        "value": "MALE"
                    },
                    {
                        "value": "FEMALE"
                    }
                ]
            },
            "Household Class": {
                "filters": [
                    {
                        "value": "Home Ownership Scheme"
                    },
                    {
                        "value": "Others"
                    },
                    {
                        "value": "Village"
                    },
                    {
                        "value": "Private"
                    },
                    {
                        "value": "Public"
                    }
                ]
            },
            "Education": {
                "filters": [
                    {
                        "value": "Secondary School Leaver"
                    },
                    {
                        "value": "Advanced Degree"
                    },
                    {
                        "value": "Bachelor’s Degree"
                    },
                    {
                        "value": "Current University Students"
                    }
                ]
            },
            "Life Stage": {
                "filters": [
                    {
                        "value": "Family with grow up child(ren)"
                    },
                    {
                        "value": "Family with young child(ren)"
                    },
                    {
                        "value": "Students"
                    },
                    {
                        "value": "Family with no child"
                    },
                    {
                        "value": "Single"
                    },
                    {
                        "value": "Just married"
                    },
                    {
                        "value": "Retirement"
                    }
                ]
            },
            "Household Income": {
                "filters": [
                    {
                        "value": "Lower 50%"
                    },
                    {
                        "value": "Unknown"
                    },
                    {
                        "value": "21 - 30%"
                    },
                    {
                        "value": "Top 10%"
                    },
                    {
                        "value": "31 - 40%"
                    },
                    {
                        "value": "41 - 50%"
                    },
                    {
                        "value": "11 - 20%"
                    }
                ]
            },
            "Occupation": {
                "filters": [
                    {
                        "value": "Blue Collar or Others"
                    },
                    {
                        "value": "White Collar"
                    }
                ]
            },
            "Parental": {
                "filters": [
                    {
                        "value": "Unknown"
                    },
                    {
                        "value": "Not a parent"
                    },
                    {
                        "value": "Parent"
                    }
                ]
            }
        },
        "Location": {
            "householdDistrict": [
                "SHA TIN",
                "SAI KUNG",
                "KWUN TONG",
                "ISLANDS",
                "TSEUN WAN",
                "SHAM SHUI PO",
                "CENTRAL AND WESTERN",
                "YAU TSIM MONG",
                "KWAI TSING",
                "TAI PO",
                "SOUTHERN",
                "EASTERN",
                "NORTH",
                "YEUN LONG",
                "WONG TAI SIN",
                "KOWLOON CITY",
                "TUEN MUN",
                "WAN CHAI"
            ],
            "workDistrict": [
                "SHA TIN",
                "SAI KUNG",
                "KWUN TONG",
                "ISLANDS",
                "TSEUN WAN",
                "SHAM SHUI PO",
                "YUEN LONG",
                "YAU TSIM MONG",
                "CENTRAL AND WESTERN",
                "KWAI TSING",
                "SOUTHERN",
                "TAI PO",
                "NORTH",
                "EASTERN",
                "WONG TAI SIN",
                "KOWLOON CITY",
                "TUEN MUN",
                "WAN CHAI"
            ]
        },
        "Segment": [
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Comics_and_Animation",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Entertainment_Industry",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Events_and_Listings",
                "filters": [
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Fun_and_Trivia",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Humor",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Movies",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Music_and_Audio",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Offbeat",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Online_Media",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Performing_Arts",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "TV_and_Video",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Arts_and_Entertainment",
                "level3": "Visual_Art_and_Design",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Bicycles_and_Accessories",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Commercial_Vehicles",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Motor_Vehicles_By_Brand_",
                "filters": [
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Motor_Vehicles_By_Type_",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Vehicle_Codes_and_Driving_Laws",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Vehicle_Parts_and_Services",
                "filters": [
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Autos_and_Vehicles",
                "level2": "Autos_and_Vehicles",
                "level3": "Vehicle_Shopping",
                "filters": [
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Beauty_and_Fitness",
                "level2": "Beauty_and_Fitness",
                "level3": "Beauty_Services_and_Spas",
                "filters": [
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Beauty_and_Fitness",
                "level2": "Beauty_and_Fitness",
                "level3": "Face_and_Body_Care",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "21"
                    }
                ]
            },
            {
                "level1": "Beauty_and_Fitness",
                "level2": "Beauty_and_Fitness",
                "level3": "Fashion_and_Style",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Beauty_and_Fitness",
                "level2": "Beauty_and_Fitness",
                "level3": "Fitness",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Beauty_and_Fitness",
                "level2": "Beauty_and_Fitness",
                "level3": "Hair_Care",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "14"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Advertising_and_Marketing",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Aerospace_and_Defense",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Agriculture_and_Forestry",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Business_Finance",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Business_Operations",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Business_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Chemicals_Industry",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Construction_and_Maintenance",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Energy_and_Utilities",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Hospitality_Industry",
                "filters": [
                    {
                        "value": "High",
                        "count": "3"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Industrial_Materials_and_Equipment",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Manufacturing",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Metals_and_Mining",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "31"
                    },
                    {
                        "value": "Mid",
                        "count": "16"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Printing_and_Publishing",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Retail_Trade",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "14"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Shipping_and_Logistics",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Business_and_Industrial",
                "level2": "Business_and_Industrial",
                "level3": "Small_Business",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Computer_Hardware",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Computer_Security",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Consumer_Electronics",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Electronics_and_Electrical",
                "filters": [
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Enterprise_Technology",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Networking",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Programming",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Computers_and_Electronics",
                "level2": "Computers_and_Electronics",
                "level3": "Software",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Fashion_and_Beauty",
                "level2": "Beauty_Center",
                "level3": "Beauty_Salon",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "30"
                    },
                    {
                        "value": "Mid",
                        "count": "17"
                    }
                ]
            },
            {
                "level1": "Fashion_and_Beauty",
                "level2": "Beauty_Center",
                "level3": "Blog",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "13"
                    },
                    {
                        "value": "Mid",
                        "count": "33"
                    }
                ]
            },
            {
                "level1": "Fashion_and_Beauty",
                "level2": "Beauty_Center",
                "level3": "Cosmetics",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Finance2",
                "level2": "Premium_Banking",
                "level3": "Banking",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "34"
                    }
                ]
            },
            {
                "level1": "Finance2",
                "level2": "Premium_Banking",
                "level3": "Investing",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "27"
                    },
                    {
                        "value": "Mid",
                        "count": "19"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Crypto",
                "level3": "Crypto",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Accounting_and_Auditing",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Banking",
                "filters": [
                    {
                        "value": "High",
                        "count": "4"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Credit_and_Lending",
                "filters": [
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Financial_Planning_and_Management",
                "filters": [
                    {
                        "value": "Low",
                        "count": "26"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Grants",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Insurance",
                "filters": [
                    {
                        "value": "Low",
                        "count": "26"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Finance",
                "level3": "Investing",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Finance",
                "level2": "Loans",
                "level3": "Loans",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_Delivery",
                "level3": "Delivery",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Beverages",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Cooking_and_Recipes",
                "filters": [
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Food",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Food_and_Grocery_Delivery",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Food_and_Grocery_Retailers",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "26"
                    }
                ]
            },
            {
                "level1": "Food_and_Drink",
                "level2": "Food_and_Drink",
                "level3": "Restaurants",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Games",
                "level2": "Games",
                "level3": "Board_Games",
                "filters": [
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "33"
                    }
                ]
            },
            {
                "level1": "Games",
                "level2": "Games",
                "level3": "Card_Games",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Games",
                "level2": "Games",
                "level3": "Computer_and_Video_Games",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Games",
                "level2": "Games",
                "level3": "Family_Oriented_Games_and_Activities",
                "filters": [
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Games",
                "level2": "Games",
                "level3": "Table_Games",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Aging_and_Geriatrics",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Alternative_and_Natural_Medicine",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Health_Conditions",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Medical_Devices_and_Equipment",
                "filters": [
                    {
                        "value": "High",
                        "count": "3"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "19"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Medical_Facilities_and_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Medical_Literature_and_Resources",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Mental_Health",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Nursing",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Nutrition",
                "filters": [
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Pharmacy",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Public_Health",
                "filters": [
                    {
                        "value": "High",
                        "count": "3"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Reproductive_Health",
                "filters": [
                    {
                        "value": "Low",
                        "count": "27"
                    },
                    {
                        "value": "Mid",
                        "count": "21"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Substance_Abuse",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Health",
                "level2": "Health",
                "level3": "Vision_Care",
                "filters": [
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Clubs_and_Organizations",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Crafts",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Outdoors",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Radio_Control_and_Modeling",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Special_Occasions",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Hobbies_and_Leisure",
                "level2": "Hobbies_and_Leisure",
                "level3": "Water_Activities",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "26"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Bed_and_Bath",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "14"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Domestic_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "26"
                    },
                    {
                        "value": "Mid",
                        "count": "21"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "HVAC_and_Climate_Control",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Home_Appliances",
                "filters": [
                    {
                        "value": "High",
                        "count": "3"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Home_Furnishings",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Home_Improvement",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Home_Safety_and_Security",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Home_Storage_and_Shelving",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "26"
                    },
                    {
                        "value": "Mid",
                        "count": "20"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Household_Supplies",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Kitchen_and_Dining",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "15"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Laundry",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "35"
                    }
                ]
            },
            {
                "level1": "Home_and_Garden",
                "level2": "Home_and_Garden",
                "level3": "Patio",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Communications_Equipment",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Email_and_Messaging",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Mobile_and_Wireless",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "21"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Search_Engines",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Service_Providers",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Internet_and_Telecom",
                "level2": "Internet_and_Telecom",
                "level3": "Web_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "28"
                    },
                    {
                        "value": "Mid",
                        "count": "17"
                    }
                ]
            },
            {
                "level1": "Jobs_and_Education",
                "level2": "Jobs_and_Education",
                "level3": "Education",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "14"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Jobs_and_Education",
                "level2": "Jobs_and_Education",
                "level3": "Jobs",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Law_and_Government",
                "level2": "Law_and_Government",
                "level3": "Government",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Law_and_Government",
                "level2": "Law_and_Government",
                "level3": "Legal",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Law_and_Government",
                "level2": "Law_and_Government",
                "level3": "Military",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "24"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Law_and_Government",
                "level2": "Law_and_Government",
                "level3": "Public_Safety",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Law_and_Government",
                "level2": "Law_and_Government",
                "level3": "Social_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "News",
                "level2": "News",
                "level3": "Business_News",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "News",
                "level2": "News",
                "level3": "Gossip_and_Tabloid_News",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "News",
                "level2": "News",
                "level3": "Politics",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Online_Communities",
                "level2": "Online_Communities",
                "level3": "Blogging_Resources_and_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Online_Communities",
                "level2": "Online_Communities",
                "level3": "Dating_and_Personals",
                "filters": [
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Online_Communities",
                "level2": "Online_Communities",
                "level3": "Online_Goodies",
                "filters": [
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Online_Communities",
                "level2": "Online_Communities",
                "level3": "Photo_and_Video_Sharing",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Ethnic_and_Identity_Groups",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Family_and_Relationships",
                "filters": [
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Men_s_Interests",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Religion_and_Belief",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Social_Issues_and_Advocacy",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "28"
                    },
                    {
                        "value": "Mid",
                        "count": "19"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Social_Sciences",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "People_and_Society",
                "level2": "People_and_Society",
                "level3": "Subcultures_and_Niche_Interests",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Pets_and_Animals",
                "level2": "Pets_and_Animals",
                "level3": "Animal_Products_and_Services",
                "filters": [
                    {
                        "value": "Low",
                        "count": "14"
                    },
                    {
                        "value": "Mid",
                        "count": "34"
                    }
                ]
            },
            {
                "level1": "Pets_and_Animals",
                "level2": "Pets_and_Animals",
                "level3": "Pets",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Real_Estate",
                "level2": "Real_Estate",
                "level3": "Real_Estate_Listings",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Real_Estate",
                "level2": "Real_Estate",
                "level3": "Real_Estate_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "Directories_and_Listings",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "General_Reference",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "Geographic_Reference",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "Humanities",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "Language_Resources",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Reference",
                "level2": "Reference",
                "level3": "Libraries_and_Museums",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Biological_Sciences",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Computer_Science",
                "filters": [
                    {
                        "value": "High",
                        "count": "3"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Earth_Sciences",
                "filters": [
                    {
                        "value": "Low",
                        "count": "23"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Ecology_and_Environment",
                "filters": [
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Engineering_and_Technology",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Science",
                "level2": "Science",
                "level3": "Mathematics",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Apparel",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Consumer_Resources",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "31"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Entertainment_Media",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Gifts_and_Special_Event_Items",
                "filters": [
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Photo_and_Video_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "29"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Shopping",
                "level3": "Toys",
                "filters": [
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Animal_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Combat_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "23"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Extreme_Sports",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Individual_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "International_Sports_Competitions",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "25"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Motor_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "26"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Sporting_Goods",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Team_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "22"
                    },
                    {
                        "value": "Mid",
                        "count": "24"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Water_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Sports",
                "level2": "Sports",
                "level3": "Winter_Sports",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "34"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Hotels_and_Accommodations",
                "filters": [
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "32"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Luggage_and_Travel_Accessories",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "25"
                    },
                    {
                        "value": "Mid",
                        "count": "22"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Specialty_Travel",
                "filters": [
                    {
                        "value": "High",
                        "count": "2"
                    },
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Tourist_Destinations",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "20"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Transportation",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "Travel_and_Transportation",
                "level2": "Travel_and_Transportation",
                "level3": "Travel_Agencies_and_Services",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "Africa",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "27"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "Asia",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "16"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "Europe",
                "filters": [
                    {
                        "value": "Low",
                        "count": "19"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "Latin_America",
                "filters": [
                    {
                        "value": "Low",
                        "count": "18"
                    },
                    {
                        "value": "Mid",
                        "count": "28"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "North_America",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "17"
                    },
                    {
                        "value": "Mid",
                        "count": "30"
                    }
                ]
            },
            {
                "level1": "World_Localities",
                "level2": "World_Localities",
                "level3": "Oceania",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "21"
                    },
                    {
                        "value": "Mid",
                        "count": "26"
                    }
                ]
            }
        ],
        "Event": [
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "CJ7_Extraterrestrial_URDU_Limited_Time_Store",
                "filters": [
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "5"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "Korean_Independent_Film_Festival_K_October_Cultural_Festival_2023",
                "filters": [
                    {
                        "value": "Low",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "Pictorial_Demeanor_Hong_Kong_Cinema_and_Comics_Exhibition",
                "filters": [
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "Qi_Series_Multiverse_of_Film_Art",
                "filters": [
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "Royal_Castle_X_CJ7_Extraterrestrial_15th_Anniversary_Celebration",
                "filters": [
                    {
                        "value": "Low",
                        "count": "11"
                    },
                    {
                        "value": "Mid",
                        "count": "5"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Cinema",
                "level3": "_Umbilical_Cord_Chinese_Mainland_Film_Festival_2023",
                "filters": [
                    {
                        "value": "Low",
                        "count": "3"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Events_and_Tickets",
                "level3": "Future_Music_Festival_Tone_Music_Festival_2023",
                "filters": [
                    {
                        "value": "Low",
                        "count": "2"
                    },
                    {
                        "value": "Mid",
                        "count": "8"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Events_and_Tickets",
                "level3": "MULTIVERSE_Elixir_D_N_Concert_2023_Hong_Kong_Postponed_",
                "filters": [
                    {
                        "value": "Low",
                        "count": "0"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Events_and_Tickets",
                "level3": "Miz_Cracker_Live_in_Hong_Kong_Concert",
                "filters": [
                    {
                        "value": "Low",
                        "count": "1"
                    },
                    {
                        "value": "Mid",
                        "count": "3"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Events_and_Tickets",
                "level3": "sukekiyo_2Days_Live_in_Hong_Kong_Concert_2023",
                "filters": [
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Dress_Up_Time_Exhibition",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Gazing_at_Sanxingdui_New_Archaeological_Discoveries_in_Sichuan_Special_Exhibition",
                "filters": [
                    {
                        "value": "Low",
                        "count": "3"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Golden_Splendour_Ancient_Gold_from_the_Hong_Kong_Palace_Museum_and_the_Meiyetang_Collection_Special_Exhibition",
                "filters": [
                    {
                        "value": "Low",
                        "count": "9"
                    },
                    {
                        "value": "Mid",
                        "count": "3"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Ink_Pulse_Site_specific_Moving_Image_Artwork_M_Facade",
                "filters": [
                    {
                        "value": "Low",
                        "count": "1"
                    },
                    {
                        "value": "Mid",
                        "count": "0"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Landscape_in_Ink_and_Wash_Beyond_the_Image_Digital_Installation_Art",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Literati_Playthings_Chinese_Literati_Utensils_and_Tea_Life_Exhibition",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "M_New_Special_Exhibition_Florence_Fang_Art_Pioneer_and_Fashion_Godmother",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "7"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "M_Sigg_Collection_Another_History_Exhibition",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    },
                    {
                        "value": "Low",
                        "count": "8"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "The_Melancholy_Kitchen_Street_Food_Guide_Exhibition",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "Walk_Walk_Jump_Jump_Exhibition_9th_Tactile_Arts_Festival",
                "filters": [
                    {
                        "value": "High",
                        "count": "1"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "You_Exhibition",
                "filters": [
                    {
                        "value": "Low",
                        "count": "4"
                    },
                    {
                        "value": "Mid",
                        "count": "14"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "_Digital_Commissioned_Interactive_Work_M_Facade",
                "filters": [
                    {
                        "value": "Low",
                        "count": "1"
                    },
                    {
                        "value": "Mid",
                        "count": "2"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "_The_Mysterious_Kingdom_of_Fungi_Dome_Film",
                "filters": [
                    {
                        "value": "Low",
                        "count": "0"
                    }
                ]
            },
            {
                "level1": "Arts_and_Entertainment",
                "level2": "Museum_and_Gallery",
                "level3": "teamLab_Future_Park_Co_create_Future_Park_Hong_Kong_Station_2023",
                "filters": [
                    {
                        "value": "Low",
                        "count": "0"
                    },
                    {
                        "value": "Mid",
                        "count": "18"
                    }
                ]
            },
            {
                "level1": "Fashion",
                "level2": "Clothing",
                "level3": "MUJI_Bazaar_Sale",
                "filters": [
                    {
                        "value": "Low",
                        "count": "12"
                    },
                    {
                        "value": "Mid",
                        "count": "4"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Mall",
                "level3": "Autumn_Moon_Delicacies_Market",
                "filters": [
                    {
                        "value": "Low",
                        "count": "3"
                    },
                    {
                        "value": "Mid",
                        "count": "15"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Mall",
                "level3": "Demon_Slayer_Kimetsu_no_Yaiba_All_Out_Concentrated_Exhibition_Hong_Kong",
                "filters": [
                    {
                        "value": "High",
                        "count": "0"
                    },
                    {
                        "value": "Low",
                        "count": "3"
                    },
                    {
                        "value": "Mid",
                        "count": "14"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Mall",
                "level3": "Night_Market_New_Town_Plaza",
                "filters": [
                    {
                        "value": "Low",
                        "count": "9"
                    },
                    {
                        "value": "Mid",
                        "count": "4"
                    }
                ]
            },
            {
                "level1": "Shopping",
                "level2": "Mall",
                "level3": "_HAPPY_MOONWALK_Mid_Autumn_Festival_Carnival",
                "filters": [
                    {
                        "value": "Low",
                        "count": "2"
                    },
                    {
                        "value": "Mid",
                        "count": "16"
                    }
                ]
            }
        ],
        "Total Count": "50"
    }
    
    return of(res);
  }

  //Currently not using this
  /*getAudienceLookerIframeUrl(){
    //return this.httpClient.get('http://34.96.198.236:5000/test_url');
    return this.httpClient.get(environment.campaignIframeUrl + 'wadata_url');    
  }*/
}
