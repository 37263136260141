<div id="loading-image" *ngIf="isLoaderDisplayFullPage">
  <!-- Loader 6 -->
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
<div class="container-fluid content-area">    
    <div class="row">
        <div class="left-panel col-lg-2">
          <app-header></app-header>
            <app-sidebar></app-sidebar>
        </div>
        <div class="right-panel col-lg-10">             
                <h5 class="project-label">Create Audience</h5>
                <div class="subtitle-div">You need to select / create a project before creating a new audience.</div>
                <br>
         
            <div class="section-container">
              <div class="sec-container-title">You'll be directed to project page to select a project.</div>
              <button class="btn btn-primary action-btn" (click)="createAudience()">Create</button>
            </div>

            <div class="listProjectHeading">Audiences List</div>

            <div>
                <div *ngIf="delerror" [ngClass]="delerror ? 'errorDiv': 'successDiv'">{{delmsg}}</div>
                <div *ngIf="editerrormsg" [ngClass]="editerrormsg ? 'errorDiv': 'successDiv'">{{editmsg}}</div>
                <div *ngIf="audienceListmsg" [ngClass]="editerrormsg ? 'errorDiv': 'successDiv'">{{editmsg}}</div>
                <div class="table-responsive">
                <table *ngIf="audListData" class="table audiencelist" cellpadding="10" cellspacing="10">
                    <thead>
                      <th>Audience Name</th>
                      <th></th>
                      <th>Project Name</th>
                      <th>Created Date</th>
                      <th>Last Updated On</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let item of audListData; let i=index">
                        <tr *ngIf="i >= getCurrentStartIndex() && i <= getCurrentLastIndex()" >
                          <td class="d-flex align-items-center">
                            <div class="marginTop6">{{item.audienceSetName}}</div>
                            <img [src]="imagePath + 'info.svg'" class="info-icon" title="{{item.filter}}" >
                          </td>
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <svg class="action-img" width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.00004 0.5C1.08337 0.5 0.333374 1.25 0.333374 2.16667C0.333374 3.08333 1.08337 3.83333 2.00004 3.83333C2.91671 3.83333 3.66671 3.08333 3.66671 2.16667C3.66671 1.25 2.91671 0.5 2.00004 0.5ZM2.00004 12.1667C1.08337 12.1667 0.333374 12.9167 0.333374 13.8333C0.333374 14.75 1.08337 15.5 2.00004 15.5C2.91671 15.5 3.66671 14.75 3.66671 13.8333C3.66671 12.9167 2.91671 12.1667 2.00004 12.1667ZM2.00004 6.33333C1.08337 6.33333 0.333374 7.08333 0.333374 8C0.333374 8.91667 1.08337 9.66667 2.00004 9.66667C2.91671 9.66667 3.66671 8.91667 3.66671 8C3.66671 7.08333 2.91671 6.33333 2.00004 6.33333Z" fill="#323232" fill-opacity="0.8"/>
                                </svg>
                                
                              <!-- <img height="14px" src='../../assets/images/three-dots-vertical.svg'> -->
                            </button>
                            
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="showEditAudModal(item.audienceSetName, item.project)"><span class="editDeleteText">Rename</span></button>
                                <button mat-menu-item (click)="deleteAudience(item.project, item.audienceSetName)"><span class="editDeleteText">Delete</span></button>
                              </mat-menu>     
                                   
                          </td>
                          <td class="align-middle"><span>{{item.project}}</span></td>
                          <td class="align-middle"><span>{{dateFormat(item.createdDate)}}</span></td>
                          <td class="align-middle"><span>{{item.updatedDate ? dateFormat(item.updatedDate, 'time') : dateFormat(item.createdDate, 'time')}}</span></td>
                          <td class="align-middle"><button class="sel-btn active-btn selected" (click)="showCampaign(item.project, item.audienceSetName)">Select</button></td>    
                        </tr>
                      </ng-container>
                    </tbody>
                </table>
                </div>
                <div class="pagination-wrapper">
                  <div>   
                <p *ngIf="(audListData | json) != ({} | json) && getTotalData() >= recordsPerPage" class="pagination-info">
                  Showing
                  <span>{{ getCurrentRange() }}</span> of
                  <span>{{ getTotalData() }}</span> rows
                </p>
                <p *ngIf="((audListData | json) == ({} | json) || audListData.length<=0) && loadList == true" class="noDataClass">
                  No Audience created for user
                </p>
              </div>
              <div> 
              <nav aria-label="Page navigation example" *ngIf="(audListData | json) != ({} | json) && getTotalData() >= recordsPerPage">
                <ul class="pagination justify-content-end">
                  <!-- Previous button -->
                  <li class="page-item pagination-arrow-l" *ngIf="pageNumber !== 1">
                    <a class="page-link pagination-arrow-left-dark" (click)="loadData(pageNumber - 1)"><img src="https://res.cloudinary.com/dt9b7pad3/image/upload/v1502810110/angle-down-dark_dkyopo.png"/></a>
                  </li>
              
                  <!-- Page buttons -->
                  <li class="page-item" *ngFor="let page of pagination">
                    <a class="page-link" (click)="loadData(page)" [class.active]="pageNumber === page">{{ page }}</a>
                  </li>
              
                  <!-- Next button -->
                  <li class="page-item pagination-arrow-r" *ngIf="pageNumber !== pagination.length">
                    <a class="page-link" (click)="loadData(pageNumber + 1)"><img src="https://res.cloudinary.com/dt9b7pad3/image/upload/v1502810110/angle-down-dark_dkyopo.png"/></a>
                  </li>
                </ul>
              </nav>
              </div>
            </div>
              
            </div>
              </div>
              
    </div>    
</div>    

 

 
<ng-template #audienceEditScreen>
  <mat-dialog-content>
    <div class="subscribeForFullAccessDiv d-flex justify-content-center align-items-center">         
        <div class="subscibePopupHeading">
                <div  class="row">
                  <div  class="save-text-heading col-lg-10">
                    Rename Audience - {{existingAud}}
                  </div>
                  <div class="col-lg-2 paddingLeft30" >
                      <button mat-button mat-dialog-close class="closeBtn">
                        <img [src]="imagePath + 'close-icon.svg'">
                      </button>
                  </div>
                </div>
                <input required class="save-text" type="text" name="newProj" id="newProject">
              
            <button type="button" class="mr-20 action-btn mt-3  renamePopupBtn" value="save" (click)="updateProject()">Update</button>
            <button class="marginLeft15 btn action-sec-btn renamePopupBtn" type="button" value="cancel" mat-button mat-dialog-close>Cancel</button>
        </div>          
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #audienceDeleteSuccessScreen>
  <mat-dialog-content>
      <div class="subscribeForFullAccessDiv deletePopup" align="left">
          <div>
              <div class="closeDiv floatRight">
                  <mat-dialog-actions>
                    <button mat-button mat-dialog-close class="rd-closeBtn">
                      <img [src]="imagePath + 'close-icon.svg'">
                  </button>
                  </mat-dialog-actions>
              </div>

              <div class="subscibePopupHeading" align="center">
                <div class="mt-1 text-black">{{editDeleteMsg}}</div>
                  <button type="button" class="mt-2 mr-20 btn btn-primary action-sm-btn" value="cancel" mat-button mat-dialog-close>Proceed</button>
              </div>
          </div>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #saveCampaignDialog>
  <mat-dialog-content>
      <div class="subscribeForFullAccessDiv  d-flex  justify-content-center align-items-center">         
        <div class="subscibePopupHeading">
                <div  class="row">
                  <div  class="save-text-heading col-lg-10">
                    <div  class="save-text-heading mb-2">Campaign Name</div>
                  </div>
                  <div class="col-lg-2 paddingLeft30 " >
                      <button mat-button mat-dialog-close class="closeBtn">
                        <img [src]="imagePath + 'close-icon.svg'">
                      </button>
                  </div>
                </div>
                <div id="campmsg"></div>
                <input required class="save-text" type="text" name="savecampaigncomment" id="saveCampaignComment" placeholder="Ex: Working Parents in their 30s">             

            <button class="mr-20 btn action-btn mt-3 renamePopupBtn" type="button" value="audience" (click)="saveCampaign()" [disabled]="creatCampBtnDisable">Create</button>
            <button class="btn action-sec-btn mt-3 renamePopupBtn" type="button" value="campaign" mat-button mat-dialog-close>Cancel</button>
        </div>          
      </div>
  </mat-dialog-content>
</ng-template>