import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DBAPIService } from 'src/app/dbapi.service';

@Component({
  selector: 'app-email-activation',
  templateUrl: './email-activation.component.html',
  styleUrls: ['./email-activation.component.scss']
})
export class EmailActivationComponent {

  constructor(public route: ActivatedRoute, private router: Router, private DBAPIObj: DBAPIService){ }

  error:any = false;
  msg:any='';
  actionCode = '';

  ngOnInit(){
    this.actionCode = this.route.snapshot.queryParams['oobCode'];
    // this.DBAPIObj.emailVerification(this.actionCode).subscribe({
    //   next: (data: any) => {
    //     this.error = false
    //     this.msg = data.message;
    //   },
    //   error: (error) => {
    //     this.error = true;
    //     this.msg = error.message.replaceAll('_', ' ');          
    //   }
    // });
  }

  login(){
    this.router.navigate(['login']);
  }
}
