<div class="container-fluid content-area">
        <div class="row" style="height: 100%;">
            <div class="left-panel col-lg-2">
                <app-header></app-header>
                <app-sidebar></app-sidebar>
            </div>
            <div class="right-panel col-lg-8 login-panel">                  
                <div class="d-flex align-items-center">                                                
                    <form (ngSubmit)="logInUser()" #loginForm="ngForm" ngNativeValidate class="login-container">
                        <h3 id="signInModalLabel" class="login-heading-label mb-4 text-center">Log In</h3>
                        <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}} </div>
                        <label class="login-label" for="email">Email Address</label>
                        <input type="email" placeholder="Email" class="form-control" id="userEmail" name="userEmail" [(ngModel)]="user.userEmail" required>
                        <!-- <div *ngIf="loginForm?.controls?.['email']?.touched && loginForm?.controls?.['email']?.errors?.['required']">
                            Email is required.
                        </div> -->
                        <label class="login-label" for="email">Password</label>
                        <input type="password" placeholder="Password" id="password" class="form-control removeMarginbottom" name="password" [(ngModel)]="user.password" required>
                        <div class="resetPwdlink" (click)="forgotPwd()">Reset Password</div>
                
                        <!-- <button type="submit" class="form-control btn btn-primary rounded submit px-3" [disabled]="!loginForm.form.valid">Sign In</button> -->
                        <button type="submit" class="form-control rounded px-3 action-btn mt-5">Log in</button>
                
                
                    <div class="col-lg-12 text-center">
                        <!-- <div class="link" (click)="forgotPwd()">Reset Password?</div> -->
                        <div><span class="registerText">Don't have an account?</span><a href='signup' class="link"> Register Now</a></div>
                    </div>
                    </form> 
                
                </div>               
            </div>
           
        </div>
</div>

 

 
        