import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignComponent } from './campaign/campaign.component';
import { AudienceComponent } from './audience/audience.component';
import { RegistrationComponent} from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProjectComponent } from './project/project.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AudienceListComponent } from './audience-list/audience-list.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { EmailActivationComponent } from './email-activation/email-activation.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { 
    path: 'audience', 
    component: AudienceComponent 
  },
  { 
    path: 'registration', 
    component: RegistrationComponent 
  },
  { 
    path: 'campaign', 
    component: CampaignComponent 
  },
  { 
    path: 'signup', 
    component: SignupComponent 
  },
  { 
    path: 'project', 
    component: ProjectComponent 
  },
  { 
    path: 'changePassword', 
    component: ChangePasswordComponent 
  },
  { 
    path: 'audiencelist', 
    component: AudienceListComponent
  },
  { 
    path: 'campaignlist', 
    component: CampaignListComponent
  },
  {
    path: 'api/pccw/healthcheck',
    component: HealthCheckComponent,
  },
  {
    path: 'admin',
    component: AdminPageComponent,
  },
  {
    path: 'emailactivation',
    component: EmailActivationComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
  },
  {
    path: '**',
    redirectTo: '/NotExist'
  },
  {
    path: 'NotExist',
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
