<div id="loading-image" *ngIf="isLoaderDisplayFullPage">
    <!-- Loader 6 -->
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>

<div class="container-fluid">
    <div class="row">

        <div class="left-panel col-lg-2">
            <app-header></app-header>
            <app-sidebar></app-sidebar>
        </div>

        <div class="right-panel col-lg-10">
            <div class="row">
            <div class="col-lg-9">
            <div class="col-lg-12"> 
            <h5 class="project-label text-color">Campaign: {{selCampaign}}</h5>
            <div class="sec-container-title">Audience Selection</div>
            <div class="subtitle-div">Select your audience</div>
                    <div class="demographics-filter-1 section-wrapper">
                        <div class="dropdown-div" *ngFor="let campaign of audSelSize;let i=index;last as isLast">
                            <div class="row" *ngIf="i <= showNextIteration" >
                                <div class="col-6">
                                    <select [id]="'proj' + i" class="dropdown select">
                                        <option value="">Select Project</option>
                                        <option [attr.selected]="proj==selected_proj && i==0? true: null" *ngFor="let proj of projectLists;let j=index" value="{{proj}}">{{ proj }}</option>
                                    </select> 
                                </div>
                               

                               <div class="col-6 dropdown-div">
                                <input type="hidden" name="currentSelectedProject">
                                <input type="hidden" name="currentSelectedAudience">
                                <select [id]="'aud' + i" class="dropdown select" >
                                    <option value="">Select an option</option>
                                    <ng-container *ngIf="i === 0">
                                        <option [attr.selected]="option==selected_aud && i==0? true: null" name="campaignset" *ngFor="let option of audList" [value]="option">
                                            {{ option }}
                                        </option>
                                      </ng-container>
                                      
                                   
                                </select>
                                
                                <div class=" filter-info info-drp"></div>
                                <button type="button" id="btn-{{i}}" *ngIf="i>0 && i< audSelSize.length-1" class="btn btn-primary add-drp"
                                    (click)="showNextAudienceDropdown(i)" [ngClass]="(audienceSetType == 'direct')?'disabled' : ''">+</button>
                               </div>
                               
                            </div>
                            <!-- *ngIf="!isLast" -->
                            <div class="row orandDiv"  *ngIf="i < showNextIteration">                                
                                <div class="col-6 text-center andor-sec">
                                    <input class="form-check-input" type="radio" [name]="dynamicORANDId + i" id="or"
                                        value="OR" checked (click)="createFilterStrForSelectedVal();">
                                    <label class="form-check-label mr-40" for="or">
                                        OR
                                    </label>
                                    <input class="form-check-input" type="radio" [name]="dynamicORANDId + i" id="and"
                                        value="AND" (click)="createFilterStrForSelectedVal()">
                                    <label class="form-check-label mr-40" for="and">
                                        AND
                                    </label>                                    
                                </div>                              
                            </div>
                       
                    </div>
                    <!-- <ng-container *ngIf="showlayeredDiv">
                        <div class="overlay"></div>
                    </ng-container>   -->
                </div>
                
                      
         
            <div class="sec-container-title">Channel</div>
            <div class="subtitle-div">Choose where to place your ads.</div>

            <div class="demographics-filter-1 section-wrapper">                
                <div class="row">
                <ng-container class="demographics-filter-1" *ngFor="let item of channellist;let i=index">
               
                    <div class="form-check col-md-3 paddingTop10 marginTop20">
                            {{item.name}}
                    </div>
                    <div class="form-check col-md-3 marginTop20">
                        <button class="sel-btn" [class]="channelArr.includes(item.name) ? 'active-btn' : ''" 
                        [disabled]="item.status == 'inactive' || ((selectedCompaignStr == '' && audienceSetType != 'direct') || (campStatus == 'Submitted') || (campStatus == 'pending' && isChannelOptUpdated == false ))" value="{{item.name}}" (click)="addChannel($event)">{{item.status == 'active' ? channelArr.includes(item.name) ? 'Selected' : 'Select' : item.status}}</button>
                    </div>
               
                </ng-container>
                </div>              
            </div>
            <div class="row demographics-filter-1 mb-10 camp-footer">
                <div class="subtitle-sec" *ngIf="selectedCompaignStr == '' && audienceSetType != 'direct'">* Select audience before submitting</div>
                <div class="col-12" id="displaySaveMsg"></div>                   
                    <button class="btn marginRight20" 
                    [class]="(selectedCompaignStr == '' && audienceSetType != 'direct') || (campStatus == 'Submitted') || (campStatus == 'pending' && isChannelOptUpdated == false ) ? 'action-sec-btn event-none' : 'action-btn '"  (click)="saveCampaignDetails()" >Submit</button>     
                    <button class="btn action-sec-btn" (click)="showCampaignList()" >Cancel</button>                    
            </div>                
</div>
</div> 
    <div class="col-lg-3 right-sec-3">
        <br>
        <div class="est-project-label text-center">Estimated Results</div>
        <!-- <div class="est-audience-sec"><span>{{audSize}}</span><br><span>Est. Audience Size</span> </div> -->
        <div echarts [options]="pieChartOptions" class="aud-donut-chart"></div> 
    </div>

    </div>

        </div>
    </div>
</div>

<ng-template #CampaignSaveDialog>
    <mat-dialog-content>
        <div class="subscribeForFullAccessDiv" align="left">
            <div>
                <div class="closeDiv floatRight">
                    <mat-dialog-actions>
                        <button class="close" mat-button mat-dialog-close>
                            <img [src]="imagePath + 'close-icon.svg'">
                          </button>
                    </mat-dialog-actions>
                </div>

                <div class="subscibePopupHeading col-lg-12">
                    <div><img [src]="imagePath + 'Aud-save.png'"></div>
                    <div class="sub-text mt-3">Request Submitted!</div>
                    <div class="sub-sm-text">We will get back to you within X business days</div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<app-footer></app-footer>