<div class="container-fluid content-area">
  
        
       
        <div class="row" style="height: 100%;">
            <div class="left-panel col-lg-2">
                <app-header></app-header>
                <app-sidebar></app-sidebar>
            </div>
            
            <div class="right-panel col-lg-8 login-panel">
                <div class="d-flex align-items-center">
                    <div class="login-container"> 
                    <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div>
                    <form (ngSubmit)="changePassword()" #loginForm="ngForm" ngNativeValidate>
                        <h3 id="signInModalLabel" class="login-heading-label mb-3 text-center">Change Password</h3>
                        
                        <label class="login-label" for="email">Old Password</label>
                        <input type="password" placeholder="Old Password" class="form-control" id="oldPassword" name="oldPassword" [(ngModel)]="user.password" required>
                        <!-- <div *ngIf="loginForm?.controls?.['email']?.touched && loginForm?.controls?.['email']?.errors?.['required']">
                            Email is required.
                        </div> -->
                        <label class="login-label" for="email">New Password</label>
                        <input type="password" placeholder="New Password" id="newPassword" class="form-control" name="newPassword" [(ngModel)]="user.newPassword" required>
                        <!-- <a data-dismiss="modal" data-toggle="modal" data-target="#resetPwdModal" class="changePwd" href="">Change Password</a> -->
                   
                        <!-- <button type="submit" class="form-control btn btn-primary rounded submit px-3" [disabled]="!loginForm.form.valid">Sign In</button> -->
                        <button type="submit" class="form-control rounded submit px-3 action-btn">Submit</button>                        
                   
                    </form>
                </div>
            </div>  
            </div>         
        </div>
</div>

 

 
        