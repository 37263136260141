import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from  '@angular/common/http';
import { SessionService } from '../session.service';
import { DBAPIService } from 'src/app/dbapi.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user = {    
    userEmail: '',
    password: ''
  };
  
  ADMIN_USER_EMAIL = environment.admin_user_email;
  ADMIN_PWD = environment.admin_pwd;

  sessionUserName: any = '';
  msg: any = '';
  error: boolean = false;
  checkSession: any = '';
  isLogin: boolean = false;
  constructor(private DBAPIObj: DBAPIService, private  http : HttpClient, 
              private sessionService: SessionService,  private router: Router){ }

  ngOnInit(){
    this.sessionService.sessionData.subscribe(data => {
      this.isLogin = data || this.sessionService.getItem('sessionUserName');
      if(this.isLogin){
        this.router.navigate(['project']);  
      }
    })
  }

   logInUser() {
    if(this.user.userEmail.toLowerCase() == this.ADMIN_USER_EMAIL && this.user.password.toLowerCase() == this.ADMIN_PWD){
        this.sessionUserName = this.ADMIN_USER_EMAIL;
        this.sessionService.setItem('sessionUserName', this.ADMIN_USER_EMAIL);        
        this.sessionService.setSessionData(this.sessionUserName);  
        this.router.navigate(['admin']);
        return; 
    }
    
    this.DBAPIObj.login(this.user).subscribe({
      next: (data: any) => {
        if (data && data['message']){
          this.error = false;
          this.sessionUserName = this.user.userEmail;
          this.sessionService.setItem('sessionUserName', this.user.userEmail);
          //this.sessionService.setItem('userOrg', this.user.userEmail);
          this.sessionService.setSessionData(this.sessionUserName);          
          this.router.navigate(['project']);   
           
        }else {
          this.error = true;
          this.msg = data['message'];        
        }
      },
      error: (error) => {
        console.log(error);
        this.error = true;
        this.msg = error.error['message'].replaceAll('_', ' ');         
      }
    });
  }

  resetPassword(){
  }

  forgotPwd(){
    this.router.navigate(['forgotpassword']);
  }

  setUserInSession(username: any, email:any) {
    this.sessionService.setItem('user', { email: email, name: username });
  }

}
