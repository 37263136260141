<div class="container-fluid content-area">
        <div class="row" style="height: 100%;">
            <div class="left-panel col-lg-2">
                <app-header></app-header>
                <app-sidebar></app-sidebar>
            </div>
            <div class="right-panel col-lg-8 login-panel">
                <div class="d-flex align-items-center">
                <div class="login-container">
                <h3 id="signInModalLabel" class="login-heading-label mb-4 text-center">Forgot Password</h3>
                    <div *ngIf="msg" [ngClass]="error ? 'errorDiv': 'successDiv'">{{msg}}</div>
                    <form (ngSubmit)="submit()" #loginForm="ngForm" ngNativeValidate>
                        <div class="form-group mb-3">
                        <!-- <label class="label" for="email">Email</label> -->

                        <label class="login-label" for="email">Email Address</label>
                        <input  [(ngModel)]="user.userEmail" type="email" placeholder="Email" class="form-control" id="userEmail" name="userEmail" required>
                     
                        </div>
                        <div class="form-group">
                            <button type="submit" class="action-btn form-control btn btn-primary rounded submit px-3 mt-3">Submit</button>
                        </div>                   
                    </form>
                </div>
            </div>
        </div>           
    </div>
</div>

 

 
        