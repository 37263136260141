// constants.ts

export const USER_ID = 'SONALPATE';
export const PROJECT_ID = 'A12';
export const ORG_ID = 'ABC1';

export const AppSettings = {
  APP_NAME: 'Adtech',
  VERSION: '1.0.0',
};
